import { DefaultButton, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./StringFormPopup.scss";
import { localize } from "src/utils/l10n";
import { setPopupForm } from "src/actions/ui";
import FormPopup from "../FormPopup/FormPopup";
import FormSection from "../FormSection/FormSection";
import FormControl from "../FormControl/FormControl";
import { validateRequiredTextField } from "src/utils";
import PhoenixForm from "../PhoenixForm/PhoenixForm";
import onFormClose from "src/utils/onFormClose";

interface IProps {
    value?: string;
    title: string;
    label: string;
    onCancelClick: any;
    onSaveClick: any;
    multiline?: boolean;
}

const StringFormPopup = (props: IProps) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <FormPopup popupFormKey="StringForm">
            <PhoenixForm onSubmit={(ev) => {
                ev.preventDefault();
                props.onSaveClick(value);
                setValue("");
            }} data={value}>
                <FormSection>
                    <FormControl>
                        <TextField
                            value={value}
                            className="textField"
                            autoAdjustHeight={props.multiline}
                            multiline={props.multiline}
                            label={props.label}
                            resizable={false}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                setValue(newValue);
                            }}
                            required
                            aria-required
                            validateOnFocusOut
                            validateOnLoad={false}
                            onGetErrorMessage={validateRequiredTextField}
                            autoFocus
                        />
                    </FormControl>
                </FormSection>
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                    <DefaultButton onClick={() => {
                        onFormClose(dispatch, () => {
                            props.onCancelClick();

                            dispatch(setPopupForm({
                                popupFormKey: "StringForm",
                                isDisplayed: false
                            }));
                        });
                    }} text={localize("Avbryt")} />
                    <PrimaryButton type={"submit"} text={localize("Spara")} />
                </Stack>
            </PhoenixForm>
        </FormPopup>
    )
};


export default StringFormPopup;
