import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import Loader from 'src/components/Loader/Loader';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './Plan.scss';
import Label from '../Label/Label';
import api from 'src/api/SpintrApi';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Page from '../Page/Page';
import ProjectSelector from '../ProjectSelector/ProjectSelector';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import FormPopup from '../FormPopup/FormPopup';
import LabelBox from '../LabelBox/LabelBox';
import { setPopupForm } from 'src/actions/ui';
import moment from 'moment';
import GoalForm from '../GoalForm/GoalForm';
import ActionItemForm from '../ActionItemForm/ActionItemForm';
import Notifications from '../Notifications/Notifications';
import onFormClose from 'src/utils/onFormClose';
import HalfCirclePlan from './HalfCirclePlan';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    projects?: any[];
    activeProjectId?: number;
}

interface IState {
    isLoading: boolean;
    plan?: any;
    selectedVectorId: number;
    selectedGoalId: number;
    selectedActionItemId: number;
    mode: "diagram" | "circle";
}

class Plan extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            selectedVectorId: 0,
            selectedGoalId: 0,
            selectedActionItemId: 0,
            mode: "diagram"
        };
    }

    componentDidMount(): void {
        if (!this.props.currentInstance.pro) {
            this.props.history.push("/");
        }

        this.fetch();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.activeProjectId !== this.props.activeProjectId) {
            this.setState({
                isLoading: true
            }, () => {
                this.fetch();
            });
        }
    }

    fetch() {
        api.get("/api/v1/plans/" + this.props.activeProjectId).then((response: any) => {
            this.setState({
                isLoading: false,
                plan: {
                    ...response.data,
                    vectors: response.data.vectors.map(v => {
                        return {
                            ...v,
                            actionItems: v.actionItems.map(a => {
                                return {
                                    ...a,
                                    dueDate: new Date(a.dueDate)
                                }
                            })
                        }
                    })
                },
                selectedVectorId: response.data.vectors[0].id,
            });
        }).catch(() => { });
    }

    getSelectedVector() {
        if (!this.state.plan) {
            return undefined;
        }

        return this.state.plan.vectors.find(x => x.id === this.state.selectedVectorId);
    }

    renderGoalForm(selectedVector) {
        return (
            <FormPopup
                popupFormKey="Goal"
                onClose={() => {
                    this.props.dispatch(setPopupForm({
                        popupFormKey: "Goal",
                        isDisplayed: false
                    }));
                }}>
                <GoalForm
                    value={selectedVector.target}
                    onSaveClick={(value: number) => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Goal",
                            isDisplayed: false
                        }));

                        this.setState({
                            plan: {
                                ...this.state.plan,
                                vectors: this.state.plan.vectors.map((v: any) => {
                                    if (v.id === this.state.selectedVectorId) {
                                        return {
                                            ...v,
                                            target: value
                                        }
                                    } else {
                                        return v;
                                    }
                                })
                            }
                        }, () => {
                            api.put("/api/v1/surveys/goal/" + this.state.selectedVectorId, value, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });
                        });
                    }}
                    onCancelClick={() => {
                        onFormClose(this.props.dispatch, () => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Goal",
                                isDisplayed: false
                            }));
                        });
                    }} />
            </FormPopup>
        )
    }

    renderActionForm(selectedVector) {
        const selectedAction = selectedVector.actionItems.find(x => x.id === this.state.selectedActionItemId);

        return (
            <FormPopup
                popupFormKey="Action"
                onClose={() => {
                    this.props.dispatch(setPopupForm({
                        popupFormKey: "Action",
                        isDisplayed: false
                    }));
                }}>
                <ActionItemForm
                    item={selectedAction || {
                        priority: 1,
                        status: 0,
                        vectorId: selectedVector.id,
                        dueDate: new Date()
                    }}
                    onDelete={() => {
                        this.setState({
                            plan: {
                                ...this.state.plan,
                                vectors: this.state.plan.vectors.map((v: any) => {
                                    if (v.id === selectedVector.id) {
                                        return {
                                            ...v,
                                            actionItems: [...v.actionItems].filter(x => x.id !== selectedAction.id)
                                        }
                                    } else {
                                        return v;
                                    }
                                })
                            }
                        });

                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Action",
                            isDisplayed: false
                        }));
                    }}
                    onSave={(item) => {
                        if (selectedAction) {
                            this.setState({
                                plan: {
                                    ...this.state.plan,
                                    vectors: this.state.plan.vectors.map((v: any) => {
                                        if (v.id === selectedVector.id) {
                                            return {
                                                ...v,
                                                actionItems: v.actionItems.map((a: any) => {
                                                    if (a.id === selectedAction.id) {
                                                        return item;
                                                    } else {
                                                        return a;
                                                    }
                                                })
                                            }
                                        } else {
                                            return v;
                                        }
                                    })
                                }
                            })
                        } else {
                            this.setState({
                                plan: {
                                    ...this.state.plan,
                                    vectors: this.state.plan.vectors.map((v: any) => {
                                        if (v.id === selectedVector.id) {
                                            return {
                                                ...v,
                                                actionItems: [
                                                    ...v.actionItems,
                                                    item
                                                ]
                                            }
                                        } else {
                                            return v;
                                        }
                                    })
                                }
                            })
                        }
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Action",
                            isDisplayed: false
                        }));
                    }}
                    onCancelClick={() => {
                        onFormClose(this.props.dispatch, () => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Action",
                                isDisplayed: false
                            }));
                        });
                    }} />
            </FormPopup>
        )
    }

    render() {
        const selectedVector = this.getSelectedVector();

        return (
            <div className="Plan">
                <Page renderHeader={this.renderHeader.bind(this)} noContentStyle>
                    {this.renderBarsContent()}
                </Page>
                {selectedVector && this.renderGoalForm(selectedVector)}
                {selectedVector && this.renderActionForm(selectedVector)}
            </div>
        )
    }

    renderModeButton(key: "circle" | "diagram", text: string) {
        return (
            <UnstyledButton
                className={this.state.mode === key ? "active" : ""}
                onClick={() => {
                    this.setState({
                        mode: key
                    });
                }}>
                <Label
                    fontType="display"
                    size="body-2"
                    color={this.state.mode === key ? undefined : "p-mid-grey-1"}
                    weight="medium">{text}</Label>
            </UnstyledButton>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <ProjectSelector />
                </div>
                <div className="header-right">
                    {/* <div className="plan-switcher">
                        {this.renderModeButton("circle", localize("CIRCLE_VIEW"))}
                        {this.renderModeButton("diagram", localize("DIAGRAM_VIEW"))}
                    </div> */}
                    <Notifications />
                </div>
            </div>
        )
    }

    renderBar(title: string, renderContent: any) {
        return (
            <div className="bar">
                <div className="bar-content">
                    {renderContent()}
                </div>
                <div className="bar-footer">
                    <Label centerText size="h3" weight="bold">
                        {title}
                    </Label>
                </div>
            </div>
        )
    }

    renderBarCreateButton(title: string, onClick: any) {
        return (
            <div className="bar-create">
                <UnstyledButton onClick={onClick}>
                    <Visage2Icon icon="add-circle" color="p-green-1" />
                    <Label color="p-green-1" fontType="display" weight="medium">{title}</Label>
                </UnstyledButton>
            </div>
        )
    }

    renderBarItem(id: number, title: string, itemType: number, renderFooter?: any, onEdit?: any) {
        const classNames = ["bar-item"];

        const isActive = (itemType === 2 && this.state.selectedVectorId === id) ||
            (itemType === 3 && this.state.selectedGoalId === id) ||
            (itemType === 4 && this.state.selectedActionItemId === id);

        if (isActive) {
            classNames.push("active");
        }

        return (
            <UnstyledButton
                onClick={() => {
                    this.setState({
                        selectedVectorId: itemType === 2 ? id : this.state.selectedVectorId,
                        selectedGoalId: itemType === 3 ? id : this.state.selectedGoalId,
                        selectedActionItemId: itemType === 4 ? id : this.state.selectedActionItemId,
                    })
                }}
                className={classNames.join(" ")}
                key={id}>
                <Label weight="medium" size="h4" fontType="display">
                    {title}
                </Label>
                {onEdit && (
                    <UnstyledButton className="edit-button" onClick={onEdit} title={localize("Redigera")}>
                        <Visage2Icon icon="edit" />
                    </UnstyledButton>
                )}
                {renderFooter && renderFooter()}
            </UnstyledButton>
        )
    }

    renderBar1() {
        const fn = () => {
            return (
                <div className="main-goal-inner">
                    <Label>
                        {this.state.plan.mainGoal}
                    </Label>
                </div>
            )
        }

        return this.renderBar(localize("PROJECT_GOAL"), fn);
    }

    renderBar2() {
        const fn = () => {
            return (
                <div className="bar-content-inner">
                    <div className="bar-create"></div>
                    {this.state.plan.vectors.map((x: any, index: number) => {
                        return this.renderBarItem(x.id, x.name, 2);
                    })}
                </div>
            )
        }

        return this.renderBar(localize("VECTORS"), fn);
    }

    renderBar3() {
        const fn = () => {
            const selectedVector = this.state.plan.vectors.find(x => x.id === this.state.selectedVectorId);

            return (
                <div className="bar-content-inner">
                    {!!selectedVector && selectedVector.target === 0 && this.renderBarCreateButton(localize("ADD_GOAL"), () => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Goal",
                            id: 0,
                            title: localize("ADD_GOAL"),
                            isDisplayed: true
                        }));
                    })}
                    {!!selectedVector && selectedVector.target > 0 && (
                        <div className="bar-create"></div>
                    )}
                    {!!selectedVector && selectedVector.target > 0 && this.renderBarItem(0, selectedVector.target + "%", 3, undefined, () => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Goal",
                            id: 0,
                            title: localize("EDIT_GOAL"),
                            isDisplayed: true
                        }));
                    })}
                </div>
            )
        }

        return this.renderBar(localize("GOAL"), fn);
    }

    renderBar4() {
        const fn = () => {
            const selectedVector = this.state.plan.vectors.find(x => x.id === this.state.selectedVectorId);

            return (
                <div className="bar-content-inner">
                    {this.renderBarCreateButton(localize("ADD_ACTION"), () => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Action",
                            id: 0,
                            title: localize("ADD_ACTION"),
                            isDisplayed: true
                        }));
                    })}
                    {selectedVector.actionItems.map((x: any, index: number) => {
                        return this.renderBarItem(x.id, x.text, 4, () => {
                            return (
                                <div className="LabelBoxes">
                                    <LabelBox text={localize("PRIORITY_" + x.priority)} prio={x.priority} />
                                    {x.dueDate && (
                                        <LabelBox text={moment(x.dueDate).format("ll")} />
                                    )}
                                </div>
                            )
                        }, () => {
                            this.setState({
                                selectedActionItemId: x.id
                            }, () => {
                                this.props.dispatch(setPopupForm({
                                    popupFormKey: "Action",
                                    id: x.id,
                                    title: localize("EDIT_ACTION"),
                                    isDisplayed: true
                                }));
                            });
                        });
                    })}
                </div>
            )
        }

        return this.renderBar(localize("AREAS_FOCUS"), fn);
    }

    renderBarsContent() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        if (this.state.mode === "circle") {
            return (
                <HalfCirclePlan />
            )
        }

        return (
            <div className="bars">
                {this.renderBar1()}
                {this.renderBar2()}
                {this.renderBar3()}
                {this.renderBar4()}
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    projects: state.project.projects,
    activeProjectId: state.project.activeProjectId
});

export default withRouter(connect(mapStateToProps)(Plan));
