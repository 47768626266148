import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';
import UnstyledButton from 'src/components/UnstyledButton/UnstyledButton';
import { SpintrTypes } from 'src/typings';

import './TargetGroups.scss';
import Page from '../Page/Page';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import { setPopupForm } from 'src/actions/ui';
import FormPopup from '../FormPopup/FormPopup';
import TargetGroupForm from '../TargetGroupForm/TargetGroupForm';
import { IPopupFormState } from 'src/reducers/ui';

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    popupFormState?: IPopupFormState;
}

interface IState {
    targetGroups: any;
}

class TargetGroups extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            targetGroups: {
                items: [],
                totalCount: 0,
                isLoading: true,
                includeDeleted: false
            }
        };
    }

    toggleDeleteTargetGroup(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/targetgroups/toggledelete/" + item.id).then(() => {
                        this.setState({
                            targetGroups: {
                                ...this.state.targetGroups,
                                items: this.state.targetGroups.items.map((i: any) => {
                                    return {
                                        ...i,
                                        deleted: i.id === item.id ?
                                            !i.deleted :
                                            i.deleted
                                    }
                                })
                            }
                        });
                    });
                },
            })
        );
    }


    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey="TargetGroups" />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: [{
                                key: "showDeleted",
                                text: this.state.targetGroups.includeDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        targetGroups: {
                                            ...this.state.targetGroups,
                                            includeDeleted: !this.state.targetGroups.includeDeleted
                                        }
                                    }, () => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    });
                                },
                            }]
                        }}
                    />
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "TargetGroup",
                                id: 0,
                                title: localize("CREATE_TARGET_GROUP"),
                                isDisplayed: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("CREATE_TARGET_GROUP")}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="TargetGroups">
                <PageHeader title={localize("Malgrupper")} hits={this.state.targetGroups.totalCount} />
                <SpintrList
                    ref={this.listRef}
                    searchKey="TargetGroups"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/targetgroups", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.targetGroups.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        targetGroups: {
                                            ...this.state.targetGroups,
                                            items: items,
                                            totalCount: response.data.totalCount
                                        }
                                    });
                                });
                        });
                    }}
                    data={{
                        data: this.state.targetGroups.items,
                        totalCount: this.state.targetGroups.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                return (
                                    <UnstyledButton
                                        className={"linkFGColor"}
                                        onClick={() => {
                                            this.props.dispatch(setPopupForm({
                                                popupFormKey: "TargetGroup",
                                                id: item.id,
                                                title: "EDIT_TARGET_GROUP",
                                                isDisplayed: true
                                            }));
                                        }}>
                                        <span style={{
                                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                        }}>{item.name}</span>
                                    </UnstyledButton>
                                );
                            },
                        },
                        {
                            name: localize("Skapad"),
                            fieldName: "createdDate",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {moment(item.createdDate).format("lll")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("AntalAnvandare"),
                            fieldName: "userCount",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {item.userCount}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Status"),
                            fieldName: "deleted",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [{
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.props.dispatch(setPopupForm({
                                                            popupFormKey: "TargetGroup",
                                                            id: item.id,
                                                            title: "EDIT_TARGET_GROUP",
                                                            isDisplayed: true
                                                        }));
                                                    }
                                                }, {
                                                    text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                    onClick: () => this.toggleDeleteTargetGroup(item),
                                                }],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    isDescending={true}
                    orderByColumn="userCount"
                ></SpintrList>
                {this.props.popupFormState && (
                    <FormPopup
                        popupFormKey="TargetGroup"
                        onClose={() => {
                            //@ts-ignore
                            this.listRef.current.reFetch();
                        }}>
                        <TargetGroupForm id={this.props.popupFormState.id} />
                    </FormPopup>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "TargetGroup")
});

export default withRouter(connect(mapStateToProps)(TargetGroups));