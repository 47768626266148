import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import Loader from 'src/components/Loader/Loader';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './Plan.scss';
import Label from '../Label/Label';
import api from 'src/api/SpintrApi';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ITextField, Modal, PrimaryButton, Stack, TextField } from '@fluentui/react';
import FormControl from '../FormControl/FormControl';
import PopupHeader from '../PopupHeader/PopupHeader';
import UnstyledButton from '../UnstyledButton/UnstyledButton';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    projects?: any[];
    activeProjectId?: number;
}

interface IState {
    isLoading: boolean;
    plan?: any;
    showModal: boolean;
}

class HalfCirclePlan extends Component<IProps, IState> {
    private textInputRef: ITextField;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            showModal: false
        };
    }

    componentDidMount(): void {
        this.fetch();
    }

    fetch() {
        api.get("/api/v1/plans/" + this.props.activeProjectId).then((response: any) => {
            this.setState({
                isLoading: false,
                plan: {
                    ...response.data,
                    selectedVectors: response.data.vectors.map(x => x.id)
                }
            });
        }).catch(() => { });
    }

    save() {
        const envelope = {
            ...this.state.plan
        };

        this.setState({
            isLoading: true
        }, () => {
            api.post("/api/v1/plans", envelope).then(() => {
                this.fetch();
            }).catch(() => { });
        });
    }

    getActionMenuCategories() {
        let categories = [{
            items: this.props.currentInstance.vectors.map((vector: any) => {
                const isChecked = this.state.plan.selectedVectors.indexOf(vector.id) > -1;

                return {
                    text: vector.name,
                    onClick: () => {
                        if (isChecked) {
                            this.setState({
                                plan: {
                                    ...this.state.plan,
                                    selectedVectors: [
                                        ...this.state.plan.selectedVectors
                                    ].filter(x => x !== vector.id)
                                }
                            }, () => {
                                this.save();
                            });
                        } else {
                            this.setState({
                                plan: {
                                    ...this.state.plan,
                                    selectedVectors: [
                                        ...this.state.plan.selectedVectors,
                                        vector.id
                                    ]
                                }
                            }, () => {
                                this.save();
                            });
                        }
                    },
                    canCheck: true,
                    isChecked
                }
            }),
        }, {
            items: [{
                text: localize("SET_VISION"),
                onClick: this.toggleShowModal.bind(this)
            }]
        }];

        return categories;
    }

    getLabels(selectedVectors: any[]) {
        const focusAreaTexts = selectedVectors.map((v: any) => {
            if (!v.actionItems || v.actionItems.length === 0) {
                return "";
            }

            return v.actionItems.map((fa) => {
                return fa.text;
            })
        }).flat();

        const goalsTexts = selectedVectors.map((v: any) => {
            const s = v.target + "%";

            return s;
        });

        const vectorNames = selectedVectors.map((v: any) => { return v.name });

        const mainGoal = [this.state.plan.mainGoal];

        const allData = [focusAreaTexts, goalsTexts, vectorNames, mainGoal];

        return allData;
    }

    toggleShowModal() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    renderFooterColumn(langTag: string, isMiddle?: boolean) {
        return (
            <div className={isMiddle ? "middle" : ""}>
                <Label
                    size="body-3"
                    uppercase
                    centerText
                    weight="medium">
                    {localize(langTag)}
                </Label>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        const selectedVectors = this.state.plan.vectors.filter(x => this.state.plan.selectedVectors.indexOf(x.id) > -1);

        const vectorNumbers = selectedVectors.map(() => {
            return 100 / this.state.plan.vectors.length
        });

        const focusAreaGroups = selectedVectors.map((vector: any, index: number) => {
            const vectorTotal = vectorNumbers[index];

            const goalNumbers = vector.actionItems.map(() => {
                return vectorTotal / vector.actionItems.length;
            });

            if (!goalNumbers || goalNumbers.length === 0) {
                return [vectorTotal];
            }

            return goalNumbers;
        });

        const flatFocusAreas = focusAreaGroups.flat();

        const focusAreaColors = ["#C6C7F3", "#F4EDF4", "#D9BFFC"];

        const getColor = (index: number) => {
            return focusAreaColors[Math.floor(index - Math.floor(index / focusAreaColors.length) * focusAreaColors.length)];
        }

        const data = {
            //labels: ['Overall Yay', 'Overall Nay', 'Group A Yay', 'Group A Nay', 'Group B Yay', 'Group B Nay', 'Group C Yay', 'Group C Nay'],
            datasets: [
                {
                    backgroundColor: focusAreaGroups.map((g: any, index: number) => {
                        return g.map((fa: any) => {
                            return getColor(index);
                        })
                    }).flat(),
                    data: flatFocusAreas,
                    borderRadius: 20
                },
                {
                    backgroundColor: ["#DCDDFC", "#C8C9FF", "#E7D9FA"],
                    data: vectorNumbers,
                    borderRadius: 20
                },
                {
                    backgroundColor: ["#F5F5F7", "#F4EDF4"],
                    data: vectorNumbers,
                    borderRadius: 20
                },
                {
                    backgroundColor: ["#F5BCD0"],
                    data: [100],
                    borderRadius: 20
                }
            ]
        };

        const plugins = [
            ChartDataLabels,
        ];

        const labels = this.getLabels(selectedVectors);

        console.log(labels);

        return (
            <div className="Plan half-circle">
                <Pie
                    data={data}
                    plugins={plugins}
                    options={{
                        responsive: true,
                        rotation: 270,
                        circumference: 180,
                        //cutout: 1,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false
                            },
                            datalabels: {
                                anchor: "center",
                                align: "center",
                                textAlign: "center",
                                formatter: function (value, context) {
                                    const s = labels[context.datasetIndex][context.dataIndex];

                                    const words = s.split(" ");

                                    let result = "";

                                    const dataIndexIsOnEdge = context.dataIndex === 0 ||
                                        context.dataIndex === (labels[context.datasetIndex].length - 1);

                                    const maxWordCount = 2;

                                    for (let i = 0; i < words.length; i++) {
                                        result += (words[i] + " ");

                                        const isLastRun = i === (words.length - 1);

                                        if (!isLastRun &&
                                            (i + 1) % maxWordCount === 0) {
                                            result += "\n";
                                        }
                                    }

                                    return result;
                                }
                            }
                        },

                    }} />
                <div className="chart-footer">
                    {this.renderFooterColumn("AREAS_FOCUS_2")}
                    {this.renderFooterColumn("GOAL")}
                    {this.renderFooterColumn("VECTORS")}
                    {this.renderFooterColumn("PROJECT_GOAL", true)}
                    {this.renderFooterColumn("VECTORS")}
                    {this.renderFooterColumn("GOAL")}
                    {this.renderFooterColumn("AREAS_FOCUS_2")}
                </div>
                <Modal className="spintr-modal modalWithPopupHeader" isOpen={this.state.showModal}>
                    <PopupHeader
                        text={localize("SET_VISION")}
                        onClose={this.toggleShowModal.bind(this)}
                    />
                    <div>
                        <FormControl>
                            <TextField
                                label={localize("VISION")}
                                componentRef={(input) => (this.textInputRef = input)}
                                defaultValue={this.state.plan.mainGoal}
                            />
                        </FormControl>

                        <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                            <UnstyledButton onClick={this.toggleShowModal.bind(this)}>
                                <Label size="body-2" color="p-mid-grey-3">
                                    {localize("CANCEL")}
                                </Label>
                            </UnstyledButton>
                            <PrimaryButton text={localize("SAVE")} onClick={() => {
                                this.setState({
                                    plan: {
                                        ...this.state.plan,
                                        mainGoal: this.textInputRef.value
                                    },
                                    isLoading: true,
                                    showModal: false
                                }, this.save.bind(this));
                            }} />
                        </Stack>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    projects: state.project.projects,
    activeProjectId: state.project.activeProjectId
});

export default withRouter(connect(mapStateToProps)(HalfCirclePlan));
