import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IApplicationState } from 'src/reducer';

interface IProps extends RouteComponentProps {
    history: any;
    currentInstance: any;
    currentUser: any;
    adminMode: boolean;
}

class Home extends Component<IProps> {
    componentDidMount() {
        if (this.props.currentUser.isAdmin && this.props.adminMode) {
            this.props.history.push("/admin");
        } else {
            this.props.history.push("/radar");
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    adminMode: state.ui.adminMode
});

export default withRouter(connect(mapStateToProps)(Home));
