import * as actionTypes from "../action-types/instance";

export interface IInstanceState {
    currentInstance: any;
    isLoading: boolean;
    hasFetched: boolean;
}

const initialInstance = {
    instanceId: 0,
    color: "#2e2e59",
    useColorHeader: false
}

const initialState: IInstanceState = {
    currentInstance: initialInstance,
    isLoading: false,
    hasFetched: false
};

const instanceReducer = (state: IInstanceState = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CURRENT_INSTANCE_PENDING:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.FETCH_CURRENT_INSTANCE_FULFILLED:
            return {
                ...state,
                currentInstance: {
                    ...initialInstance,
                    ...(action.payload.data.theme || {}),
                    ...action.payload.data
                },
                isLoading: false,
                hasFetched: true
            }
        case actionTypes.EDIT_INSTANCE:
            return {
                ...state,
                currentInstance: {
                    ...state.currentInstance,
                    ...action.item
                }
            }
        default:
            return state;
    }
};

export default instanceReducer;
