import React, { Component, ReactNode } from "react";
import api from "src/api/SpintrApi";
import { SpintrTypes } from "src/typings";
import { localize } from "src/utils/l10n";
import generateUniqueId from "src/utils/uniqueId";
import TokenizedInputFabric from "src/components/TokenizedInputFabric/TokenizedInputFabric";

export interface ITokenizedObjectInputProps {
    allowEmailAddresses?: boolean;
    disabled?: boolean;
    enableNewItems?: boolean;
    hideImage?: boolean;
    id?: string;
    itemLimit?: number;
    items: any[];
    noAutocomplete?: boolean;
    onChange: any;
    placeholder?: string;
    useSearchEngine?: boolean;
    types?: any[];
    onQueryChange?: any;
    initialInputText?: string;
    showAllWhenEmpty?: boolean;
    excludeEmptyRoles?: boolean;
    shareableGroupsOnly?: boolean;
    groupId?: number;
    includeDeleted?: boolean;
    excludeId?: number;
    url: string;
    customParams?: any;
}

class TokenizedObjectInput extends Component<ITokenizedObjectInputProps> {
    constructor(props: ITokenizedObjectInputProps) {
        super(props);

        this.autocomplete = this.autocomplete.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    public autocomplete(text: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject): void => {
            if (this.props.noAutocomplete) {
                return resolve([]);
            }

            if (this.props.onQueryChange) {
                this.props.onQueryChange(text);
            }

            const types: SpintrTypes.UberType[] = this.props.types || [1];

            const uberType = SpintrTypes.UberType;

            if (
                this.props.allowEmailAddresses &&
                text.length > 2 &&
                text.indexOf("@") > 0 &&
                text.indexOf("@") !== text.length - 1
            ) {
                return resolve([
                    {
                        imageUrl: "",
                        isEmail: true,
                        key: generateUniqueId(),
                        name: text,
                        subText: localize("Epost"),
                    },
                ]);
            }

            if (this.props.useSearchEngine) {
                let excludedTypes = [];

                if (this.props.types && this.props.types.length > 0) {
                    for (let t in uberType) {
                        if (typeof uberType[t] === 'number') {
                            excludedTypes.push(uberType[t]);
                        }
                    }

                    excludedTypes = excludedTypes.filter(t => this.props.types.indexOf(t) === -1);
                }

                // const req = executeSearch({
                //     query: text,
                //     excludedTypes
                // });

                // req.then((res) => resolve(res.data.map((hit) => ({
                //     imageUrl: hit.imageUrl,
                //     key: hit.id,
                //     name: hit.name,
                //     subText: truncate(stripHtml(hit.caption), 50)
                // })))).catch((err) => reject(err));
            } else {
                const request = api.get<any>(this.props.url, {
                    params: {
                        excludeAll: true,
                        excludeDepartments: !types.includes(uberType.Department),
                        excludeGroups: !types.includes(uberType.Group),
                        excludeOffices: !types.includes(uberType.Office),
                        excludeEmptyRoles: this.props.excludeEmptyRoles,
                        excludeRoles: !types.includes(uberType.Role),
                        excludeTargetGroups: !types.includes(uberType.TargetGroup), // TODO: Fix, target groups aren't ubers :(
                        excludeUsers: !types.includes(uberType.User),
                        excludeCompanies: !types.includes(uberType.Company),
                        searchQuery: text,
                        shareableGroupsOnly: this.props.shareableGroupsOnly,
                        skip: 0,
                        take: 8,
                        groupId: this.props.groupId,
                        includeDeleted: !!this.props.includeDeleted,
                        ...(this.props.customParams ? this.props.customParams : {})
                    },
                });

                request.then(
                    (response) => {
                        let data = response.data.items;

                        if (!!this.props.excludeId) {
                            data = data.filter((item: any) => item.id !== this.props.excludeId)
                        }

                        resolve(
                            data.map(
                                (item): any => {
                                    switch (item.type) {
                                        case 2:
                                            item.info = localize("Avdelning");
                                            break;
                                        case 8:
                                            item.info = localize("Kontor");
                                            break;
                                        case 26:
                                            item.info = localize("Grupp");
                                            break;
                                        case 44:
                                            item.info = localize("Roll");
                                            break;
                                        case 52:
                                            item.info = localize("Mottagargrupp");
                                            break;
                                        case 65:
                                            item.info = localize("Foretag")
                                    }

                                    return {
                                        imageUrl: item.imageUrl,
                                        key: item.id,
                                        name: item.name,
                                        subText: item.info,
                                        type: item.type,
                                    };
                                }
                            )
                        );
                    },
                    (reason: any) => reject(reason)
                );
            }
        });
    }

    public onChange(items: any[]): void {
        this.props.onChange(items);
    }

    public render(): ReactNode {
        return (
            <TokenizedInputFabric
                id={this.props.id}
                showAllWhenEmpty={this.props.showAllWhenEmpty}
                initialInputText={this.props.initialInputText}
                disabled={this.props.disabled}
                autocomplete={this.autocomplete}
                items={this.props.items}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                hideImage={this.props.hideImage}
                enableNewItems={this.props.enableNewItems}
                itemLimit={this.props.itemLimit}
            />
        );
    }
}

export default TokenizedObjectInput;
