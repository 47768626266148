import React, { Component } from "react";
import { localize } from "src/utils/l10n";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ChartOptions,
} from "chart.js";
import {
    Callout,
    DefaultButton,
    Dropdown,
    PrimaryButton,
} from "@fluentui/react";
import Label from "src/components/Label/Label";
import "./DetailsView.scss";
import { RouteComponentProps } from "react-router-dom";
import api from "src/api/SpintrApi";
import moment from "moment";
import Loader from "src/components/Loader/Loader";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";

import { listActionMenuWidth } from "src/utils/style";
import { connect } from "react-redux";
import annotationPlugin from "chartjs-plugin-annotation";
import { IApplicationState } from "src/reducer";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import { AxiosResponse } from "axios";
import Page from "../Page/Page";
import Notifications from "../Notifications/Notifications";
import VectorSelector from "../ProjectSelector/VectorSelector";
import SpintrList from "../SpintrList/SpintrList";
import { capitalizeFirstLetter } from "src/utils";
import gradient from 'chartjs-plugin-gradient';
import LabelBox from "../LabelBox/LabelBox";
import { ActionMenu } from "../ActionMenu";
import StatusMessageWithIcon from "../StatusMessageWithIcon/StatusMessageWithIcon";
import FormPopup from "../FormPopup/FormPopup";
import { setPopupForm } from "src/actions/ui";
import ActionItemForm from "../ActionItemForm/ActionItemForm";
import { SpintrTypes } from "src/typings";
import SpintrUser from "../SpintrUser/SpintrUser";
import { setConfirmPopup } from "src/actions/popup";
import * as Style from 'src/utils/style';
import onFormClose from "src/utils/onFormClose";
import GoalForm from "../GoalForm/GoalForm";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    annotationPlugin,
    gradient
);


interface IRouteParams {
    vectorId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    hasCustomBodyFont: boolean;
    instanceColor: string;
    pro?: boolean;
    isAdmin: boolean;
    dispatch?: any;
    activeProjectId: number;
    activeVectorId: number;
    projects?: any[];
}

interface IState {
    data?: any;
    isLoading: boolean;
    targetX: number;
    targetY: number;
    selectedActionItemId: number;
    showCommentsCalloutForSurveyId: number;
    targetGroups?: any[];
    targetGroup?: number;
    question?: number;
}

class DetailsView extends Component<IProps, IState> {
    private surveysListRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();
    private actionItemsListRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            targetX: 0,
            targetY: 0,
            selectedActionItemId: 0,
            showCommentsCalloutForSurveyId: 0
        };
    }

    public componentDidMount() {
        this.fetch();
        this.fetchTargetGroups();
    }

    private fetch = async () => {
        if (!this.props.activeVectorId) {
            this.setState({
                isLoading: false
            });

            return;
        }

        api.get(`/api/v1/surveys/radar/details/${this.props.activeVectorId}`, {
            params: {
                targetGroup: this.state.targetGroup || undefined,
                question: this.state.question || undefined
            }}
        ).then((response: AxiosResponse) => {
            const surveys = response.data.surveys.map(x => {
                return {
                    ...x,
                    color: this.stringToColor(x.createdDate)
                }
            });

            this.setState({
                isLoading: false,
                data: {
                    ...response.data,
                    surveys,
                    actionItems: response.data.actionItems.map(x => {
                        return {
                            ...x,
                            dueDate: new Date(x.dueDate),
                            vectorId: this.props.activeVectorId,
                            assigneeName: x.assignee ? x.assignee.name : ""
                        }
                    })
                }
            }, () => {
                if (this.surveysListRef.current) {
                    //@ts-ignore
                    this.surveysListRef.current.reFetch();
                }

                if (this.actionItemsListRef.current) {
                    //@ts-ignore
                    this.actionItemsListRef.current.reFetch();
                }
            });
        }).catch(() => {});
    };

    private fetchTargetGroups = () => {
        api.get("/api/v1/targetgroups", {params: {take: 9999, includeEmpty: false}}).then((response) => {
            this.setState({
                targetGroups: response.data.items
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.activeVectorId !== prevProps.activeVectorId) {
            this.setState((prevState) => ({
                question: undefined,
                targetGroup: this.props.activeProjectId !== prevProps.activeProjectId ? 0 : prevState.targetGroup
            }), this.fetch)
        }
    }
    
    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)} noContentStyle pageClassName="DetailsView">
                {this.renderContent()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <VectorSelector />
                </div>
                <div className="header-right">
                    {!this.state.data?.surveys.some((s) => s.isAnonymous) && this.state.targetGroups && (
                        <div className="dropdown-container">
                            <Dropdown
                                defaultSelectedKey={0}
                                label={localize("Malgrupp")}
                                selectedKey={this.state.targetGroup}
                                onChange={(e, v) => {
                                    const id = Number(v.key);

                                    this.setState({
                                        targetGroup: id
                                    }, this.fetch);
                                }}
                                options={[
                                    {
                                        key: 0,
                                        text: localize("Alla")
                                    },
                                    ...this.state.targetGroups.map((tg) => (
                                        {
                                            key: tg.id,
                                            text: tg.name
                                        }
                                    ))
                                ]}
                            />
                        </div>
                    )}
                    <DefaultButton
                        text={localize("EDIT_GOAL")}
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Goal",
                                id: 0,
                                title: localize("ADD_GOAL"),
                                isDisplayed: true
                            }));
                        }}
                    />
                    <Notifications />
                </div>
            </div>
        )
    }

    renderGoalForm() {
        return (
            <FormPopup
                popupFormKey="Goal"
                onClose={() => {
                    this.props.dispatch(setPopupForm({
                        popupFormKey: "Goal",
                        isDisplayed: false
                    }));
                }}>
                <GoalForm
                    value={this.state.data.target}
                    onSaveClick={(value: number) => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Goal",
                            isDisplayed: false
                        }));

                        api.put("/api/v1/surveys/goal/" + this.props.activeVectorId, value, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });

                        this.setState({
                            data: {
                                ...this.state.data,
                                target: value
                            }
                        })
                    }}
                    onCancelClick={() => {
                        onFormClose(this.props.dispatch, () => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Goal",
                                isDisplayed: false
                            }));
                        });
                    }} />
            </FormPopup>
        )
    }

    public renderContent() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        if (this.state.data.surveys.length === 0) {
            return (
                <StatusMessageWithIcon text={localize("NO_DATA_AVAILABLE")} icon="home-1" />
            )
        }

        return (
            <div className="boxed-content">
                <div className="row">
                    <div className="column chart-column">
                        {this.renderChart()}
                    </div>
                    <div className="column">
                        {this.renderSurveys()}
                    </div>
                </div>
                {this.props.pro && (
                    <>
                        <div className="row">
                            <div className="column">
                                {this.renderActionList()}
                            </div>
                        </div>
                        {this.renderActionForm()}
                    </>
                )}
                {this.renderGoalForm()}
            </div>
        );
    }

    private stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
    };

    getSurveyForChart() {
        const surveyLimit = 6;

        const surveyChartCount = this.state.data.surveys.length < surveyLimit ?
            this.state.data.surveys.length :
            surveyLimit;

        const surveys = [];

        for (let i = 0; i < surveyChartCount; i++) {
            surveys.push(this.state.data.surveys[i]);
        }

        return surveys;
    }

    getChartData() {
        const surveys = this.getSurveyForChart();

        return {
            labels: [
                "",
                ...surveys.map((s) =>
                    moment(s.createdDate).format("L")
                ),
                ""
            ],
            datasets: [
                {
                    borderColor: Style.getHexFromSpintrColor("p-green-2"),
                    tension: 0.5,
                    data: [
                        surveys[0]?.value || 0,
                        ...surveys.map((s) => s.value),
                        surveys[surveys.length - 1]?.value || 0,
                    ],
                    pointBorderWidth: 0,
                    pointBorderColor: 'transparent',
                    pointRadius: 6,
                    pointHoverRadius: 7,
                    pointBackgroundColor: [
                        "transparent",
                        ...surveys.map((s) => s.color),
                        "transparent",
                    ],
                    gradient: {
                      borderColor: {
                        axis: 'x',
                        colors: {
                          0: '#00A57815',
                          100: '#00A578',
                        },
                      },
                    },
                },
            ],
        }
    }

    getChartOptions() {
        return LineChartOptions(
            this.state.data.target || 0,
            100,
            (x: number, y: number) => {
                if (this.state.targetX === x &&
                    this.state.targetY === y) {
                    return;
                }

                this.setState({
                    targetX: x,
                    targetY: y
                });
            },
        );
    }

    renderChart() {
        return (
            <div className="chart-wrapper">
                <ActionMenu
                    categories={[{
                        items: [
                            {
                                key: 0,
                                text: localize("Alla"),
                                canCheck: true,
                                isChecked: !this.state.question,
                                onClick: () => {
                                    this.setState({
                                        question: 0,
                                    }, this.fetch);
                                }
                            },
                            ...this.state.data.questions.map((q) => ({
                                key: q.id,
                                active: true,
                                text: q.text,
                                canCheck: true,
                                isChecked: this.state.question === q.id,
                                onClick: () => {
                                    this.setState({
                                        question: q.id,
                                    }, this.fetch);
                                }
                            }))
                        ]
                    }]}
                    renderButton={() => <UnstyledButton><Visage2Icon icon="filter" /></UnstyledButton> }
                />
                <Line
                    // @ts-ignore
                    ref={this.chartRef}
                    // @ts-ignore
                    data={this.getChartData()}
                    // @ts-ignore
                    options={this.getChartOptions()}
                />
                {this.state.data.target > 0 && (
                    <div
                        className="goal-label"
                        style={{
                            top: this.state.targetY,
                            left: this.state.targetX,
                        }}>
                        <Label size="body-3" color="p-white" fontType="display">{localize("GOAL")}</Label>
                    </div>
                )}
            </div>
        )
    }

    renderSurveys() {
        if (this.state.data.surveys.length === 0) {
            return (
                <StatusMessageWithIcon text={localize("NO_DATA_AVAILABLE")} icon="home-1" noSpacing />
            )
        }

        return (
            <SpintrList
                ref={this.surveysListRef}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        const compare = (a, b) => {
                            const aVal = a[columnId];
                            const bVal = b[columnId];

                            if (isAscending) {
                                return aVal > bVal ? 1 : -1;
                            } else {
                                return aVal < bVal ? 1 : -1;
                            }
                        }

                        const items = [...this.state.data.surveys].sort(compare);

                        resolve({
                            data: items,
                            totalCount: this.state.data.surveys.length
                        });
                    });
                }}
                take={1000}
                isDescending
                orderByColumn={"createdDate"}
                columns={[
                    {
                        name: localize("SURVEY"),
                        fieldName: "createdDate",
                        minWidth: 200,
                        onRender: (item) => {
                            return (
                                <div className="survey-name">
                                    <div className="color-ball" style={{
                                        backgroundColor: item.color
                                    }} />
                                    <div>
                                        {moment(item.createdDate).format("ll")}
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        name: localize("AntalSvar"),
                        fieldName: "userAnswerCount",
                        minWidth: 75,
                        onRender: (item) => {
                            return (
                                <span>
                                    {item.userAnswerCount}/{item.userTotalCount}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("SCORE"),
                        fieldName: "value",
                        minWidth: 75,
                        onRender: (item) => {
                            return (
                                <span>
                                    {item.value}%
                                </span>
                            );
                        },
                    },
                    {
                        name: capitalizeFirstLetter(localize("COMMENTS")),
                        fieldName: "comments",
                        onRender: (item) => {
                            const id = "comments-" + item.id;
                            const isEmpty = item.comments.length === 0;

                            return (
                                <div>
                                    <UnstyledButton
                                        disabled={isEmpty}
                                        onClick={() => {
                                            this.setState({
                                                showCommentsCalloutForSurveyId: item.id
                                            });
                                        }}
                                        className="survey-comments-button"
                                        id={id}>
                                        <Visage2Icon icon="message" color={isEmpty ? "p-dark-grey-1" : "p-link-color"} size="small" />
                                        <Label color={isEmpty ? "p-dark-grey-1" : "p-link-color"} fontType="display" size="body-2">{item.comments.length}</Label>
                                    </UnstyledButton>
                                    {this.state.showCommentsCalloutForSurveyId === item.id && (
                                        <Callout
                                            className="Phoenix-callout wide"
                                            isBeakVisible={false}
                                            role="dialog"
                                            gapSpace={0}
                                            target={"#" + id}
                                            onDismiss={() => {
                                                this.setState({
                                                    showCommentsCalloutForSurveyId: 0
                                                });
                                            }}
                                            setInitialFocus
                                        >
                                            <div className="Phoenix-callout-header">
                                                <Label weight="medium" fontType="display">{capitalizeFirstLetter(localize("COMMENTS"))}</Label>
                                            </div>
                                            <div className="Comments-list">
                                                {item.comments.map((c: any, index:number) => {
                                                    return (
                                                        <div className="Comment-wrapper" key={c.id}>
                                                            <Label fontType="display" size="body-3" color="p-mid-grey-1">{localize("QUESTION") + ": " + c.question.text}</Label>
                                                            <div className="Comment">
                                                                <SpintrUser
                                                                    imageUrl={c.user ? c.user.imageUrl : ""}
                                                                    big
                                                                    name={c.user ? c.user.name : localize("ANONYMOUS")}
                                                                    hideText
                                                                />
                                                                <div className="Comment-right">
                                                                    <div className="top-row">
                                                                        <Label fontType="display" weight="medium" size="body-2">
                                                                            {c.user ? c.user.name : localize("ANONYMOUS_USER")}
                                                                        </Label>
                                                                        <Label fontType="display" weight="medium" color="p-mid-grey-1" size="body-3">
                                                                            {moment(c.createdDate).format("ll")}
                                                                        </Label>
                                                                    </div>
                                                                    <Label fontType="display" size="body-2" color="p-mid-grey-1">
                                                                        {c.text}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Callout>
                                    )}
                                </div>
                            )
                        }
                    },
                    // {
                    //     name: "",
                    //     minWidth: listActionMenuWidth,
                    //     maxWidth: listActionMenuWidth,
                    //     onRender: (item: any) => {
                    //         return (
                    //             <ActionMenu
                    //                 categories={[
                    //                     {
                    //                         items: [{
                    //                             text: localize("Redigera"),
                    //                             onClick: () => {
                                                    
                    //                             }
                    //                         }, {
                    //                             text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                    //                             onClick: () => {

                    //                             },
                    //                         }],
                    //                     },
                    //                 ]}
                    //             />
                    //         );
                    //     },
                    // },
                ]}
                disableCommandBar
            />
        )
    }

    hideActionItemPopupForm() {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "Action",
                isDisplayed: false
            }));
    
            this.setState({
                selectedActionItemId: 0
            });
        });
    }

    renderActionForm() {
        const selectedAction = this.state.data.actionItems.find(x => x.id === this.state.selectedActionItemId);

        return (
            <FormPopup
                popupFormKey="Action"
                onClose={this.hideActionItemPopupForm.bind(this)}>
                <ActionItemForm
                    item={selectedAction || {
                        priority: 0,
                        status: 1,
                        vectorId: this.props.activeVectorId,
                        dueDate: new Date()
                    }}
                    onDelete={() => {
                        this.fetch();
                        this.hideActionItemPopupForm();
                    }}
                    onSave={() => {
                        this.fetch();
                        this.hideActionItemPopupForm();
                    }}
                    onCancelClick={this.hideActionItemPopupForm.bind(this)} />
            </FormPopup>
        )
    }

    renderActionList() {
        return (
            <div>
                <div className="content-header">
                    <Label fontType="display" size="h4" weight="medium">
                        {localize("AREAS_FOCUS")}
                    </Label>
                    <PrimaryButton
                        onClick={() => {
                            this.setState({
                                selectedActionItemId: 0
                            }, () => {
                                this.props.dispatch(setPopupForm({
                                    popupFormKey: "Action",
                                    id: 0,
                                    title: localize("ADD_ACTION"),
                                    isDisplayed: true
                                }));
                            });
                        }}
                        className="create-button">
                        <Visage2Icon icon="add" />
                        {localize("ADD_ACTION")}
                    </PrimaryButton>
                </div>
                <div className="content-list-wrapper">
                    {this.state.data.actionItems.length === 0 && (
                        <StatusMessageWithIcon text={localize("NO_DATA_AVAILABLE")} icon="home-1" noSpacing />
                    )}
                    {this.state.data.actionItems.length > 0 && (
                        <SpintrList
                            ref={this.actionItemsListRef}
                            fetch={(skip, take, columnId, isAscending, searchQuery) => {
                                return new Promise((resolve, reject) => {
                                    const compare = (a, b) => {
                                        const aVal = a[columnId];
                                        const bVal = b[columnId];

                                        if (isAscending) {
                                            return aVal > bVal ? 1 : -1;
                                        } else {
                                            return aVal < bVal ? 1 : -1;
                                        }
                                    }

                                    const items = [...this.state.data.actionItems].sort(compare);

                                    resolve({
                                        data: items,
                                        totalCount: this.state.data.actionItems.length
                                    });
                                });
                            }}
                            take={1000}
                            isDescending
                            orderByColumn={"priority"}
                            columns={[
                                {
                                    name: localize("ACTION"),
                                    fieldName: "text",
                                },
                                {
                                    name: localize("PRIORITY"),
                                    fieldName: "priority",
                                    minWidth: 100,
                                    onRender: (item) => {
                                        return (
                                            <LabelBox text={localize("PRIORITY_" + item.priority)} prio={item.priority} />
                                        )
                                    }
                                },
                                {
                                    name: localize("DUE_DATE"),
                                    fieldName: "dueDate",
                                    minWidth: 150,
                                    onRender: (item) => {
                                        return (
                                            <span>{moment(item.dueDate).format("ll")}</span>
                                        )
                                    }
                                },
                                {
                                    name: localize("ASSIGNEE"),
                                    fieldName: "assigneeName",
                                    minWidth: 150,
                                    onRender: (item) => {
                                        if (!item.assignee) {
                                            return null;
                                        }

                                        return (
                                            <SpintrUser name={item.assignee.name} imageUrl={item.assignee.imageUrl} />
                                        );
                                    }
                                },
                                {
                                    name: localize("STATUS"),
                                    fieldName: "status",
                                    minWidth: 100,
                                    onRender: (item) => {
                                        return <span>{localize("ACTION_ITEM_STATUS_" + item.status)}</span>;
                                    }
                                },
                                {
                                    name: "",
                                    minWidth: listActionMenuWidth,
                                    maxWidth: listActionMenuWidth,
                                    onRender: (item: any) => {
                                        return (
                                            <ActionMenu
                                                categories={[
                                                    {
                                                        items: [{
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.setState({
                                                                    selectedActionItemId: item.id
                                                                }, () => {
                                                                    this.props.dispatch(setPopupForm({
                                                                        popupFormKey: "Action",
                                                                        id: item.id,
                                                                        title: localize("EDIT_ACTION"),
                                                                        isDisplayed: true
                                                                    }));
                                                                });
                                                            }
                                                        }, {
                                                            text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                            onClick: () => {
                                                                this.props.dispatch(
                                                                    setConfirmPopup({
                                                                        isOpen: true,
                                                                        title: localize("TaBort"),
                                                                        message: localize("ArDuSakerPaAttDuVillTaBortDennaPost"),
                                                                        onConfirm: async () => {
                                                                            api.delete("/api/v1/plans/action/" + item.id).then(() => {
                                                                                this.fetch();
                                                                            }).catch(() => {});
                                                                        },
                                                                    })
                                                                );
                                                            },
                                                        }],
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ]}
                            disableCommandBar
                        />
                    )}
                </div>
            </div>
        )
    }
}

export const LineChartOptions = (
    goal: number,
    max: number,
    onInit: (_x: number, _y: number) => void,
): ChartOptions<'line'> => ({
    responsive: true,
    scales: {
        y: {
            min: 0,
            max: max,
            ticks: {
                count: 5,
                color: Style.getHexFromSpintrColor("p-mid-grey-1"),
                font: {
                    size: 12,
                    family: 'EloquiaDisplay Regular',
                },
                callback: (scale: any) => {
                    return Math.round(scale)
                },
                padding: 8,
                autoSkip: true,
            },
            beginAtZero: true,
            grid: {
                display: true,
                color: Style.getHexFromSpintrColor("p-border-color"),
                lineWidth: 0.5,
                drawTicks: false,
                borderColor: Style.getHexFromSpintrColor("p-border-color"),
                borderWidth: 0.5
            },
        },
        x: {
            ticks: {
                color: Style.getHexFromSpintrColor("p-mid-grey-1"),
                font: {
                    size: 12,
                    family: 'EloquiaDisplay Regular',
                },
                padding: 8
            },
            grid: {
                display: true,
                color: Style.getHexFromSpintrColor("p-border-color"),
                lineWidth: 0.5,
                drawTicks: false,
                tickLength: 0,
                borderColor: Style.getHexFromSpintrColor("p-border-color"),
                borderWidth: 0.5
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: true,
            titleFont: {
                size: 12,
                family: 'EloquiaDisplay Regular',
            },
            bodyFont: {
                size: 12,
                family: 'EloquiaDisplay Regular',
            },
            displayColors: false,
            callbacks: {
                label: (tooltipItem) => {
                    let title = tooltipItem.label;

                    // for (let item of this.props.data) {
                    //     if (item.name == tooltipItem.label &&
                    //         !!item.title) {
                    //         title = item.title;
                    //     }
                    // }

                    return title + ": " + tooltipItem.formattedValue + "%";
                },
                title: () => "",
            },
        },
        legend: {
            display: false,
        },
        annotation: {
            annotations: {
            target: {
                display: !!goal,
                type: 'line',
                drawTime: 'afterDatasetsDraw',
                borderDash: [3],
                borderWidth: 1,
                borderColor: '#787CDD',
                yMin: goal,
                yMax: goal,
                init: ({ properties }: any) => {
                    if (properties) {
                        onInit(properties.x, properties.y)
                    }
                },
            },
            },
        },
    },
  })  

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    hasCustomBodyFont: !!state.instance.currentInstance.bodyFontUrl,
    instanceColor: state.instance.currentInstance.color,
    pro: state.instance.currentInstance.pro,
    isAdmin: state.user.currentUser.isAdmin,
    activeProjectId: state.project.activeProjectId,
    activeVectorId: state.project.activeVectorId,
    projects: state.project.projects
});

export default connect(mapStateToProps)(DetailsView);
