import axios, { CancelTokenSource } from "axios";
import classnames from "classnames";
import {
    ColumnActionsMode, CommandBar,
    DetailsRowFields, IColumn, ICommandBarItemProps, IContextualMenuProps,
    IDragDropEvents, SelectionMode, ShimmeredDetailsList, TooltipHost
} from "@fluentui/react";
import React, { PureComponent } from "react";
import { debounce } from "src/utils";
import "./SpintrList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import SpintrSearch from "./SpintrSearch";
import { localize } from "src/utils/l10n";
import SpintrPagination from "src/components/Pagination/SpintrPagination";
import { Label } from "src/components/Label/Label";
import { ActionMenu } from "src/components/ActionMenu";
import { connect } from "react-redux";
import { ISearchContext } from "src/reducers/ui";

declare type ListType =
    | "list"
    | "cards";

interface IActionMenuItem {
    icon?: string;
    key?: string;
    text: string;
    onClick?: () => void;
    href?: string;
    subMenuProps?: any;
    canCheck?: boolean;
    isChecked?: boolean;
}

interface IActionMenuCategory {
    title?: string;
    items: IActionMenuItem[];
}

interface IProps {
    id?: string;
    fetch: any;
    data?: any; // used with redux
    isLoading?: boolean; // used with redux
    take?: number;
    actionMenu?: IActionMenuCategory[];
    columns: any[];
    buttons?: ICommandBarItemProps[];
    orderByColumn: string;
    history?: any;
    onRowClick?(item: any): void;
    disableSort?: boolean;
    disableCommandBar?: boolean;
    disablePagination?: boolean;
    disableSearch?: boolean;
    hideHeader?: boolean;
    farItems?: any[];
    selectionMode?: any;
    selection?: any;
    isDescending?: boolean;
    emptyContent?: any;
    dragDropEvents?: IDragDropEvents;
    onRenderRow?: any;
    optionItems?: IContextualMenuProps;
    ariaLabel?: string;
    initialSearchText?: string;
    onColumnHeaderClick?(ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void;
    listType?: ListType;
    renderCard?: any;
    renderShimmerCard?: any;
    infiniteScroll?: boolean;
    searchKey?: string;
    contextSearchValue?: string;
}

interface IState {
    isLoading: boolean;
    searchText: string;
    skip: number;
    take: number;
    orderByColumn: string;
    isAscending: boolean;
    data: any;
    totalCount: number;
    activePage: number;
}

function onRenderDetailsHeader(props, defaultRender?) {
    return defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip: (tooltipHostProps) => {
            return <TooltipHost {...tooltipHostProps} />;
        },
    });
}

class SpintrList extends PureComponent<IProps, IState> {
    private cancelTokenSource: CancelTokenSource;
    private lastPromise: React.MutableRefObject<boolean | null> = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: this.props.isLoading !== undefined ? this.props.isLoading : true,
            searchText: this.props.initialSearchText || "",
            skip: 0,
            take: props.take || 10,
            orderByColumn: props.orderByColumn,
            isAscending: props.isDescending ? false : true,
            data: [],
            totalCount: 0,
            activePage: 0,
        };
    }

    componentDidMount(): void {
        if (this.props.id) {
            const stateString = localStorage.getItem("list-" + this.props.id);

            if (stateString) {
                const state = JSON.parse(stateString);

                this.setState({
                    ...this.state,
                    ...state,
                }, () => {
                    this.fetch();
                });
            } else {
                this.fetch();
            }
        } else {
            this.fetch();
        }

        if (this.props.infiniteScroll) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount(): void {
        if (this.props.infiniteScroll) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        const hasMore = this.state.data?.length < this.state.totalCount;

        if (!hasMore ||
            this.state.isLoading) {
            return;
        }

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const diff = height - winScroll;

        if (diff < 100) {
            this.setState({
                activePage: this.state.activePage + 1,
            }, () => {
                this.fetch(true);
            });
        }
    }

    isScrollbarVisible = () => {
        const scrollbarVisible = document.body.clientHeight > (document.documentElement.clientHeight + 10);
        return scrollbarVisible;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.listType === "cards" && prevState.isLoading !== this.state.isLoading) {
            const hasNext = this.state.data?.length < this.state.totalCount

            if (!this.state.isLoading && hasNext && !this.isScrollbarVisible()) {
                // console.log("Fetching more becacuse, ", this.isScrollbarVisible())
                this.fetch(true);
            }

        }

        if (this.props.data) {
            this.setState({
                data: this.props.data.data,
                totalCount: this.props.data.totalCount,
                isLoading: this.props.isLoading !== undefined ? this.props.isLoading : this.state.isLoading,
            });
        }

        if (this.props.id) {
            localStorage.setItem("list-" + this.props.id, JSON.stringify(this.state));
        }

        if (this.props.contextSearchValue !== prevProps.contextSearchValue) {
            this.searchEvent(null, this.props.contextSearchValue);
        }
    }

    getSearchText = () => this.state.searchText;

    reFetch(): void {
        this.setState(
            {
                activePage: 0,
            },
            () => this.fetch(false, true)
        );
    }

    fetch = async (fetchMore = false, isSilent = false): Promise<any> => {
        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource.cancel("Operation canceled due to new request.");
        }

        this.cancelTokenSource = axios.CancelToken.source();

        this.setState(
            {
                isLoading: !isSilent,
            },
            () => {
                const fetch = this.props.fetch(
                    this.state.activePage * this.state.take,
                    this.state.take,
                    this.state.orderByColumn,
                    this.state.isAscending,
                    this.state.searchText,
                    this.cancelTokenSource.token,
                    fetchMore
                );

                if (fetch && fetch.then) {
                    this.lastPromise.current = fetch;
                    fetch.then((response) => {
                        if (fetch === this.lastPromise.current) {
                            // Protect against out of order network requests
                            this.setState({
                                data: response.data ? response.data : this.state.data,
                                totalCount: response.totalCount ? response.totalCount : this.state.totalCount,
                                isLoading: this.props.isLoading !== undefined ? this.props.isLoading : false,
                            });
                        }
                    });
                }
            }
        );
    };

    renderActionMenu(): JSX.Element {
        return <ActionMenu categories={this.props.actionMenu} />;
    }

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, value: string): void => {
        this.setState(
            {
                skip: 0,
                activePage: 0,
                searchText: !!value ? value : "",
            },
            this.debouncedFetchSearch
        );
    };

    onPageChange = (page: number): void => {
        this.setState(
            {
                activePage: page,
            },
            () => this.fetch()
        );
    };

    rowFieldAs = (props) => {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    };

    cardLoader = () => {
        return <div className="SpintrList-cards">
            {
                Array.from(Array(this.props.take).keys()).map((item: any, index: number) => {
                    return this.props.renderShimmerCard(index);
                })
            }
        </div>
    }

    render(): JSX.Element {
        const allSelected =
            this.props.selection && this.props.selection._selectedItems?.length === this.props.selection._items?.length;

        return (
            <div
                aria-label={this.props.ariaLabel}
                className={classnames("spintr-list", {
                    isEmpty: !this.state.isLoading && this.state.data.length === 0,
                    disableSort: this.props.disableSort,
                })}
            >
                {!this.props.disableCommandBar && (
                    <CommandBar
                        className={
                            "spintr-list-command-bar" + (this.props.disableSearch ? "" : " CommandBar-GrowSearch")
                        }
                        items={[
                            ...(!this.props.disableSearch
                                ? [
                                    {
                                        key: "search",
                                        onRender: () => {
                                            return (
                                                <SpintrSearch
                                                    value={this.state.searchText}
                                                    onChange={this.searchEvent} />
                                            )
                                        },
                                    },
                                ]
                                : []),
                            ...(this.props.optionItems
                                ? [
                                    {
                                        key: "alternativ",
                                        name: localize("Alternativ"),
                                        subMenuProps: {
                                            ...this.props.optionItems,
                                            isBeakVisible: false
                                        },
                                    },
                                ]
                                : []),
                            ...(this.props.buttons ? [...this.props.buttons] : []),
                        ]}
                    // farItems={[
                    //     ...(this.props.farItems
                    //         ? this.props.farItems
                    //         : [
                    //             ...(this.props.actionMenu
                    //                 ? [
                    //                     {
                    //                         key: "actionMenu",
                    //                         text: "",
                    //                         iconProps: { iconName: "More" },
                    //                         onRender: () => {
                    //                             return this.renderActionMenu();
                    //                         },
                    //                     },
                    //                 ]
                    //                 : []),
                    //         ]),
                    // ]}
                    />
                )}
                {
                    (!this.props.listType || this.props.listType === "list") && (
                        <ShimmeredDetailsList
                            onRenderDetailsHeader={onRenderDetailsHeader}
                            compact={true}
                            // checkboxVisibility={CheckboxVisibility.always}
                            onItemInvoked={this.props.onRowClick ? this.props.onRowClick : null}
                            isHeaderVisible={this.props.hideHeader ? false : true}
                            enableShimmer={this.state.isLoading && !this.props.infiniteScroll}
                            selectionMode={this.props.selectionMode ? this.props.selectionMode : SelectionMode.none}
                            selection={this.props.selection ? this.props.selection : undefined}
                            ariaLabelForSelectionColumn={localize("Markera")}
                            ariaLabelForSelectAllCheckbox={allSelected ? localize("AvmarkeraAlla") : localize("MarkeraAlla")}
                            checkButtonAriaLabel={localize("Markera")}
                            onColumnHeaderClick={
                                !this.props.disableSort
                                    ? (event, column) => {
                                        this.setState(
                                            (prevState) => ({
                                                orderByColumn: column.fieldName,
                                                isAscending:
                                                    prevState.orderByColumn === column.fieldName
                                                        ? !this.state.isAscending
                                                        : true,
                                            }),
                                            () => {
                                                this.props?.onColumnHeaderClick && this.props.onColumnHeaderClick(event, column)
                                                this.reFetch()
                                            }
                                        );
                                    }
                                    : undefined
                            }
                            onRenderRow={this.props.onRenderRow ? this.props.onRenderRow : undefined}
                            columns={this.props.columns.map((column) => {
                                return {
                                    ...column,
                                    columnActionsMode: column.columnActionsMode
                                        ? column.columnActionsMode
                                        : this.props.disableSort
                                            ? ColumnActionsMode.disabled
                                            : !column.name
                                                ? ColumnActionsMode.disabled
                                                : ColumnActionsMode.clickable,
                                    key: column.key ? column.key : this.props.columns.indexOf(column),
                                    minWidth: column.minWidth || 100,
                                    isSorted: this.state.orderByColumn === column.fieldName,
                                    isSortedDescending: !this.state.isAscending,
                                    headerClassName: "custom-header-styling",
                                    ...(column.onRender
                                        ? {
                                            onRender: (item) => column.onRender(item, this),
                                        }
                                        : {}),
                                };
                            })}
                            items={this.state.data}
                            dragDropEvents={this.props.dragDropEvents}
                            selectionPreservedOnEmptyClick
                        />
                    )
                }
                {
                    this.props.listType === "cards" && (
                        <div>
                            {
                                this.state.isLoading && this.state.data.length === 0 ? this.cardLoader() :
                                    <InfiniteScroll
                                        className="SpintrList-cards"
                                        dataLength={this.state.data?.length || 0}
                                        next={() => this.fetch(true)}
                                        hasMore={this.state.data?.length < this.state.totalCount}
                                        loader={<this.cardLoader />}
                                    >
                                        {this.state.data.map((item: any, index: number) => {
                                            return this.props.renderCard(item, index);
                                        })}
                                    </InfiniteScroll>
                            }
                        </div>
                    )
                }
                {!this.state.isLoading && this.state.data.length === 0 && this.props.emptyContent && (
                    <>{this.props.emptyContent}</>
                )}
                {!this.state.isLoading && this.state.data.length === 0 && !this.props.emptyContent ? (
                    <div className="textNoTodo">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                ) : null}
                {!this.props.disablePagination && !this.props.infiniteScroll && (
                    <SpintrPagination
                        pageSize={this.state.take}
                        totalCount={this.state.totalCount}
                        activePage={this.state.activePage}
                        onPageChange={this.onPageChange}
                    />
                )}
                {this.state.isLoading && this.props.infiniteScroll && (
                    <ShimmeredDetailsList
                        items={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                        enableShimmer={true}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={false}
                        columns={this.props.columns.map((column) => {
                            return {
                                ...column,
                                columnActionsMode: column.columnActionsMode
                                    ? column.columnActionsMode
                                    : this.props.disableSort
                                        ? ColumnActionsMode.disabled
                                        : !column.name
                                            ? ColumnActionsMode.disabled
                                            : ColumnActionsMode.clickable,
                                key: column.key ? column.key : this.props.columns.indexOf(column),
                                minWidth: column.minWidth || 100,
                                isSorted: this.state.orderByColumn === column.fieldName,
                                isSortedDescending: !this.state.isAscending,
                                headerClassName: "custom-header-styling",
                                ...(column.onRender
                                    ? {
                                        onRender: (item) => column.onRender(item, this),
                                    }
                                    : {}),
                            };
                        })}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    contextSearchValue: state.ui.contexts.find((x: ISearchContext) => x.key === props.searchKey)?.value || ""
});

export default connect(mapStateToProps, {}, null, {forwardRef: true})(SpintrList);
