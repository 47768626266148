import { DefaultButton, Dropdown, Pivot, PivotItem, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { SpintrTypes } from 'src/typings';
import { validateRequiredTextField } from 'src/utils';
import { fetchLangTags, localize } from 'src/utils/l10n';
import FormControl from 'src/components/FormControl/FormControl';
import FormSection from 'src/components/FormSection/FormSection';
import FormTokenizedObjectInput from 'src/components/FormTokenizedObjectInput/FormTokenizedObjectInput';
import Loader from 'src/components/Loader/Loader';
import ErrorMessagebar from 'src/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/components/Messagebars/SuccessMessagebar';

import './UserForm.scss';
import FileSelector, { IUploadModel } from '../FileSelector/FileSelector';
import { fetchCurrentUser } from 'src/actions/user';
import getLanguages from 'src/utils/getLanguages';
import { setPopupForm } from 'src/actions/ui';
import Label from '../Label/Label';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';

interface IProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    id: number;
    instanceId?: number;
    chooseInstance?: boolean;
}

interface IState {
    isLoading: boolean;
    isNew: boolean;
    item: any;
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
    instances?: any[];
}

class UserForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const isNew = props.id === 0;

        this.state = {
            isLoading: !isNew,
            isNew,
            item: {
                id: 0,
                language: props.currentInstance.language
            },
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false
        };
    }

    componentDidMount() {
        if (!this.state.isNew) {
            const id = this.props.id;
            const instanceId = this.props.instanceId;

            api.get("api/v1/users/" + id, {
                params: {
                    instanceId,
                },
            }).then((response: any) => {
                const item = {
                    ...response.data,
                    language: !!response.data.language ? response.data.language : this.props.currentInstance.language,
                }

                this.setState({
                    item,
                    isLoading: false
                });
            }).catch(this.handleCatch.bind(this))
        }
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    onCancelClick = () => {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "User",
                isDisplayed: false
            }));
        });
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            const payload = { ...this.state.item };
            let saveError: string[] = [];

            if (!payload.name) {
                saveError.push("DuMasteFyllaIEttNamn");
            }

            if (!payload.email) {
                saveError.push("DuMasteFyllaIEnEpost");
            }

            if (this.props.chooseInstance && !payload.instance) {
                saveError.push("DuMasteValjaEnKund")
            }

            if (payload.instance) {
                payload.instanceId = payload.instance.key;
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/users", payload).then(() => {
                if (payload.id === this.props.currentUser.id) {
                    this.props.dispatch(fetchCurrentUser());

                    if (this.props.currentUser.language !== payload.language) {
                        fetchLangTags(() => {
                            window.location.reload();
                        });
                    }
                }
                this.props.dispatch(setPopupForm({
                    popupFormKey: "User",
                    isDisplayed: false
                }));
                this.props.dispatch(setPopupForm({
                    popupFormKey: "Profile",
                    isDisplayed: false
                }));
            }).catch(this.handleCatch.bind(this));
        });
    }

    render() {
        const languages = getLanguages();

        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="UserForm fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.onSaveClick.bind(this)} data={this.state.item}>
                    <Pivot>
                        <PivotItem headerText={localize("Allmant")}>
                            <FormSection>
                                {this.props.chooseInstance && (
                                    <FormTokenizedObjectInput
                                        url={"/api/v1/instances"}
                                        label={localize("CUSTOMER")}
                                        items={
                                            this.state.item.instance
                                                ? [this.state.item.instance]
                                                : []
                                        }
                                        onChange={(instances) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    instance: instances[0],
                                                    instanceId: instances[0]?.key,
                                                },
                                            });
                                        }}
                                        required
                                        itemLimit={1}
                                    />
                                )}
                                <FormControl>
                                    <TextField
                                        value={this.state.item.name}
                                        className="textField"
                                        label={localize("Namn")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    name: newValue
                                                }
                                            });
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        value={this.state.item.email}
                                        className="textField"
                                        label={localize("Epost")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    email: newValue
                                                }
                                            });
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        value={this.state.item.phone}
                                        className="textField"
                                        label={localize("PHONE_NUMBER")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    phone: newValue
                                                }
                                            });
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Dropdown
                                        label={localize("Sprak")}
                                        selectedKey={this.state.item.language.toString()}
                                        onChange={(_e, v) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    language: Number(v.key)
                                                }
                                            });
                                        }}
                                        options={languages}
                                        styles={{ dropdown: { width: 250 } }}
                                    />
                                </FormControl>
                                <FormControl label={localize("BildProfil")}>
                                    <FileSelector
                                        isImage
                                        fileUrl={this.state.item.imageUrl}
                                        instanceId={this.state.item.instanceId}
                                        onChange={(model: IUploadModel) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    imageUrl: model.thumbnailUrl,
                                                    ticket: model,
                                                }
                                            });
                                        }} />
                                </FormControl>
                            </FormSection>
                        </PivotItem>
                        <PivotItem headerText={localize("Malgrupper")}>
                            <FormTokenizedObjectInput
                                items={this.state.item.targetGroups}
                                types={[SpintrTypes.UberType.User]}
                                label={localize("Malgrupper")}
                                url={"/api/v1/targetgroups"}
                                enableNewItems
                                customParams={{
                                    instanceId: this.state.item.instanceId,
                                }}
                                validateOnLoad={this.state.enableFieldValidation}
                                onChange={(targetGroups) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            targetGroups: targetGroups.map((e) => {
                                                let id = e.id ? e.id : parseInt(e.key);

                                                if (!id) {
                                                    id = 0;
                                                }

                                                return {
                                                    id,
                                                    name: e.name,
                                                    type: SpintrTypes.UberType.User,
                                                    key: e.key ? e.key : e.id,
                                                    info: e.subText,
                                                    imageUrl: e.imageUrl,
                                                };
                                            }),
                                        }
                                    });
                                }}
                            />
                        </PivotItem>
                        <PivotItem headerText={localize("Rattigheter")}>
                            <FormSection>
                                <FormControl>
                                    <Dropdown
                                        label={localize("Roll")}
                                        selectedKey={this.state.item.canLogIn ? (this.state.item.isAdmin ? "2" : "1") : "0"}
                                        required
                                        onChange={(_e, v) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    canLogIn: v.key === "1" || v.key === "2",
                                                    isAdmin: v.key === "2"
                                                }
                                            });
                                        }}
                                        options={[
                                            ...[{
                                                key: "0",
                                                text: localize("Mottagare_Singular")
                                            }, {
                                                key: "1",
                                                text: localize("USER")
                                            }],
                                            ...(this.props.currentUser.isAdmin ? [{
                                                key: "2",
                                                text: localize("Administrator")
                                            }] : [])
                                        ]}
                                        styles={{ dropdown: { width: 250 } }}
                                    />
                                </FormControl>
                                <Label
                                    size="body-2"
                                    className="form-info-text"
                                    color={this.state.item.isAdmin ? "p-red-4" : "p-mid-grey-1"}>
                                    {localize("ROLE_DESCRIPTION_" + (this.state.item.canLogIn ? (this.state.item.isAdmin ? "2" : "1") : "0"))}
                                </Label>
                            </FormSection>
                        </PivotItem>
                    </Pivot>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                        <PrimaryButton type={"submit"} text={localize("Spara")} />
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(UserForm);
