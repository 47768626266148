import React from "react";
import "./AnimatedDots.scss";

const AnimatedDots = () => {
    return (
        <div className="AnimatedDots">
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </div >
    )
}

export default AnimatedDots;
