import React, { useState } from "react";
import "./GoalForm.scss";
import FormSection from "../FormSection/FormSection";
import FormControl from "../FormControl/FormControl";
import { DefaultButton, PrimaryButton, SpinButton, Stack } from "@fluentui/react";
import { localize } from "src/utils/l10n";

interface IProps {
    value?: number;
    onSaveClick: any;
    onCancelClick: any;
}

const getNumericPart = (value: string): number | undefined => {
    const valueRegex = /^(\d+(\.\d+)?).*/;
    if (valueRegex.test(value)) {
      const numericValue = Number(value.replace(valueRegex, '$1'));
      return isNaN(numericValue) ? undefined : numericValue;
    }
    return undefined;
};

const GoalForm = (props: IProps) => {
    const [value, setValue] = useState(props.value || 0);
    const classNames = ["GoalForm"];

    const min = 0;
    const max = 100;
    const suffix = "%";

    return (
        <div className={classNames.join(" ")}>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onSaveClick(value);
            }}>
                <FormSection>
                    <FormControl>
                        <SpinButton
                            className="spinButton"
                            defaultValue={value.toString() + suffix}
                            min={min}
                            max={max}
                            step={1}
                            onIncrement={(value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
                                const numericValue = getNumericPart(value);

                                if (numericValue !== undefined) {
                                    return String(Math.min(numericValue + 2, max)) + suffix;
                                }
                            }}
                            onDecrement={(value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
                                const numericValue = getNumericPart(value);

                                if (numericValue !== undefined) {
                                    return String(Math.max(numericValue - 2, min)) + suffix;
                                }
                            }}
                            onValidate={(value: string) => {
                                let numericValue = getNumericPart(value);

                                if (numericValue !== undefined) {
                                    numericValue = Math.min(numericValue, max);
                                    numericValue = Math.max(numericValue, min);
                                    return String(numericValue) + suffix;
                                }
                            }}
                            onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                let numericValue = getNumericPart(newValue);

                                if (numericValue !== undefined) {
                                    numericValue = Math.min(numericValue, max);
                                    numericValue = Math.max(numericValue, min);
                                } else {
                                    numericValue = 0;
                                }

                                setValue(Number(numericValue));
                            }}
                        />
                    </FormControl>
                </FormSection>
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                    <DefaultButton
                        onClick={props.onCancelClick}
                        text={localize("Avbryt")} />
                    <PrimaryButton type={"submit"} text={localize("Spara")} />
                </Stack>
            </form>
        </div>
    )
}

export default GoalForm;
