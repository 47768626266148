import { setMomentLang } from "src/utils/l10n";
import * as actionTypes from "../action-types/user";

export interface IUserState {
    currentUser?: any;
    isLoading: boolean;
    hasFetched: boolean;
}

const initialState: IUserState = {
    currentUser: null,
    isLoading: false,
    hasFetched: false
};

const setLang = (lang) => {
    let language = !!lang ?
        parseInt(lang, 10) :
        3;

    if (isNaN(language)) {
        language = 3;
    }

    setMomentLang(language);
}

const userReducer = (state: IUserState = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CURRENT_USER_PENDING:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.FETCH_CURRENT_USER_FULFILLED:
            setLang(action.payload.data.language);

            return {
                ...state,
                currentUser: action.payload.data,
                isLoading: false,
                hasFetched: true
            }
        case actionTypes.SET_CURRENT_USER:
            setLang(({
                ...state.currentUser,
                ...action.meta.item
            }).language);

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.meta.item
                }
            }
        default:
            return state;
    }
};

export default userReducer;
