import { SpintrTypes } from "src/typings";
import { localize } from "./l10n";

export default function getNotificationText(notification: any): string {
    if (notification.type === SpintrTypes.NotificationType.SurveyAnswer) {
        return localize("NOTIFICATION_SURVEY_ANSWER_TEXT").replace("{{PROJECTNAME}}", notification.projectName);
    } else if (notification.type === SpintrTypes.NotificationType.SurveyDispatch) {
        return localize("NOTIFICATION_SURVEY_DISPATCH_TEXT").replace("{{PROJECTNAME}}", notification.projectName);
    } else {
        return "";
    }
}