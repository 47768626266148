import React from 'react';
import { ReactSVG } from 'react-svg';
import * as Style from 'src/utils/style';
import './Visage2Icon.scss';

interface IProps {
    icon: string;
    type?: "bold" | "outline" | "custom" | undefined;
    size?: "extra-small" | "small" | "medium" | "big" | "extra-big" | undefined;
    color?: spintrColors;
    hexColor?: string;
    className?: string;
    title?: string;
    style?: any;
}

const Visage2Icon = (props: IProps) => {
    if (!props.icon) {
        return null;
    }

    const src = "/visage2icons/" + (props.type || "outline") + "/" + props.icon + ".svg";

    const classes = ["Visage2Icon",
        ("Visage2Icon-" + props.icon.toLowerCase()),
        (props.className || ""),
        ("icon-size-" + (props.size || "medium"))];

    if (props.color || !props.hexColor) {
        classes.push("icon-color-" + (props.color || "p-dark-grey-1"));
    }

    const className = classes.filter(x => !!x).join(" ");

    if (!props.icon) {
        return null;
    }

    return (
        <ReactSVG
            src={src}
            title={props.title}
            className={className}
            style={props.style}
            beforeInjection={(svg) => {
                if (!props.hexColor) {
                    return;
                }

                const color = !!props.hexColor ? props.hexColor : Style.getHexFromSpintrColor(props.color || "p-dark-grey-1");

                for (let i = 0; i < svg.children.length; i++) {
                    //@ts-ignore
                    svg.children[i].style["fill"] = color;
                }
            }}
            fallback={() => {
                console.log("ICON ERROR: '" + src + "' NOT FOUND.")
                return null;
            }} />
    )
}

export default Visage2Icon;