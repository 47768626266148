import React, { CSSProperties, useMemo } from "react";
import { IVectorChartPosition } from "./RadarViewInner"
import classNames from "classnames";
import LabelLine1 from "src/style/images/chat-label-line-1.png";
import LabelLine2 from "src/style/images/chat-label-line-2.png";
import LabelLine3 from "src/style/images/chat-label-line-3.png";
import LabelLine4 from "src/style/images/chat-label-line-4.png";
import Container from "../external/container";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import Label from "../Label/Label";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { IApplicationState } from "src/reducer";
import { setActiveVectorId } from "src/actions/project";

interface IProps extends RouteComponentProps {
    id: number;
    position: IVectorChartPosition;
}

const VectorChartLabel = (props: IProps) => {
    const dispatch = useDispatch();
    const [className, style]: [string, CSSProperties] = useMemo(() => {
        const { index, total, top, right, left, chartWidth } = props.position
        const inverseIndex = total - index - 1
        let _className = ''
        const _style: CSSProperties = {}
        if (index === 0) {
            _className = 'top'
            if (total === 4) {
                _className += ' invert'
            }
            _style.top = top
            _style.left = left
        } else if (index <= Math.floor(total / 2) - 1) {
            _className = 'right'
            _style.top = top - 3
            _style.left = left
        } else if (inverseIndex < Math.floor(total / 2) - 1) {
            _className = 'left'
            _style.top = top - 6
            _style.left = left
        } else {
            _className = 'bottom'
            if (right / chartWidth <= 0.5) {
                 _className += " bottom-left"
            } else {
                _className += " bottom-right";
            }
            _style.top = top
            _style.left = left;
        }

        return [_className, _style]
    }, [props.position])

    return (
        <div className={classNames('chart-label-wrapper', className)} style={style}>
            <div className="line-wrapper">
                <img src={LabelLine1} alt="" />
                <img src={LabelLine2} alt="" />
                <img src={LabelLine3} alt="" />
                <img src={LabelLine4} alt="" />
            </div>
            <Container
                align="center"
                gap={6}
                className="chart-label"
                onClick={() => {
                    dispatch(setActiveVectorId(props.id));
                    props.history.push("/vector-details");
                }}
            >
                {/* <Text
                ellipsis
                font="medium"
                size={16}
                lineHeight={20}
                color="--primary-default"
                >
                {position.text}
                </Text> */}
                <Label>
                    {props.position.text}
                </Label>
                <Visage2Icon icon="arrow-circle-right" />
            </Container>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
});

export default withRouter(connect(mapStateToProps)(VectorChartLabel));