import React from "react";
import "./LabelBox.scss";
import Label from "../Label/Label";

interface IProps {
    text: string;
    prio?: number;
}

const LabelBox = (props: IProps) => {
    const classNames = ["LabelBox"];

    classNames.push("prio-" + props.prio);

    return (
        <div className={classNames.join(" ")}>
            <Label weight="medium" color="p-mid-grey-1" fontType="display" size="body-2">{props.text}</Label>
        </div>
    )
}

export default LabelBox;
