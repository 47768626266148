import { localize } from "./l10n";

function validateRequiredTextField(value: string) {
    if (value.length > 0) {
        return "";
    } else {
        return localize("VALIDATION_FIELDNAME_IS_REQUIRED");
    }
}

export default validateRequiredTextField;