import * as actionTypes from "../action-types/project";

export interface IProjectState {
    projects: any[];
    isLoading: boolean;
    hasFetched: boolean;
    activeProjectId: number;
    activeVectorId: number;
}

const initialState: IProjectState = {
    projects: [],
    isLoading: false,
    hasFetched: false,
    activeProjectId: 0,
    activeVectorId: 0
};

const projectReducer = (state: IProjectState = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROJECTS_PENDING:
            return {
                ...state,
                isLoading: true,
            }
        case actionTypes.FETCH_PROJECTS_FULFILLED:
            const activeProjectId = action.payload.data.items.length > 0 && (state.activeProjectId === 0 || !action.payload.data.items.some((i) => i.id === state.activeProjectId)) ?
                action.payload.data.items[0].id :
                state.activeProjectId;

            const activeProject = action.payload.data.items.find((i) => i.id === activeProjectId);

            return {
                ...state,
                projects: action.payload.data.items,
                isLoading: false,
                hasFetched: true,
                activeProjectId: activeProjectId,
                activeVectorId: action.payload.data.items.length > 0 && (state.activeVectorId === 0 || !activeProject.vectors.some((v) => v.id === state.activeVectorId)) ? activeProject.vectors[0].id :
                    state.activeVectorId,
            }
        case actionTypes.SET_ACTIVE_PROJECT_ID:
            return {
                ...state,
                activeProjectId: action.meta.id,
                activeVectorId: !!state.projects.find(x => x.id === action.meta.id) ?
                    state.projects.find(x => x.id === action.meta.id).vectors[0].id :
                    0
            }
        case actionTypes.SET_ACTIVE_VECTOR_ID:
            return {
                ...state,
                activeVectorId: action.meta.id
            }
        default:
            return state;
    }
};

export default projectReducer;
