import React, { useCallback, useState } from "react";
import "./ProjectSelector.scss";
import { connect } from "react-redux";
import { IApplicationState } from "src/reducer";
import Label from "../Label/Label";
import { ContextualMenu, IContextualMenuItemProps } from "@fluentui/react";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import { localize } from "src/utils/l10n";
import moment from "moment";
import { setActiveProjectId, setActiveVectorId } from "src/actions/project";
import { getProjectStatusColor } from "src/utils";

interface IProps {
    projects?: any[];
    activeProjectId?: number;
    activeVectorId?: number;
    dispatch?: any;
}

interface ProjectSelectorProjectProps {
    item: any;
}

const VectorSelectorVector = (props: ProjectSelectorProjectProps) => {
    return (
        <div className="ProjectSelectorProject">
            <div className="project-selector-top">
                <Label size="h5" weight="semi-bold" fontType="display">{props.item.name}</Label>
            </div>
        </div>
    )
}

const ProjectSelectorProject = (props: ProjectSelectorProjectProps) => {
    return (
        <div className="ProjectSelectorProject">
            <div className="project-selector-top">
                <Label size="h5" weight="semi-bold" fontType="display">{props.item.name}</Label>
            </div>
            <div className="project-selector-meta">
                <span>
                    <Label color="p-mid-grey-1" size="body-3">{localize("CREATED")}</Label>
                    <Label color="p-mid-grey-5" size="body-3">{moment(props.item.createdDate).format("ll")}</Label>
                </span>
                <Label color={getProjectStatusColor(props.item.status)} size="body-3">{localize("PROJECT_STATUS_" + props.item.status)}</Label>
            </div>
        </div>
    )
}

const VectorSelector = (props: IProps) => {
    const linkRef = React.useRef(null);
    const projectLinkRef = React.useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showProjectMenu, setShowProjectMenu] = useState(false);

    const getActiveProject = useCallback(() => {
        return props.projects.find(x => x.id === props.activeProjectId);
    }, [props.activeProjectId]);

    const getActiveVector = useCallback(() => {
        return props.projects.find(x => x.id === props.activeProjectId).vectors.find(x => x.id === props.activeVectorId);
    }, [props.activeVectorId]);

    const activeProject = getActiveProject();
    const activeVector = getActiveVector();

    if (!activeProject || !activeVector) {
        return null;
    }

    return (
        <div className="ProjectSelector">
            <div>
                <UnstyledButton onClick={() => {
                    setShowMenu(true);
                }}>
                    <div className="project-selector-top" ref={linkRef}>
                        <Label size="h3" weight="semi-bold" fontType="display">{activeVector.name}</Label>
                        <Visage2Icon icon="arrow-down-1" size="small" />
                    </div>
                </UnstyledButton>
                <div className="project-selector-meta">
                    <span>
                        <Label color="p-mid-grey-5" size="body-3">{localize("PROJECT") + ": "}</Label>
                        <div ref={projectLinkRef} className="vector-selector-project-part">
                            <UnstyledButton onClick={() => {
                                setShowProjectMenu(true);
                            }}>
                                <Label size="body-3" color="p-link-color">{activeProject.name}</Label>
                                <Visage2Icon icon="arrow-down-1" color="p-link-color" size="extra-small" />
                            </UnstyledButton>
                        </div>
                    </span>
                    <span>
                        <Label color="p-mid-grey-5" size="body-3">{localize("CREATED") + ": "}</Label>
                        <Label color="p-mid-grey-5" size="body-3">{moment(activeProject.createdDate).format("ll")}</Label>
                    </span>
                    <span>
                        <Label color="p-mid-grey-5" size="body-3">{localize("STATUS") + ": "}</Label>
                        <Label color={getProjectStatusColor(activeProject.status)} size="body-3">{localize("PROJECT_STATUS_" + activeProject.status)}</Label>
                    </span>
                </div>
            </div>
            <ContextualMenu
                className="ProjectSelectorMenu"
                contextualMenuItemAs={(contextualMenuItemProps: IContextualMenuItemProps) => {
                    return (
                        <VectorSelectorVector item={activeProject.vectors.find(x => x.id === contextualMenuItemProps.item.key)} />
                    )
                }}
                items={activeProject.vectors.map((x: any) => {
                    return {
                        key: x.id,
                        text: x.name,
                        onClick: () => {
                            props.dispatch(setActiveVectorId(x.id));
                        }
                    }
                })}
                hidden={!showMenu}
                target={linkRef}
                onItemClick={() => {
                    setShowMenu(false);
                }}
                onDismiss={() => {
                    setShowMenu(false);
                }}
            />
            <ContextualMenu
                className="ProjectSelectorMenu"
                contextualMenuItemAs={(contextualMenuItemProps: IContextualMenuItemProps) => {
                    return (
                        <ProjectSelectorProject item={props.projects.find(x => x.id === contextualMenuItemProps.item.key)} />
                    )
                }}
                items={props.projects.map((x: any) => {
                    return {
                        key: x.id,
                        text: x.name,
                        onClick: () => {
                            props.dispatch(setActiveProjectId(x.id));
                            props.dispatch(setActiveVectorId(x.vectors[0].id));
                        }
                    }
                })}
                hidden={!showProjectMenu}
                target={projectLinkRef}
                onItemClick={() => {
                    setShowProjectMenu(false);
                }}
                onDismiss={() => {
                    setShowProjectMenu(false);
                }}
            />
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    projects: state.project.projects,
    activeProjectId: state.project.activeProjectId,
    activeVectorId: state.project.activeVectorId
});

export default connect(mapStateToProps)(VectorSelector);

