import React from "react";
import { connect } from "react-redux";
import { Label } from "src/components/Label/Label";
import "./StatusMessageWithIcon.scss";
import { IApplicationState } from "src/reducer";

interface IProps {
    text: string;
    icon: string;
    currentInstance?: any;
    noSpacing?: boolean;
}

const StatusMessageWithIcon = (props: IProps) => {
    const classNames = ["StatusMessageWithIcon"];

    if (props.noSpacing) {
        classNames.push("no-spacing");
    }

    return (
        <div className={classNames.join(" ")}>
            {/* <Visage2Icon
                size="big"
                icon={props.icon}
                type={"bold"}
                color="p-mid-grey-5"
                hexColor={props.currentInstance.color} /> */}
            <Label size="body-2" color="p-mid-grey-5">{props.text}</Label>
        </div >
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(StatusMessageWithIcon);
