import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import api from "src/api/SpintrApi";
import { SpintrTypes } from "src/typings";
import { connect } from "react-redux";
import { IApplicationState } from "src/reducer";
import { setActiveProjectId } from "src/actions/project";
import { setAdminMode } from "src/actions/ui";

interface IProps {
    currentInstanceId: number;
    dispatch?: any;
}

interface IRouteParams {
    instanceId: string;
    type: string;
    id: string;
}

const GotoView = (props: IProps) => {
    const { instanceId, type, id } = useParams<IRouteParams>();
    const history = useHistory();


    const instanceIdInt = parseInt(instanceId, 10);
    const typeInt = parseInt(type, 10);
    const idInt = parseInt(id, 10);

    const url =
        typeInt === SpintrTypes.ItemType.Customer
            ? "/radar"
            : typeInt === SpintrTypes.ItemType.Project
            ? "/radar"
            : typeInt === SpintrTypes.ItemType.User
            ? "/users/" + id
            : "";

    props.dispatch(setAdminMode(false));

    if (typeInt === SpintrTypes.ItemType.Project) {
        props.dispatch(setActiveProjectId(idInt));
    }

    if (instanceIdInt === props.currentInstanceId || !instanceIdInt) {
        history.push(url);
    } else {
        api.get("/api/v1/instances/imp/" + instanceId).then(() => {
            window.location.pathname = "/goto/" + type + "/" + id;
        });
    }

    return (
        <div className="AdminImpView">
            <Loader />
        </div>
    );
};

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentInstanceId: state.instance.currentInstance.id,
});

export default connect(mapStateToProps)(GotoView);
