import { Checkbox, DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "src/api/SpintrApi";
import { IApplicationState } from "src/reducer";
import { localize } from "src/utils/l10n";
import FormControl from "src/components/FormControl/FormControl";
import FormSection from "src/components/FormSection/FormSection";
import ErrorMessagebar from "src/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/components/Messagebars/SuccessMessagebar";

import { Label } from "src/components/Label/Label";
import { setPopupForm } from "src/actions/ui";
import onFormClose from "src/utils/onFormClose";
import Loader from "../Loader/Loader";
import PhoenixForm from "../PhoenixForm/PhoenixForm";

interface IProps {
    currentUser?: any;
    dispatch?: (Action) => void;
    popupFormState?: any;
}

interface IState {
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
    isLoading: boolean;
    item: any;
}

class SurveyReminderForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            item: {
                sendWithSms: true,
                sendWithEmail: true,
                id: props.popupFormState.id,
            },
            isLoading: false,
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false,
        };
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (
            saveError &&
            saveError.response &&
            saveError.response.data &&
            saveError.response.data.errorlist
        ) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    componentDidMount(): void {}

    dispatchSurvey = () => {
        this.setState(
            {
                enableFieldValidation: true,
                isLoading: true,
            },
            () => {
                const payload = {
                    ...this.state.item,
                };

                api.post("/api/v1/surveys/dispatch", payload)
                    .then(() => {
                        this.props.dispatch(
                            setPopupForm({
                                popupFormKey: "SurveyReminder",
                                isDisplayed: false,
                            })
                        );
                    })
                    .catch(this.handleCatch.bind(this));
            }
        );
    };

    onCancelClick() {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(
                setPopupForm({
                    popupFormKey: "Instance",
                    isDisplayed: false,
                })
            );
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="InstanceSettings fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false,
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.dispatchSurvey.bind(this)} data={this.state.item}>
                    <FormControl>
                        <Label size="body-2" color="p-mid-grey-3">
                            {localize("REMINDER_INFO")}
                        </Label>
                    </FormControl>
                    <FormSection>
                        <FormControl>
                            <div className="dispatch-info">
                                <Label size="body-2" color="p-mid-grey-3">
                                    {localize(
                                        this.props.popupFormState
                                            .remainingUsers === 1
                                            ? "HOW_SEND_REMINDER_ALT"
                                            : "HOW_SEND_REMINDER"
                                    ).replace(
                                        "{0}",
                                        this.props.popupFormState.remainingUsers.toString()
                                    )}
                                </Label>
                            </div>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                label={localize("SEND_AS_SMS")}
                                checked={!!this.state.item.sendWithSms}
                                onChange={(
                                    ev?: React.FormEvent<
                                        HTMLElement | HTMLInputElement
                                    >,
                                    checked?: boolean
                                ) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            sendWithSms: checked,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                label={localize("SEND_AS_EMAIL")}
                                checked={!!this.state.item.sendWithEmail}
                                onChange={(
                                    ev?: React.FormEvent<
                                        HTMLElement | HTMLInputElement
                                    >,
                                    checked?: boolean
                                ) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            sendWithEmail: checked,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </FormSection>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                        <DefaultButton
                            onClick={() => {
                                onFormClose(this.props.dispatch, () => {
                                    this.props.dispatch(setPopupForm({
                                        popupFormKey: "SurveyReminder",
                                        isDisplayed: false
                                    }));
                                });
                            }}
                            text={localize("Avbryt")} />
                        <PrimaryButton
                            disabled={
                                !this.state.item.sendWithEmail &&
                                !this.state.item.sendWithSms
                            }
                            type={"submit"}
                        >
                            {localize("SEND_REMINDER")}
                        </PrimaryButton>
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    popupFormState: state.ui.popupForms.find(
        (x) => x.popupFormKey === "SurveyReminder"
    ),
});

export default connect(mapStateToProps)(SurveyReminderForm);
