import { PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import api from "src/api/SpintrApi";
import Label from "src/components/Label/Label";
import Loader from "src/components/Loader/Loader";
import registerBackgroundUrl from "src/style/images/register.jpg";
import SplitAuth from "../SplitAuth";
import "./Register.scss";
import PhoenixForm from "src/components/PhoenixForm/PhoenixForm";

interface IProps {}

interface IState {
    isLoading: boolean;
    name: string;
    role: string;
    companyName: string;
    email: string;
    code?: string;
    userId?: string;
    error?: string;
    emailDomainTaken?: boolean;
}
export class Register extends Component<IProps, IState> {
    private recaptchaRef = React.createRef<ReCAPTCHA>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            name: "",
            role: "",
            companyName: "",
            email: "",
        };
    }

    private onChangeText = (ev, val) => {
        // @ts-ignore
        this.setState({ [ev.target.name]: val, emailDomainTaken: false });
    };

    private onSubmit = async (ev) => {
        const code = await this.recaptchaRef.current.executeAsync();
        const { name, role, companyName } = this.state;

        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/register", {
            username: this.state.email,
            name,
            role,
            companyName,
            code,
        })
            .then((response) => {
                this.setState({
                    code: response.data.code,
                    userId: response.data.userId,
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.response.data === "InstanceExists") {
                    this.setState({
                        emailDomainTaken: true,
                        isLoading: false,
                    });
                    return;
                }

                this.setState({
                    error: "Unexpected error occured",
                    isLoading: false,
                });
            });
    };

    private onFormSubmit = (ev) => {
        ev.preventDefault();
    };

    public renderForm = () => (
        <>
            {this.state.code && this.state.userId ? (
                <>
                    <Link to={`/confirmemail/${this.state.userId}/${this.state.code}`}>Click to confirm email</Link>
                </>
            ) : (
                <PhoenixForm onSubmit={this.onFormSubmit}>
                    <TextField label="Full Name" name="name" value={this.state.name} onChange={this.onChangeText} />
                    <div className="role-company">
                        <TextField label="Your Role" name="role" value={this.state.role} onChange={this.onChangeText} />
                        <TextField
                            label="Company Name"
                            name="companyName"
                            value={this.state.companyName}
                            onChange={this.onChangeText}
                        />
                    </div>
                    <TextField
                        label="Email Address"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeText}
                        errorMessage={
                            this.state.emailDomainTaken
                                ? "You can only register one account per domain and there is already one linked to this email domain"
                                : ""
                        }
                    />

                    <ReCAPTCHA
                        // @ts-ignore
                        ref={this.recaptchaRef}
                        sitekey="6LeL8yUfAAAAADGE1n1InxDM4tL15fbXcSsugVJC"
                        size="invisible"
                        badge="inline"
                        className="captcha-container"
                    />

                    <PrimaryButton text="Next" onClick={this.onSubmit} className="next-button" type="submit" />
                </PhoenixForm>
            )}
        </>
    );

    public render() {
        const { error } = this.state;

        return (
            <div className="register-view">
                <SplitAuth error={error} backgroundUrl={registerBackgroundUrl}>
                    <Label as="h2" size="h2" weight="bold" className="register-label">
                        Register
                    </Label>

                    <Label size="body-1" color="p-mid-grey-3" className="register-subtext-label">
                        Subtext
                    </Label>

                    {this.state.isLoading ? <Loader /> : this.renderForm()}
                </SplitAuth>
            </div>
        );
    }
}
