function debounce(fn: any, time: any) {
    let timeout: any;

    return function () {
        //@ts-ignore
        const functionCall = () => fn.apply(this);

        clearTimeout(timeout);

        timeout = setTimeout(functionCall, time);
    }
}

export default debounce;