import axios, { AxiosInstance } from "axios";

// IE11 cache fix
axios.defaults.headers.common["If-Modified-Since"] = 0;

const api: AxiosInstance = axios.create();

api.interceptors.response.use(request => {
    if (request.request &&
        request.request.responseURL &&
        request.request.responseURL.includes("login?ReturnUrl")) {
        window.location.href = "/login";

        throw new axios.Cancel();
    }

    if (!!request.headers["x-login-page"]) {
        window.location.href = "/login";

        throw new axios.Cancel();
    }

    return request;
}, error => {
    if (error.request.responseURL.includes("login?ReturnUrl")) {
        window.location.assign("/login");
    }

    return Promise.reject(error);
});

export default api;
