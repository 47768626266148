import React from 'react';
import { IFormStep } from '../ProjectForm/ProjectForm';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Label from '../Label/Label';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import './FormSteps.scss';
import Visage2Icon from '../Visage2Icon/Visage2Icon';

interface IProps {
    steps: IFormStep[];
    step: number;
    children: any;
    onCancelClick: any;
    onSaveClick: any;
    onStepChange: any;
}


const FormSteps = (props: IProps) => {
    const classNames = ["FormSteps"];

    classNames.push("FormSteps-active-step-" + props.step);

    const activeStep = props.steps[props.step];

    return (
        <div className={classNames.join(" ")}>
            <div className="FormSteps-header">
                {props.steps.map((step: IFormStep, index: number) => {
                    const isActive = props.step === index;

                    return (
                        <div key={index} className="FormSteps-header-step">
                            {index !== 0 && (
                                <Visage2Icon icon="arrow-right-3" size="small" color="p-light-grey-5" />
                            )}
                            <UnstyledButton onClick={() => {
                                props.onStepChange(index);
                            }}>
                                <Label size="body-2" color={isActive ? "p-link-color" : "p-mid-grey-1"}>{step.title}</Label>
                            </UnstyledButton>
                        </div>
                    )
                })}
            </div>
            {props.children}
            <div className="FormSteps-footer">
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                    <DefaultButton onClick={props.onCancelClick} text={activeStep.cancelButtonText} />
                    <PrimaryButton onClick={props.onSaveClick} text={activeStep.saveButtonText} />
                </Stack>
            </div>
        </div>
    )
}

export default FormSteps;