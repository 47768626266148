import React, { useEffect } from "react";
import "./AdminImpView.scss";
import { IApplicationState } from "src/reducer";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import api from "src/api/SpintrApi";

interface IProps {

}

const AdminImpView = () => {
    useEffect(() => {
        const instanceId = window.location.pathname.split("/").pop();

        api.get("/api/v1/instances/imp/" + instanceId).then(() => {
            window.location.pathname = "/";
        }).catch(() => {});
    }, []);

    return (
        <div className="AdminImpView">
            <Loader />
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentInstance: state.instance.currentInstance,
});

export default connect(mapStateToProps)(AdminImpView);
