import React from "react";
import { localize } from "src/utils/l10n";
import { Label } from "src/components/Label/Label";

const MandatoryText: React.FunctionComponent = () => (
    <Label className="obligatoryInfoText" as="p" size="body-2" color="p-mid-grey-3">
        {localize("ObligatoriskInfoText") + "."}
    </Label>
);

export default MandatoryText;
