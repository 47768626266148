import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { BasePicker, IBasePicker, ISuggestionItemProps, ITag, TagItem, TagPicker } from "@fluentui/react/lib/Pickers";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React from "react";
import SpintrUser from "src/components/SpintrUser/SpintrUser";
import * as Style from 'src/utils/style';
import { localize } from "src/utils/l10n";

interface ITagPlus extends IPersonaProps {
    isNewItem: boolean;
}

export type TokenizedInputChangeHandler = (items: any[]) => void;

export type TokenizedInputAutocompleteCallback = (text: string) => Promise<any[]>;

export class NormalPicker extends BasePicker<any, any> {
    public static defaultProps = {
        onRenderItem: (props) => {
            return (
                <TagItem {...props}>
                    {
                        <SpintrUser
                            name={props.item.name}
                            size={24}
                            subText={props.item.subText}
                            imageUrl={props.item.imageUrl}
                        />
                    }
                </TagItem>
            );
        },
    };
}

export class SpintrTagPicker extends BasePicker<any, any> {
    public static defaultProps = {
        onRenderItem: (props) => {
            return <TagItem {...props}>{props.item.name}</TagItem>;
        },
    };
}

export interface ITokenizedInputFabricProps {
    autocomplete?: TokenizedInputAutocompleteCallback;
    items: any[];
    onChange: any;
    placeholder?: string;
    hideImage: boolean;
    enableNewItems: boolean;
    itemLimit: number;
    showAllWhenEmpty?: boolean;
    id?: string;
}

export interface ITokenizedInputFabricState {
    text: string;
}

const SuggestedBigItem: (target, itemProps) => JSX.Element = (target: any, itemProps: ISuggestionItemProps<any>) => (
    <div style={{ padding: Style.getSpacing(2) }}>
        <SpintrUser
            imageUrl={target && target.imageUrl}
            name={target.isNewItem ? (target.name + " (" + localize("LaggTillNyMalgrupp") + ")") : target.name}
            subText={target.subText} />
    </div>
);

const rootClass = mergeStyles({
    //maxWidth: 500,
});

export interface Props extends ITokenizedInputFabricProps {
    disabled?: boolean;
    initialInputText?: string;
}

class TokenizedInputFabric extends React.Component<Props, ITokenizedInputFabricState> {
    private picker = React.createRef<IBasePicker<ITag>>();

    constructor(props: ITokenizedInputFabricProps) {
        super(props);

        this.state = {
            text: "",
        };
    }
    private _getTextFromItem(props: any): any {
        return props.name;
    }

    private listContainsDocument(tag: ITag, tagList?: ITag[]) {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.filter((compareTag) => compareTag.key === tag.key).length > 0;
    }

    private _onFilterChanged = (text, tagList) => {
        const trimmedText = (text || "").trim();
        if (!this.props.autocomplete || !trimmedText) {
            return [];
        }

        return this.props.autocomplete(trimmedText).then(
            (items) => {
                let newItems = items
                    .map((i) => {
                        return { key: i.key, name: i.name, isNewItem: false, ...i };
                    })
                    .filter((tag) => !this.listContainsDocument(tag, tagList));

                if (!this.props.enableNewItems ||
                    newItems.find((i) => i.key === text) ||
                    newItems.find((i) => i.name.toLowerCase() === text.toLowerCase())) {
                    return newItems;
                } else {
                    return [{ key: text, name: text, isNewItem: true }].concat(newItems);
                }
            },
            (reason: any) => {
                console.log(reason);
            }
        );
    };

    public render(): JSX.Element {
        let items = this.props.items;

        if (this.props.showAllWhenEmpty && (!items || items?.length < 1)) {
            items = [{ key: "alla-0", name: localize("Alla") }];
        } else if (this.props.showAllWhenEmpty && this.props.items?.length > 1) {
            items = items.filter((i) => {
                return i.name !== localize("Alla");
            });
        }

        return (
            <div className={rootClass}>
                {this.props.hideImage ? (
                    <TagPicker
                        id={this.props.id}
                        disabled={this.props.disabled}
                        removeButtonAriaLabel={localize("TaBort")}
                        //@ts-ignore
                        onResolveSuggestions={this._onFilterChanged}
                        onChange={this.props.onChange}
                        getTextFromItem={this._getTextFromItem}
                        //@ts-ignore
                        selectedItems={this.props.items}
                        inputProps={{
                            "aria-label": localize("SokOchLaggTill"),
                            placeholder: this.props.placeholder || (localize("SokOchLaggTill") + "..."),
                            id: this.props.id
                        }}
                    />
                ) : (
                    <NormalPicker
                        id={this.props.id}
                        disabled={this.props.disabled}
                        removeButtonAriaLabel="Remove"
                        itemLimit={this.props.itemLimit}
                        onRenderSuggestionsItem={SuggestedBigItem as any}
                        //@ts-ignore
                        onResolveSuggestions={this._onFilterChanged}
                        onChange={(data) => {
                            this.props.onChange(data.filter((i) => i.key !== "alla-0"));
                        }}
                        getTextFromItem={this._getTextFromItem}
                        selectedItems={items}
                        onItemSelected={(item: ITagPlus): ITagPlus | null => {
                            if (item && item.isNewItem) {
                                //alert("New item added, make any necessary backend calls.");
                            }
                            return item;
                        }}
                        onDismiss={() => {
                            return false; // On click outside, don't select active item
                        }}
                        pickerSuggestionsProps={
                            {
                                //  suggestionsHeaderText: "Suggested Groups",
                                //    noResultsFoundText: "No groups Found",
                            }
                        }
                        inputProps={{
                            placeholder: this.props.placeholder || (localize("SokOchLaggTill") + "..."),
                            defaultVisibleValue: this.props.initialInputText,
                            "aria-label": "Group Picker",
                            id: this.props.id
                        }}
                    />
                )}
            </div>
        );
    }
}

export default TokenizedInputFabric;
