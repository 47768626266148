const vars = {
    baseUnit: 4
}

export const getHexFromSpintrColor = (color?: spintrColors) => {
    switch (color) {
        case "p-black": return "#000";
        case "p-white": return "#FFF";
        case "p-dark-grey-1": return "#4e4f6c";
        case "p-dark-grey-2": return "#363853";
        case "p-dark-grey-3": return "#091B3D";
        case "p-mid-grey-1": return "#727E94";
        case "p-mid-grey-2": return "#707070";
        case "p-mid-grey-3": return "#566176";
        case "p-mid-grey-4": return "#6D7588";
        case "p-mid-grey-5": return "#4E4F6C";
        case "p-light-grey-1": return "#F4F4F5";
        case "p-light-grey-2": return "#d5d5d5";
        case "p-light-grey-3": return "#EAEAEA";
        case "p-light-grey-4": return "#E9E9E9";
        case "p-light-grey-5": return "#d0d5dd";
        case "p-light-grey-6": return "#A7A7B5";
        case "p-light-grey-7": return "#f5f5f5";
        case "p-light-grey-8": return "#9da4b1";
        case "p-light-grey-9": return "#ececec";
        case "p-light-grey-10": return "#909090";
        case "p-light-grey-11": return "#F6F6F6";
        case "p-light-grey-12": return "#f1f2ff";
        case "p-light-grey-13": return "#EAECF0";
        case "p-link-color": return "#787cdd";
        case "p-red-1": return "#fc5371";
        case "p-red-2": return "#E45864";
        case "p-red-3": return "#ff1429";
        case "p-red-4": return "#ED694E";
        case "p-red-5": return "#ff5555";
        case "p-red-6": return "#ea3c00";
        case "p-green-1": return "#00A578";
        case "p-green-2": return "#4B9F47";
        case "p-green-3": return "#eaf4ef";
        case "p-yellow-1": return "#ADA000";
        case "p-yellow-2": return "#F7D48B";
        case "p-yellow-3": return "#F1AE15";
        case "p-yellow-4": return "#FCB147";
        case "p-pink-1": return "#FBB5D4";
        case "p-pink-2": return "#fceef5";
        case "p-blue-1": return "#2772F0";
        case "p-blue-2": return "#C9CBF1";
        case "p-blue-3": return "#f1f2fc";
        case "p-border-color": return "#ECF0F3";
        case "p-outline-color": return "#488dc8";
        default:
            return "#091B3D";
    }
};

export const getNumberFromIconSize = (iconSize: iconSize) => {
    switch (iconSize) {
        case "extra-small":
            return 12;
        case "small":
            return 16;
        case "medium":
            return 20;
        case "large":
            return 24;
        case "extra-large":
            return 28;
        case "huge":
            return 40;
        default:
            return 20;
    }
}

export const circleXSmall = 24;
export const circleSmall = 32;
export const circleMedium = 36;
export const circleLarge = 40;
export const circleXLarge = 54;

export const listActionMenuWidth = 24;

export const getSpacing = (size: number) => {
    return vars.baseUnit * size;
};

export const getSpacingStr = (size: number) => {
    return getSpacing(size) + 'px';
};
