import React, { Component } from "react";

import "./Header.scss";

interface IProps {
    children?: any;
}

class Header extends Component<IProps> {
    render() {
        return (
            <div className="Header">
                {this.props.children}
            </div>
        )
    }
}

export default Header;
