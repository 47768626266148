import { Callout } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";
import "./Notifications.scss";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import NotificationItem from "./NotificationItem";
import { AxiosResponse } from "axios";
import api from "src/api/SpintrApi";
import getNotificationText from "src/utils/notificationText";

interface IState {
    showCallout: boolean;
    items: any[];
    isLoading: boolean;
    hasMore: boolean;
}

const Notifications = () => {
    const [state, setState] = useState<IState>({
        showCallout: false,
        items: [],
        isLoading: false,
        hasMore: true
    });

    const fetch = useCallback(() => {
        if (state.isLoading || !state.hasMore) {
            return;
        }

        api.get("/api/v1/notifications", {
            params: {
                skip: state.items.length,
                take: 10
            },
        }).then((response: AxiosResponse) => {
            const notifications = response.data.map((notification) => ({
                ...notification,
                text: getNotificationText(notification),
            }));

            setState(s => ({
                ...s,
                hasMore: response.data.length === 10,
                items: [
                    ...s.items,
                    ...notifications,
                ]
            }));
        }).catch(() => {});
    }, [state.items, state.isLoading]);

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className={"Notifications" + (state.showCallout ? " visible" : "")}>
            <UnstyledButton
                onClick={() => {
                    setState(s => ({
                        ...s,
                        showCallout: !s.showCallout
                    }))
                }}
                id="Notifications-button"
                className="Notifications-button">
                <Visage2Icon icon="notification" />
                {!!state.items.find(x => x.unread) && <div className="button-unread-dot"></div>}
            </UnstyledButton>
            {state.showCallout && (
                <Callout
                    className="Phoenix-callout"
                    isBeakVisible={false}
                    role="dialog"
                    gapSpace={0}
                    target={"#Notifications-button"}
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            showCallout: false
                        }))
                    }}
                    setInitialFocus
                >
                    <div className="Phoenix-callout-header">
                        <Label weight="medium" fontType="display">{localize("NOTIFICATIONS")}</Label>
                        <UnstyledButton
                            onClick={() => {
                                api.put("/api/v1/notifications/mark-all-as-read").then(() => {}).catch(() => {});

                                setState(s => ({
                                    ...s,
                                    items: s.items.map((x: any) => {
                                        return {
                                            ...x,
                                            unread: false
                                        }
                                    })
                                }));
                            }}
                            className="mark-all-as-unread">
                            <Label size="body-3" fontType="display">{localize("MARK_ALL_AS_READ")}</Label>
                        </UnstyledButton>
                    </div>
                    <div className="Notification-list">
                        {state.items.map((item: any, index: number) => {
                            return (
                                <NotificationItem
                                    key={index}
                                    onMarkAsRead={() => {
                                        api.put("/api/v1/notifications/" + item.id + "/read");

                                        setState(s => ({
                                            ...s,
                                            items: s.items.map((x: any) => {
                                                if (x.id === item.id) {
                                                    return {
                                                        ...x,
                                                        unread: false
                                                    }
                                                }

                                                return x;
                                            })
                                        }));
                                    }}
                                    onDelete={() => {
                                        api.delete("/api/v1/notifications/" + item.id);

                                        setState(s => ({
                                            ...s,
                                            items: [...s.items].filter(x => x.id !== item.id)
                                        }));
                                    }}
                                    item={item} />
                            )
                        })}
                    </div>
                    {
                        state.hasMore && (
                            <UnstyledButton className="load-more">
                                <Label color="p-link-color" fontType="display" size="body-3">{localize("LaddaInFlera")}</Label>
                            </UnstyledButton>
                        )
                    }
                </Callout>
            )}
        </div>
    )
}

export default Notifications;