export default function getLanguages(includeZero?: boolean) {
    let languages = [
        {
            key: "1",
            text: "Svenska",
        },
        {
            key: "2",
            text: "Norsk",
        },
        {
            key: "3",
            text: "English",
        },
    ];

    if (includeZero) {
        languages.unshift({
            key: "0",
            text: "Standard",
        });
    }

    return languages;
}
