import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';
import UnstyledButton from 'src/components/UnstyledButton/UnstyledButton';

import './ProjectsList.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import Page from '../Page/Page';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { SpintrTypes } from 'src/typings';
import FormPopup from '../FormPopup/FormPopup';
import { IPopupFormState } from 'src/reducers/ui';
import { setAdminMode, setPopupForm } from 'src/actions/ui';
import ProjectForm from '../ProjectForm/ProjectForm';
import { setActiveProjectId } from 'src/actions/project';

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    popupFormState?: IPopupFormState;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
}

class ProjectsList extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false
        };
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
                {this.renderFormPopup()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey="ProjectsList" placeholder={localize("SEARCH_PLACEHOLDER_PROJECTS")} />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: [{
                                key: "showDeleted",
                                text: this.state.includeDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        includeDeleted: !this.state.includeDeleted
                                    }, () => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    });
                                },
                            }]
                        }}
                    />
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Project",
                                id: 0,
                                title: localize("START_NEW_PROJECT"),
                                isDisplayed: true,
                                hideHeader: true,
                                chooseInstance: true,
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("START_NEW_PROJECT")}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="Project"
                onClose={() => {
                    //@ts-ignore
                    this.listRef.current.reFetch();
                }}>
                    <ProjectForm
                        id={this.props.popupFormState.id}
                        instanceId={this.props.popupFormState.instanceId}
                        chooseInstance={this.props.popupFormState.chooseInstance}
                    />
            </FormPopup>
        )
    }

    renderContent() {
        return (
            <div className="ProjectsList">
                <PageHeader
                    title={localize("PROJECTS")}
                    hits={this.state.totalCount} />
                <SpintrList
                    ref={this.listRef}
                    searchKey="ProjectsList"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/projects", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        items: items,
                                        totalCount: response.data.totalCount
                                    });
                                }).catch(() => { });
                        });
                    }}
                    data={{
                        data: this.state.items,
                        totalCount: this.state.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                return (
                                    <UnstyledButton
                                        className={"linkFGColor"}
                                        onClick={() => {
                                            if (this.props.currentInstance.id === item.instanceId) {
                                                this.props.dispatch(setActiveProjectId(item.id));
                                                this.props.dispatch(setAdminMode(false));
                                                this.props.history.push("/");
                                                return;
                                            }

                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("SWITCH_INSTANCE"),
                                                message: localize("SWITCH_INSTANCE_MSG").replace("{0}", item.customerName),
                                                onConfirm: () => {
                                                    this.props.dispatch(setActiveProjectId(item.id));
                                                    this.props.history.push("/imp/" + item.instanceId);
                                                }
                                            }));
                                        }}>
                                        <span style={{
                                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                        }}>{item.name}</span>
                                    </UnstyledButton>
                                );
                            },
                        },
                        {
                            name: localize("CUSTOMER"),
                            minWidth: 200,
                            fieldName: "customerName",
                        },
                        {
                            name: localize("LAST_SURVEY"),
                            fieldName: "latestSurveyDate",
                            minWidth: 200,
                            onRender: (item) => {
                                if (!item.latestSurveyDate) {
                                    return null;
                                }

                                return (
                                    <span>
                                        {moment(item.latestSurveyDate).format("lll")}
                                    </span>
                                )
                            }
                        },
                        {
                            name: localize("SURVEYS"),
                            fieldName: "surveyCount",
                        },
                        {
                            name: localize("Status"),
                            fieldName: "deleted",
                            minWidth: 200,
                            onRender: (item) => {
                                if (item.instanceDeleted) {
                                    return (
                                        <span>
                                            {localize("INSTANCE_DELETED")}
                                        </span>
                                    )
                                }

                                return (
                                    <span>
                                        {localize("PROJECT_STATUS_" + item.status)}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [{
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.props.dispatch(setPopupForm({
                                                            popupFormKey: "Project",
                                                            id: item.id,
                                                            instanceId: item.instanceId,
                                                            isDisplayed: true,
                                                            hideHeader: true,
                                                            chooseInstance: false,
                                                        }));
                                                    },
                                                }]
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    isDescending={false}
                    orderByColumn="name"
                ></SpintrList>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Project")
});

export default withRouter(connect(mapStateToProps)(ProjectsList));