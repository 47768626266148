import React, { Component } from "react";

import "./Page.scss";
import Header from "../Header/Header";

interface IProps {
    renderHeader?: any;
    children?: any;
    noContentStyle?: boolean;
    fullScreen?: boolean;
    pageClassName?: string;
}

class Page extends Component<IProps> {
    render() {
        const classes = ["Page"];

        if (this.props.fullScreen) {
            classes.push("fullScreen");
        }

        if (this.props.pageClassName) {
            classes.push(this.props.pageClassName);
        }

        return (
            <div className={classes.join(" ")}>
                {!!this.props.renderHeader && (
                    <Header>
                        {this.props.renderHeader()}
                    </Header>
                )}
                {this.props.noContentStyle && this.props.children}
                {!this.props.noContentStyle && (
                    <div className="Page-content">
                        {this.props.children}
                    </div>
                )}
            </div>
        )
    }
}

export default Page;
