import { combineReducers } from "redux";
import instanceReducer, { IInstanceState } from "./reducers/instance";
import popupReducer, { IPopupState } from "./reducers/popup";
import userReducer, { IUserState } from "./reducers/user";
import uiReducer, { IUIState } from "./reducers/ui";
import projectReducer, { IProjectState } from "./reducers/project";

export interface IApplicationState {
    instance: IInstanceState,
    user: IUserState,
    popup: IPopupState,
    ui: IUIState,
    project: IProjectState
}

const rootReducer = combineReducers({
    instance: instanceReducer,
    user: userReducer,
    popup: popupReducer,
    ui: uiReducer,
    project: projectReducer
});

export default rootReducer;
