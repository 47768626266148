import { DefaultButton, Dropdown, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentInstance } from 'src/actions/instance';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { validateRequiredTextField } from 'src/utils';
import { localize } from 'src/utils/l10n';
import FormControl from 'src/components/FormControl/FormControl';
import FormSection from 'src/components/FormSection/FormSection';
import Loader from 'src/components/Loader/Loader';
import ErrorMessagebar from 'src/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/components/Messagebars/SuccessMessagebar';

import './InstanceForm.scss';
import getLanguages from 'src/utils/getLanguages';
import { setPopupForm } from 'src/actions/ui';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';
import FormTokenizedObjectInput from '../FormTokenizedObjectInput/FormTokenizedObjectInput';
import { SpintrTypes } from 'src/typings';

interface IProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    popupFormState?: any;
}

interface IState {
    item: any;
    isLoading: boolean;
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
}

class InstanceForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            item: {
                id: 0,
                language: 1
            },
            isLoading: props.popupFormState.id !== 0,
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false
        };
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    componentDidMount(): void {
        if (this.props.popupFormState.id !== 0) {
            api.get("/api/v1/instances/" + this.props.popupFormState.id).then((response: AxiosResponse) => {
                this.setState({
                    item: response.data,
                    isLoading: false
                })
            }).catch(() => {});
        }
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            const payload = {
                ...this.state.item,
                theme: {
                    ...(this.state.item.theme || {}),
                    ...this.state.item
                }
            };

            let saveError: string[] = [];

            if (!payload.name) {
                saveError.push("DuMasteFyllaIEttNamn");
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/instances", payload).then(() => {
                this.props.dispatch(fetchCurrentInstance());
                this.props.dispatch(setPopupForm({
                    popupFormKey: "Instance",
                    isDisplayed: false
                }));
            }).catch(this.handleCatch.bind(this));
        });
    }

    onCancelClick() {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "Instance",
                isDisplayed: false
            }));
        });
    }

    render() {
        const languages = getLanguages();

        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="InstanceSettings fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.onSaveClick.bind(this)} data={this.state.item}>
                    <FormSection>
                        <FormControl>
                            <TextField
                                value={this.state.item.name}
                                className="textField"
                                label={localize("COMPANY_NAME")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            name: newValue
                                        }
                                    });
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.customerNumber}
                                className="textField"
                                label={localize("CUSTOMER_NUMBER")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            customerNumber: newValue
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Dropdown
                                label={localize("Sprak")}
                                selectedKey={this.state.item.language.toString()}
                                required
                                onChange={(_e, v) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            language: Number(v.key)
                                        }
                                    });
                                }}
                                options={languages}
                                styles={{ dropdown: { width: 250 } }}
                            />
                        </FormControl>
                        <div className="form-row">
                            <FormControl>
                                <TextField
                                    value={this.state.item.industry}
                                    className="textField"
                                    label={localize("INDUSTRY")}
                                    resizable={false}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                industry: newValue
                                            }
                                        });
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    value={this.state.item.year}
                                    className="textField"
                                    label={localize("FOUNDED_YEAR")}
                                    resizable={false}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                year: newValue
                                            }
                                        });
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="form-row">
                            <FormControl>
                                <TextField
                                    value={this.state.item.numberOfEmployees}
                                    className="textField"
                                    label={localize("NUMBER_OF_EMPLOYEES")}
                                    resizable={false}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                numberOfEmployees: newValue
                                            }
                                        });
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    value={this.state.item.numberOfBusinessUnits}
                                    className="textField"
                                    label={localize("NUMBER_OF_BUSINESS_UNITS_SHORT")}
                                    resizable={false}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                numberOfBusinessUnits: newValue
                                            }
                                        });
                                    }}
                                />
                            </FormControl>
                        </div>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.state.item.customerRepresentatives}
                                types={[SpintrTypes.UberType.User]}
                                label={localize("CUSTOMER_REPRESENTATIVES")}
                                url={"/api/v1/users"}
                                customParams={{
                                    onlyAdministrators: true
                                }}
                                onChange={(items) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            customerRepresentatives: items.map(x => {
                                                return {
                                                    ...x,
                                                    key: !!x.key ? x.key : x.id,
                                                    id: !!x.id ? x.id : x.key
                                                }
                                            })
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Toggle
                                label="Pro"
                                checked={this.state.item.pro}
                                onChange={(ev, val) => {
                                    this.setState((prevState) => ({
                                        item: {
                                            ...prevState.item,
                                            pro: val
                                        }
                                    }));
                                }}
                            />
                        </FormControl>
                    </FormSection>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                        <PrimaryButton type={"submit"} text={localize("Spara")} />
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Instance")
});

export default connect(mapStateToProps)(InstanceForm);
