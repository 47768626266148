import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { SurveyView } from './components/SurveyView/SurveyView';
import { Register } from './components/Auth/Register/Register';
import { ConfirmEmail } from './components/Auth/ConfirmEmail/ConfirmEmail';
import Login from './components/Auth/Login/Login';
import { ForgotPassword } from './components/Auth/ForgotPassword/ForgotPassword';
import PhoenixApp from './components/PhoenixApp/PhoenixApp';
import SpintrCustomStyleProvider from './components/SpintrCustomStyleProvider/SpintrCustomStyleProvider';
import './App.scss';
import './style/Spintr.scss';
import { initializeFocusRects } from '@fluentui/react';

export default class App extends Component {
    componentDidMount() {
        initializeFocusRects(window);
    }

    render() {
        return (
            <SpintrCustomStyleProvider>
                <Switch>
                    <Route path='/survey/u/:id' component={SurveyView} />
                    <Route path='/survey/s/:id' component={SurveyView} />
                    <Route path='/register' component={Register} />
                    <Route path='/confirmemail/:userId/:code' component={ConfirmEmail} />
                    <Route path='/forgotpassword/:userId?/:code?' component={ForgotPassword} />
                    <Route path='/login' component={Login} />
                    <Route path='/*' component={PhoenixApp} />
                </Switch>
            </SpintrCustomStyleProvider>
        );
    }
}
