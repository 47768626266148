import { ITheme, loadTheme } from "@fluentui/react";
import { createContext } from "react";
import * as Style from '../utils/style';

import Color from "./colors/Color";

export const produceTheme = (primaryColor: string): ITheme => {
    const font: string = '"EloquiaDisplay Regular", sans-serif';
    const color = Color.fromHex(primaryColor);

    return loadTheme({
        defaultFontStyle: {
            fontFamily: font
        },

        fonts: {
            large: { fontFamily: font },
            medium: { fontFamily: font },
            mediumPlus: { fontFamily: font },
            mega: { fontFamily: font },
            small: { fontFamily: font },
            smallPlus: { fontFamily: font },
            superLarge: { fontFamily: font },
            tiny: { fontFamily: font },
            xLarge: { fontFamily: font },
            xLargePlus: { fontFamily: font },
            xSmall: { fontFamily: font },
            xxLarge: { fontFamily: font },
            xxLargePlus: { fontFamily: font },
        },

        palette: {
            themeDark: color.darken(30).toString("hex"),
            themeDarkAlt: color.darken(15).toString("hex"),
            themeDarker: color.darken(50).toString("hex"),
            themeLight: Style.getHexFromSpintrColor("p-light-grey-2"),
            themeLighter: color.lighten(10).toString("hex"),
            themeLighterAlt: color.lighten(12.5).toString("hex"),
            themePrimary: color.toString("hex"),
            themeSecondary: color.lighten(2.5).toString("hex"),
            themeTertiary: color.lighten(5).toString("hex"),
        }
    });
};

export const defaultTheme: ITheme = produceTheme("#e6007e");

const ThemeContext = createContext(defaultTheme);

export default ThemeContext;