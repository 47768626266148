import { PrimaryButton, TextField, Toggle } from "@fluentui/react";
import React, { Component } from "react";
import { Link, RouterProps, withRouter } from "react-router-dom";
import api from "src/api/SpintrApi";
import Label from "src/components/Label/Label";
import loginBackgroundUrl from "src/style/images/masma75127 copy_thin-1.jpg";
import Loader from "src/components/Loader/Loader";
import "./Login.scss";
import classNames from "classnames";
import { editInstance } from "src/actions/instance";
import { IApplicationState } from "src/reducer";
import { connect } from "react-redux";
import SplitAuth from "../SplitAuth";
import PhoenixForm from "src/components/PhoenixForm/PhoenixForm";

interface IProps extends RouterProps {
    dispatch?: (Action) => void;
}

interface IState {
    username: string;
    password: string;
    code: string;
    twoFactor: boolean;
    rememberMe: boolean;
    isLoading: boolean;
    error?: string;
    instanceName?: string;
}
class Login extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            code: "",
            twoFactor: false,
            rememberMe: true,
            isLoading: false,
        };
    }

    private onBlurUsername = async (ev) => {
        const username = ev.target.value;

        if (!username.includes("@")) {
            return;
        }

        let data;
        try {
            data = (
                await api.get("/api/v1/instances/domain", {
                    params: { username },
                })
            ).data;
        } catch (err) {
            return;
        }

        this.setState({ instanceName: data.name });
        this.props.dispatch(editInstance(data.theme));

        console.log(data);
    };

    private onChangeUsername = (ev, val) => {
        this.setState({ username: val });
    };

    private onChangePassword = (ev, val) => {
        this.setState({ password: val });
    };

    private onChangeCode = (ev, val) => {
        this.setState({ code: val });
    };

    private toggleRememberMe = (ev, val) => {
        this.setState((prevState) => ({
            rememberMe: !prevState.rememberMe,
        }));
    };

    private onSubmit = (ev) => {
        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/login", {
            username: this.state.username,
            password: this.state.password,
            rememberMe: this.state.rememberMe,
            code: this.state.code,
        })
            .then((response) => {
                if (response.data === "2f") {
                    this.setState({
                        isLoading: false,
                        twoFactor: true,
                    });
                    return;
                }

                this.props.history.push("/");
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: this.state.twoFactor
                        ? "The authentication code is incorrect"
                        : "The username or password is incorrect",
                });
            });
    };

    private renderForm = () => (
        <>
            <PhoenixForm>
                {this.state.twoFactor ? (
                    <>
                        <TextField label="Authentication code" value={this.state.code} onChange={this.onChangeCode} />
                    </>
                ) : (
                    <>
                        <TextField
                            label="Email Address"
                            value={this.state.username}
                            onChange={this.onChangeUsername}
                            onBlur={this.onBlurUsername}
                            className="email-input"
                        />

                        <TextField
                            label="Password"
                            onChange={this.onChangePassword}
                            className="password-input"
                            type="password"
                            canRevealPassword
                        />

                        <div className="remember-forgot">
                            <Toggle
                                label="Remember Me"
                                checked={this.state.rememberMe}
                                onChange={this.toggleRememberMe}
                                inlineLabel
                            />

                            <Link to="/forgotpassword">
                                <Label className="forgot-link" size="body-2" weight="medium">
                                    Forgot Password?
                                </Label>
                            </Link>
                        </div>
                    </>
                )}
                <PrimaryButton text="Sign in" onClick={this.onSubmit} className="sign-in-button" type="submit" />
            </PhoenixForm>
            {!this.state.twoFactor && (
                <Link to="/register" className="register-link">
                    <Label>Register</Label>
                </Link>
            )}
        </>
    );

    public render() {
        const { isLoading, error, twoFactor } = this.state;

        return (
            <div
                className={classNames("login-view", {
                    "two-factor": twoFactor,
                })}
            >
                <SplitAuth error={error} backgroundUrl={loginBackgroundUrl}>
                    <Label as="h2" size="h2" weight="bold" className="sign-in-label">
                        {twoFactor ? "Only one step left" : `Sign In to ${this.state.instanceName || "Phoenix"}`}
                    </Label>

                    {twoFactor && (
                        <Label size="body-1" color="p-mid-grey-3" className="sign-in-sublabel">
                            Enter the six-digit code we sent to your phone to log in
                        </Label>
                    )}

                    {isLoading ? <Loader /> : this.renderForm()}
                </SplitAuth>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
});

export default withRouter(connect(mapStateToProps)(Login));
