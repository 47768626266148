import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { localize } from 'src/utils/l10n';
import { Label } from 'src/components/Label/Label';
import * as Style from 'src/utils/style';
import { useDispatch } from 'react-redux';
import './Sidebar.scss';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import { ISidebarItem } from './Sidebar';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import { setPopupForm } from 'src/actions/ui';

interface IProps {
    item: ISidebarItem;
    instanceColor: string;
}

interface ISidebarItemLinkProps {
    item: ISidebarItem;
    onExpand: any;
    children: any;
}

const SidebarItemLink = (props: ISidebarItemLinkProps) => {
    const dispatch = useDispatch();
    const isActive = window.location.pathname === props.item.url;

    if (props.item.popupFormKey) {
        return (
            <UnstyledButton
                onClick={() => {
                    dispatch(setPopupForm({
                        popupFormKey: "InstanceSidebar",
                        id: 0,
                        title: localize("Installningar"),
                        isDisplayed: true
                    }));
                }}
                className={"SidebarItem" + (isActive ? " isActive" : "")}>
                {props.children}
            </UnstyledButton>
        )
    } else if (props.item.children) {
        return (
            <UnstyledButton
                onClick={props.onExpand}
                className={"SidebarItem" + (isActive ? " isActive" : "")}>
                {props.children}
            </UnstyledButton>
        )
    } else {
        return (
            <Link
                to={props.item.url}
                className={"SidebarItem" + (isActive ? " isActive" : "")}>
                {props.children}
            </Link>
        )
    }
}

const SidebarItem = (props: IProps) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const isActive = window.location.pathname === props.item.url;

    return (
        <div>
            {props.item.hasBorderTop && <div className="SidebarItem-border" />}
            <SidebarItemLink item={props.item} onExpand={() => {
                setIsExpanded(!isExpanded);
            }}>
                <Visage2Icon
                    className="SidebarItemIcon"
                    icon={props.item.icon}
                    type={isActive ? "bold" : "outline"}
                    hexColor={isActive ? props.instanceColor : Style.getHexFromSpintrColor("p-mid-grey-1")} />
                <Label
                    className={isActive ? "primaryFGColor" : ""}
                    weight={"medium"}
                    size="h6"
                    color="p-mid-grey-1"
                    fontType="display">{props.item.name}</Label>
                {props.item.children && (
                    <Visage2Icon icon={isExpanded ? "arrow-up-2" : "arrow-down-1"} size="small" color="p-mid-grey-1" />
                )}
            </SidebarItemLink>
            {props.item.children && isExpanded && (
                <div>
                    {props.item.children.map((child: ISidebarItem, index: number) => {
                        return (
                            <SidebarItem key={index} item={child} instanceColor={props.instanceColor} />
                        )
                    })}
                </div>
            )}
        </div>
    )
}


export default SidebarItem;

