import React, { Component } from "react";
import { localize } from "src/utils/l10n";
import { Radar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ChartOptions,
} from "chart.js";
import { DefaultButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import Label from "src/components/Label/Label";
import "./RadarView.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import api from "src/api/SpintrApi";
import moment from "moment";
import Loader from "src/components/Loader/Loader";
import { debounce } from "src/utils";
import { connect } from "react-redux";
import { IApplicationState } from "src/reducer";
import StatusMessageWithIcon from "../StatusMessageWithIcon/StatusMessageWithIcon";
import PopupHeader from "../PopupHeader/PopupHeader";
import SpintrLoader from "src/components/Loader/Loader";
import Page from "../Page/Page";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import ProjectSelector from "../ProjectSelector/ProjectSelector";
import VectorChartLabel from "./VectorChartLabel";
import { IVectorChartPosition } from "./RadarViewInner";
import CenteredContent from "../CenteredContent/CenteredContent";
import FormPopup from "../FormPopup/FormPopup";
import { IPopupFormState } from "src/reducers/ui";
import { setPopupForm } from "src/actions/ui";
import ProjectForm from "../ProjectForm/ProjectForm";
import RadarSidebarItem from "./RadarSidebarItem";
import Notifications from "../Notifications/Notifications";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export type TFunc = () => void
const RadarChartOptions = (onLoad: TFunc): ChartOptions<'radar'> => ({
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      r: {
        min: 0,
        max: 100,
        ticks: {
          count: 6,
          display: true,
          color: '#000000',
          font: {
            size: 12,
            family: 'EloquiaDisplay Medium',
          },
        },
        pointLabels: {
          display: true,
          color: 'transparent',
          font: {
            size: 0,
            family: 'EloquiaDisplay Medium',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (item) => {
            return item.dataset.label + ": " + item.formattedValue + "%";
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 6,
      },
    },
    animation: {
      onComplete: onLoad,
    },
  })

interface IProps extends RouteComponentProps {
    hasCustomHeadlineFont: boolean;
    dispatch?: any;
    projects?: any[];
    activeProjectId?: number;
    popupFormState?: IPopupFormState;
}

interface IState {
    chartHasRendered: boolean;
    dataSets: any[];
    vectors: any[];
    isLoading: boolean;
    displayExportPopup: boolean;
    exportFileUrl: string;
    updateIndex: number;
    data?: any;
}

class RadarView extends Component<IProps, IState> {
    private ref = React.createRef<ChartJS>();
    private containerRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            chartHasRendered: false,
            isLoading: true,
            vectors: [],
            dataSets: [],
            displayExportPopup: false,
            exportFileUrl: "",
            updateIndex: 0
        };
    }

    private stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
    };

    public componentDidMount() {
        this.fetchSurveyResults();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.activeProjectId !== this.props.activeProjectId) {
            this.setState({
                isLoading: true,
                chartHasRendered: false,
            }, () => {
                this.fetchSurveyResults();
            });
        }
    }

    private fetchSurveyResults = async () => {
        if (!this.props.activeProjectId) {
            this.setState({
                isLoading: false
            });

            return;
        }

        const data = (await api.get("/api/v1/surveys/radar/" + this.props.activeProjectId)).data;

        const allVectors = data.flatMap((survey) => survey.vectors.map((v: any) => {
            return {
                ...v,
                name: v.name
            }
        }));

        // get distinct values from allVectors based on id
        const distinctVectors = allVectors.reduce((acc, curr) => {
            if (!acc.find((vector) => vector.id === curr.id)) {
                acc.push(curr);
            }
            return acc;
        }, []);

        this.setState({
            data,
            dataSets: data.map((survey, i) => ({
                label: moment(survey.createdDate).format("ll"),
                backgroundColor: this.stringToColor(survey.externalId) + "46",
                borderColor: this.stringToColor(survey.externalId) + "80",
                data: distinctVectors.map(
                    // fixes surveys with different number of vectors
                    (vector) => survey.vectors.find((surveyVector) => surveyVector.id === vector.id)?.value || 0
                ),
                pointBackgroundColor: "transparent",
                pointBorderColor: "transparent",
                borderWidth: 0
            })),
            vectors: distinctVectors,
            isLoading: false,
        });
    };

    renderSidebar = () => {
        const chart = this.ref.current;

        if (!chart) {
            return null;
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        return (
            <div className="radar-right surveys-wrapper">
                <Label weight="medium">{localize("SURVEYS")}</Label>
                {items.map((item, index) => {
                    const survey = this.state.data[index];

                    return (
                        <RadarSidebarItem
                            key={index}
                            chart={chart}
                            item={item}
                            index={index}
                            survey={survey} />
                    )
                })}
            </div>
        )
    }

    private spintrPlugin = {
        id: "spintrPlugin",
        afterUpdate: (chart, args, options) => {
            if (!this.state.chartHasRendered) {
                this.setState({ chartHasRendered: true });
            }

            if (args.mode === "resize") {
                this.debouncedUpdate();
            }
        },
    };

    getLabelPositions = () => {
        const chart = this.ref.current;

        if (!chart) {
            return [];
        }

        const labels: string[] = chart.scales.r.getLabels()
            const _positions: IVectorChartPosition[] = labels.map((label, idx) => ({
                text: label,
                index: idx,
                total: labels.length,
                vectorId: this.state.vectors.find((vector) => vector.name === label).id,
                chartWidth: chart.width,
                // @ts-ignore
                ...chart.scales.r.getPointLabelPosition(idx),
            }));

            return _positions;
    }

    private debouncedUpdate = debounce(() => this.forceUpdate(), 10);

    export() {
        api.get("/api/v1/surveys/export").then((response: any) => {
            this.setState({
                exportFileUrl: response.data
            });
        }).catch(() => {
            this.setState({
                displayExportPopup: false
            });
        });
    }

    renderExportPopup() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.displayExportPopup}
                allowTouchBodyScroll
                onDismiss={() => {
                    this.setState({
                        displayExportPopup: false
                    });
                }}
            >
                <PopupHeader
                    text={localize("EXPORT")}
                    onClose={() => {
                        this.setState({
                            displayExportPopup: false
                        });
                    }} />
                {!this.state.exportFileUrl && (
                    <SpintrLoader />
                )}
                {this.state.exportFileUrl && (
                    <div>
                        <Label>{localize("EXPORT_DONE")}</Label>
                    </div>
                )}
                {this.state.exportFileUrl && (
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                        <DefaultButton
                            onClick={() => {
                                this.setState({
                                    displayExportPopup: false
                                });
                            }}
                            text={localize("Stang")} />
                        <PrimaryButton
                            onClick={() => {
                                window.open(this.state.exportFileUrl, "_blank");
                            }}
                            text={localize("LaddaNed")} />
                    </Stack>
                )}
            </Modal>
        )
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup popupFormKey="Project">
                <ProjectForm id={this.props.popupFormState.id} />
            </FormPopup>
        )
    }

    render() {
        if (this.props.projects.length === 0) {
            return (
                <CenteredContent>
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Project",
                                id: 0,
                                title: localize("START_NEW_PROJECT"),
                                isDisplayed: true,
                                hideHeader: true
                            }));
                        }}>
                        {/* <Visage2Icon icon="add" /> */}
                        {localize("START_NEW_PROJECT")}
                    </PrimaryButton>
                    {this.renderFormPopup()}
                </CenteredContent>
            )
        }

        return (
            <Page renderHeader={this.renderHeader.bind(this)} noContentStyle fullScreen>
                {this.renderContent()}
                {this.renderFormPopup()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <ProjectSelector />
                </div>
                <div className="header-right">
                    <DefaultButton onClick={() => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Project",
                            id: this.props.activeProjectId,
                            title: localize("EDIT_PROJECT"),
                            isDisplayed: true,
                            hideHeader: true
                        }));
                    }}>
                        {localize("EDIT_PROJECT")}
                    </DefaultButton>
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Project",
                                id: 0,
                                title: localize("START_NEW_PROJECT"),
                                isDisplayed: true,
                                hideHeader: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("START_NEW_PROJECT")}
                    </PrimaryButton>
                    <Notifications />
                </div>
            </div>
        )
    }

    renderContent() {   
        const hasData = !this.state.isLoading &&
            this.state.dataSets.length > 0 &&
            this.state.vectors.length > 0;

        if (this.state.isLoading) {
            return (
                <Loader />
            );
        }

        const labelPositions = this.getLabelPositions();

        return (
            <div className="radar-view">
                <div className="radar-view-content">
                    {this.state.isLoading && (
                        <Loader />
                    )}
                    {!hasData && !this.state.isLoading && (
                        <StatusMessageWithIcon text={localize("NO_DATA_AVAILABLE")} icon="home-1" />
                    )}
                        <div className="radar-view-graph" ref={this.containerRef}>
                    {!!hasData && !this.state.isLoading && (
                            <div className="canvas-wrapper">
                                <Radar
                                    //@ts-ignore
                                    ref={this.ref}
                                    data={{
                                        labels: this.state.vectors.map((vector) => vector.name),
                                        datasets: this.state.dataSets,
                                    }}
                                    plugins={[this.spintrPlugin]}
                                    options={RadarChartOptions(() => {
                                        //this.onSetChartSize(this)
                                    })}
                                />
                                {!!labelPositions && labelPositions.map((p, idx) => (
                                    <VectorChartLabel id={p.vectorId} key={idx} position={p} />
                                ))}
                            </div>
                    )}
                        </div>
                </div>
                {this.renderSidebar()}
                {this.renderExportPopup()}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    hasCustomHeadlineFont: !!state.instance.currentInstance.fontUrl,
    projects: state.project.projects,
    activeProjectId: state.project.activeProjectId,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Project"),
});

export default withRouter(connect(mapStateToProps)(RadarView));
