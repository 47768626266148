import { ReactElement } from "react";
import * as actionTypes from "../action-types/popup";

export interface IPopupState {
    confirmPopup: IConfirmPopupState;
}

export interface IConfirmPopupState {
    isOpen: boolean;
    title?: string;
    message?: string;
    content?: ReactElement;
    onConfirm?: () => void;
    onDismiss?: () => void;
}

const initialState: IPopupState = {
    confirmPopup: {} as IConfirmPopupState,
};

const popupReducer = (state: IPopupState = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONFIRM_POPUP:
            return {
                ...state,
                confirmPopup: {
                    ...action.props
                }
            }
        default:
            return state;
    }
};

export default popupReducer;
