import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';
import UnstyledButton from 'src/components/UnstyledButton/UnstyledButton';

import './InstanceList.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import Page from '../Page/Page';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { SpintrTypes } from 'src/typings';
import FormPopup from '../FormPopup/FormPopup';
import InstanceForm from '../InstanceForm/InstanceForm';
import { IPopupFormState } from 'src/reducers/ui';
import { setAdminMode, setPopupForm } from 'src/actions/ui';
import Notifications from '../Notifications/Notifications';

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    popupFormState?: IPopupFormState;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
}

class InstanceList extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false
        };
    }

    toggleDelete(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/instances/toggledelete/" + item.id).then(() => {
                        this.setState({
                            items: this.state.items.map((i: any) => {
                                return {
                                    ...i,
                                    deleted: i.id === item.id ?
                                        !i.deleted :
                                        i.deleted
                                }
                            })
                        });
                    });
                },
            })
        );
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
                {this.renderFormPopup()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey="InstanceList" placeholder={localize("SEARCH_PLACEHOLDER_CUSTOMERS")} />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: [{
                                key: "showDeleted",
                                text: this.state.includeDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        includeDeleted: !this.state.includeDeleted
                                    }, () => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    });
                                },
                            }]
                        }}
                    />
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Instance",
                                id: 0,
                                title: localize("CREATE_CUSTOMER"),
                                isDisplayed: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("CREATE_CUSTOMER")}
                    </PrimaryButton>
                    <Notifications />
                </div>
            </div>
        )
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="Instance"
                onClose={() => {
                    //@ts-ignore
                    this.listRef.current.reFetch();
                }}>
                <InstanceForm />
            </FormPopup>
        )
    }

    renderContent() {
        return (
            <div className="InstanceList">
                <PageHeader
                    title={localize("CUSTOMERS")}
                    hits={this.state.totalCount} />
                <SpintrList
                    ref={this.listRef}
                    searchKey="InstanceList"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/instances", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        items: items,
                                        totalCount: response.data.totalCount
                                    });
                                }).catch(() => { });
                        });
                    }}
                    data={{
                        data: this.state.items,
                        totalCount: this.state.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                return (
                                    <UnstyledButton
                                        className={"linkFGColor list-icon-button"}
                                        style={{
                                            ...(this.props.currentInstance.id === item.id ? { fontWeight: 600 } : {})
                                        }}
                                        onClick={() => {
                                            if (this.props.currentInstance.id === item.id) {
                                                this.props.dispatch(setAdminMode(false));
                                                this.props.history.push("/");
                                                return;
                                            }

                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("SWITCH_INSTANCE"),
                                                message: localize("SWITCH_INSTANCE_MSG").replace("{0}", item.name),
                                                onConfirm: () => {
                                                    this.props.history.push("/imp/" + item.id);
                                                }
                                            }));
                                        }}>
                                        <span style={{
                                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                        }}>{item.name}</span>
                                        {this.props.currentInstance.id === item.id && (
                                            <Visage2Icon icon="user" type="bold" color="p-link-color" />
                                        )}
                                    </UnstyledButton>
                                );
                            },
                        },
                        {
                            name: localize("PROJECTS"),
                            fieldName: "projectCount",
                        },
                        {
                            name: localize("INDUSTRY"),
                            fieldName: "industry",
                            minWidth: 200
                        },
                        {
                            name: localize("Version"),
                            fieldName: "pro",
                            onRender: (item) => (
                                <span>
                                    {localize(item.pro ? "Pro" : "Standard")}
                                </span>
                            ),
                        },
                        {
                            name: localize("USERS"),
                            fieldName: "userCount",
                        },
                        {
                            name: localize("Status"),
                            fieldName: "deleted",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [{
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.props.dispatch(setPopupForm({
                                                            popupFormKey: "Instance",
                                                            id: item.id,
                                                            title: localize("EDIT_CUSTOMER"),
                                                            isDisplayed: true
                                                        }));
                                                    },
                                                }, {
                                                    text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                    onClick: () => this.toggleDelete(item),
                                                }],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    isDescending={false}
                    orderByColumn="name"
                ></SpintrList>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Instance")
});

export default withRouter(connect(mapStateToProps)(InstanceList));