import React, { Component } from "react";
import ShowMoreText from "react-show-more-text";
import { localize } from "src/utils/l10n";
import { Label } from "src/components/Label/Label";
import MandatoryText from "src/components/MandatoryText/MandatoryText";
import "./PageHeader.scss";
import { ActionMenu } from "../ActionMenu";

interface IProps {
    title: string;
    renderTitle?: any;
    lang?: string;
    subText?: string;
    size?: spintrLabelSizes;
    displayMandatoryText?: boolean;
    useShowMoreSubText?: boolean;
    formatSubTextHtml?: boolean;
    hits?: number;
    actionMenuCategories?: any;
}

class PageHeader extends Component<IProps> {
    renderSubText() {
        if (!this.props.subText) {
            return null;
        }

        if (this.props.useShowMoreSubText && this.props.formatSubTextHtml) {
            return (
                <Label
                    className="pageHeader-subText"
                    size="body-2"
                    as="div"
                    color="p-mid-grey-3">
                    <ShowMoreText
                        lines={1}
                        more={localize("VisaMer")}
                        less={localize("VisaMindre")}
                        expanded={false}
                    // width={480}
                    >
                        <Label
                            size="body-2"
                            as="div"
                            color="p-mid-grey-3"
                            dangerouslySetInnerHTML={!this.props.formatSubTextHtml ? undefined : {
                                __html: this.props.subText
                            }} />
                    </ShowMoreText>
                </Label>
            )
        } else if (this.props.useShowMoreSubText) {
            return (
                <Label
                    className="pageHeader-subText"
                    size="body-2"
                    as="div"
                    color="p-mid-grey-3">
                    <ShowMoreText
                        lines={1}
                        more={localize("VisaMer")}
                        less={localize("VisaMindre")}
                        expanded={false}
                    // width={480}
                    >
                        <Label
                            size="body-2"
                            as="div"
                            color="p-mid-grey-3">
                            {this.props.subText}
                        </Label>
                    </ShowMoreText>
                </Label>
            )
        } else if (this.props.formatSubTextHtml) {
            return (
                <Label
                    className="pageHeader-subText"
                    size="body-2"
                    as="div"
                    color="p-mid-grey-3"
                    dangerouslySetInnerHTML={!this.props.formatSubTextHtml ? undefined : {
                        __html: this.props.subText
                    }} />
            )
        } else {
            return (
                <Label
                    className="pageHeader-subText"
                    size="body-2"
                    as="div"
                    color="p-mid-grey-3">
                    {this.props.subText}
                </Label>
            )
        }
    }

    render() {
        return (
            <div
                className={"pageHeader " + this.props.size}
                lang={this.props.lang}
            >
                <div className="left">
                    <div className="pageHeader-title">
                        {this.props.renderTitle ? (
                            this.props.renderTitle()
                        ) : (
                            <Label
                                size={!!this.props.size ? this.props.size : ("h4")}
                                as="h4"
                                weight="medium"
                                className="general-row-break">
                                {this.props.title}
                            </Label>
                        )}
                    </div>
                    {this.props.hits !== undefined && (
                        <Label className={"pageHeader-hits"} size="body-3" as="div" color="p-mid-grey-3">
                            {this.props.hits + " " + localize(this.props.hits === 1 ? "traff_small" : "traffar_small")}
                        </Label>
                    )}
                    {
                        this.renderSubText()
                    }
                    {!!this.props.displayMandatoryText && (
                        <MandatoryText />
                    )}
                </div>
                {this.props.actionMenuCategories && (
                    <div className="right">
                        <ActionMenu categories={this.props.actionMenuCategories} />
                    </div>
                )}
            </div>
        );
    }
}

export default PageHeader;
