import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/fi';
import 'moment/locale/nb'; // Norskt bokmål
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import { IDropdownOption } from "@fluentui/react";
import isDev from "../isDev";
import api from "../../api/SpintrApi";

let tags: { [key: string]: string };
let currentLanguage: any;
let hasTags: boolean;

export type LanguageFetchCallback = (success: boolean) => void;

tags = {};
currentLanguage = 0;
hasTags = false;

export function getLanguageTagsFromStorage() {
    try {
        const localStorageId = "spintr_language";

        const data: any = localStorage.getItem(localStorageId);

        if (!!data) {
            tags = JSON.parse(data);

            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
}

function setLanguageTagsInStorage(data: any) {
    const localStorageId = "spintr_language";

    try {
        localStorage.setItem(localStorageId, JSON.stringify(data));
    } catch { }
}

/**
 * Consumes and stores a provided localization tag dictionary.
 *
 * @param items Localization tags to be stored.
 */
export function consumeLocalization(items: { [key: string]: string }): void {
    tags = items;

    setLanguageTagsInStorage(tags);
}

/**
 * Specifies what language moment should use
 *
 * @param langId Language id
 */
export function setMomentLang(langId: number): void {
    const languageCodes = ["sv", "nb", "en-gb", "de", "fi"];
    const langCode = languageCodes[langId - 1];
    document.documentElement.lang = langCode;
    moment.locale(langCode);
}

/**
 * Fetches and stores the correct localization tags from the server.
 *
 * @param api An API object that will be used to execute the request.
 * @param next Function to be called once loading is completed.
 */
export const fetchLangTags = (() => {
    let isLoading: boolean = false;

    return (next: LanguageFetchCallback, language?: number): void => {
        if (isLoading) {
            return;
        }

        isLoading = true;

        const url = !!language ?
            ("/api/v1/language?language=" + language) :
            "/api/v1/language";

        api.get(url).then(
            (response: any) => {
                consumeLocalization(response.data.tags);
                next(true);
                currentLanguage = response.data.currentLanguage;
                isLoading = false;
            },
            () => {
                next(false);

                isLoading = false;
            }
        );
    };
})();

/**
 * Returns the current localizationtag
 *
 */
export const getLocalizationTag = () => {
    switch (currentLanguage) {
        case "1":
            return "sv";
        case "2":
            return "no";
        case "3":
            return "en";
        case "4":
            return "de";
        case "5":
            return "fi";
    }
};

/**
 * Localizes a string based on the provided key.
 *
 * @param key A localization key.
 * @returns The localized string if the key could be found, otherwise the key.
 */
const localize = (key: string, skipLog?: boolean) => {
    if (tags && hasTags === false) {
        hasTags = Object.keys(tags).length > 0;
    }

    if (!tags || !hasTags) {
        if (!skipLog && isDev) {
            //console.log("Language tags not fetched yet but tried to get tag: " + key);
        }

        return key;
    }

    const tag = tags[key];

    if (!tag) {
        if (!skipLog && isDev) {
            console.log("Language tag not found: " + key);
        }

        return key;
    }

    return tag;
};
export default localize;

/**
 * Replaces format placeholders and returns the formatted string.
 *
 * The function looks for format placeholders based around the number provided
 * in the index argument wrapped with curly brackets. E.g.: {0}, {1} and so on.
 *
 * @param input Input string containing format placeholders.
 * @param replacement Data to replace the format placeholder with.
 * @param index Zero based format placeholder.
 * @returns Formatted string
 */
export const replaceFormatIndex = (input: string, replacement: any, index: number): string =>
    input.replace(new RegExp(`\\{${index}\\}`, "g"), replacement);

/**
 * Localizes a string based on the provided key and formats the input data.
 *
 * Example:
 * Given that key "FOO" corresponds to the string "Hello {0}, I'm {1}"
 * Then calling the function with parameters "FOO", "foo", "bar" will return
 * "Hello foo, I'm bar"
 *
 * @param key A localization key
 * @param args Any arguments to be formatted into the localized string.
 * @returns The localized string if the key could be found, otherwise the key.
 */
export const localizeFormat: (key: string, ...args: any) => string = (key: string, ...args: any[]) =>
    args.reduce(replaceFormatIndex, localize(key));

/**
 *
 * @param typeId A typeId
 * @param options Able to change the word to different states
 */

export const getTypeName = (typeId: number, options: { [key: string]: string | boolean }): string => {
    let tag: string;

    options = Object.assign(
        {},
        {
            case: "indefinite" /* indefinite | definite | possessive */,
            form: "singular" /* singular | plural */,
        },
        options
    );

    tag = "Ubertype" + typeId + "_" + (options.form === "plural" ? 1 : 0) + "_";
    switch (options.case) {
        case "indefinite":
            tag += 0;
            break;
        case "definite":
            tag += 1;
            break;
        case "possessive":
            tag += 2;
            break;
        default:
        case "lemma":
            tag += 4;
            break;
    }

    if (options.alternative) {
        tag += "_alternative";
    }

    return localize(tag);
};

export const getLanguageOptions = (): IDropdownOption[] => [
    { key: "", text: localize("EjAngivet") },
    ...[
        { key: "sv", text: localize("Swedish") },
        { key: "no", text: localize("Norwegian") },
        { key: "en", text: localize("English") },
        { key: "de", text: localize("German") },
        { key: "fi", text: localize("Finnish") },
    ].sort((a, b) => (a.text > b.text ? 1 : -1)),
];