import React from "react";
import "./CenteredContent.scss";

interface IProps {
    children: any;
}

const CenteredContent = (props: IProps) => {
    return (
        <div className="CenteredContent">{props.children}</div>
    )
}

export default CenteredContent;
