import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import Label from "src/components/Label/Label";
import { IApplicationState } from "src/reducer";
import logoUrl from "src/style/images/Logo_spintr_RGB_Blue-2.png";

import "./SplitAuth.scss";

interface IProps {
    backgroundUrl: string;
    error?: string;
    children?: any;
    currentInstance?: any;
}

class SplitAuth extends Component<IProps> {
    private getLogoUrl = (): string => {
        const url = this.props.currentInstance.colorLogoUrl || this.props.currentInstance.logoUrl || logoUrl;

        return url;
    }

    public render() {
        const { backgroundUrl, error } = this.props;

        return (
            <div className="split-auth-view">
                {error && (
                    <div className="error-bar" role="alert">
                        <Label color="p-white" size="body-1" weight="medium" centerText>
                            {error}
                        </Label>
                    </div>
                )}
                <div className={classNames("content", { "has-error": error })}>
                    <div className="left">
                        <div className="form-container">
                            <div className="login-logo-wrapper">
                                <img src={this.getLogoUrl()} alt="Spintr logotyp" />
                            </div>

                            {this.props.children}
                        </div>
                    </div>
                    <div className="right">
                        <div className="background-image" style={{ backgroundImage: `url("${backgroundUrl}")` }} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentInstance: state.instance.currentInstance,
});

export default connect(mapStateToProps)(SplitAuth);
