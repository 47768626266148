import { FontIcon } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";
import "./PopupHeader.scss";

interface IProps {
    text: string;
    subText?: string;
    onClose?: any;
    onBackClick?: any;
    hideCloseButton?: boolean;
    displayBackButton?: boolean;
    renderFooterContent?: any;
}

class PopupHeader extends Component<IProps> {
    render() {
        const classNames = ["PopupHeader"];

        if (this.props.renderFooterContent) {
            classNames.push("has-footer-content");
        }

        return (
            <div className={classNames.join(" ")}>
                <div className="PopupHeader-inner">
                    <div className="top-row">
                        {this.props.displayBackButton && (
                            <UnstyledButton
                                className="back-button"
                                onClick={this.props.onBackClick}
                            >
                                <FontIcon
                                    iconName="VisageSidebarArrowCircleLeft"
                                />
                            </UnstyledButton>
                        )}
                        <Label
                            size="h7"
                            color="p-dark-grey-3"
                            weight="semi-bold">
                            {this.props.text}
                        </Label>
                        {
                            !this.props.hideCloseButton && (
                                <UnstyledButton
                                    className="close-button"
                                    title={localize("Stang")}
                                    onClick={() => {
                                        if (this.props.onClose) {
                                            this.props.onClose();
                                        }
                                    }}
                                >
                                    <FontIcon iconName="ChromeClose" />
                                </UnstyledButton>
                            )
                        }
                    </div>
                    {!!this.props.renderFooterContent && (
                        <div className="footer">
                            {this.props.renderFooterContent()}
                        </div>
                    )}
                </div>
                {!!this.props.subText && (
                    <Label
                        className="sub-text"
                        size="body-2"
                        color="p-mid-grey-3">
                        {this.props.subText}
                    </Label>
                )}
            </div>
        )
    }
}

export default PopupHeader;
