import { SearchBox } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/utils/l10n";

import "./SpintrSearch.scss";
import Visage2Icon from "../Visage2Icon/Visage2Icon";

interface IProps {
    onChange?: any;
    onClear?: any;
    onFocus?: any;
    value?: string;
    placeholder?: string;
    classes?: string;
    small?: boolean;
    id?: string;
}

interface IState {
    value: string;
}

class SpintrSearch extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            value: !!props.value ? props.value : ""
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: !!this.props.value ? this.props.value : ""
            })
        }
    }

    render() {
        const hasValue = !!this.state.value && this.state.value.length > 0;

        let classNames = ["SpintrSearch"];

        if (!!hasValue) {
            classNames.push("hasValue")
        }

        if (this.props.small) {
            classNames.push("SpintrSearch-small")
        }

        if (!!this.props.classes) {
            classNames.push(this.props.classes);
        }

        return (
            <div className={classNames.join(" ")} id={this.props.id}>
                <Visage2Icon icon="search-normal-1" color={"p-mid-grey-5"} size={this.props.small ? "small" : "medium"} />
                <SearchBox
                    role={"menuitem"}
                    onFocus={this.props.onFocus}
                    value={this.state.value}
                    onChange={this.props.onChange}
                    onClear={this.props.onClear}
                    placeholder={this.props.placeholder || (localize("Sok") + "...")}
                    styles={{ root: { paddingLeft: 0, minWidth: 175 } }}
                    showIcon={false}
                    disableAnimation
                />
            </div>
        )
    }
}

export default SpintrSearch;