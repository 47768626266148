import React, { Component, ReactNode } from "react";
import { Label } from "src/components/Label/Label";
import "./ActionMenuItem.scss";

interface IActionMenuItem {
    icon?: string;
    key?: string;
    text: string;
    onClick?: () => void;
    href?: string;
    subMenuProps?: any;
    canCheck?: boolean;
    isChecked?: boolean;
}

interface IActionMenuItemProps {
    item: IActionMenuItem;
}

class ActionMenuItem extends Component<IActionMenuItemProps> {
    constructor(props: IActionMenuItemProps) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    public onItemClicked(): void {
        if (typeof this.props.item.onClick !== "function") {
            return;
        }

        this.props.item.onClick();
    }

    public render(): ReactNode {
        const item = this.props.item;

        return (
            <div
                className="ActionMenuItem"
                onClick={this.onItemClicked}
                role="button">
                {item.icon && (
                    <div className="icon">
                        {/* <Icon icon={item.icon} /> */}
                    </div>
                )}
                <div
                    className="text">
                    <Label
                        as="span"
                        size="body-2"
                        weight="regular">
                        {item.text}
                    </Label>
                </div>
            </div>
        );
    }
}

export default ActionMenuItem;