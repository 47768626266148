import { Link } from "@fluentui/react";
import React, { useCallback } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import "./Notifications.scss";
import { ActionMenu } from "../ActionMenu";
import moment from "moment";

interface IProps {
    item: any;
    onMarkAsRead: any;
    onDelete: any;
}

const NotificationItem = (props: IProps) => {
    const getActionMenuItems = useCallback(() => {
        const actionMenuItems = [];

        if (props.item.isUnread) {
            actionMenuItems.push({
                text: localize("chat_mark_read"),
                onClick: props.onMarkAsRead
            });
        }

        actionMenuItems.push({
            text: localize("TaBort"),
            onClick: props.onDelete
        });

        return actionMenuItems;
    }, [props.item]);

    return (
        <Link
            to={props.item.url}
            className={"NotificationItem" + (props.item.unread ? " isUnread" : "")}>
            <div className="top-row">
                <Label weight="medium" fontType="display">{props.item.text}</Label>
                {props.item.unread && (
                    <div className="unread-circle" />
                )}
                <ActionMenu
                    categories={[
                        {
                            items: getActionMenuItems()
                        },
                    ]}
                />
            </div>
            <Label fontType="display" color="p-mid-grey-1" size="body-2">
                {moment(props.item.createdDate).fromNow()}
            </Label>
        </Link>
    )
}

export default NotificationItem;