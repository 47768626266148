import { DefaultButton, Pivot, PivotItem, PrimaryButton } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';

import './AdvancedSettings.scss';
import FormSection from '../FormSection/FormSection';
import FormControl from '../FormControl/FormControl';
import Label from '../Label/Label';
import SpintrLoader from '../Loader/Loader';
import SuccessMessagebar from '../Messagebars/SuccessMessagebar';
import ErrorMessagebar from '../Messagebars/ErrorMessagebar';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import Page from '../Page/Page';
import { SpintrTypes } from 'src/typings';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import { setPopupForm } from 'src/actions/ui';
import FormPopup from '../FormPopup/FormPopup';
import SuperInstanceForm from '../SuperInstanceForm/SuperInstanceForm';
import { IPopupFormState } from 'src/reducers/ui';
import VectorForm from '../VectorForm/VectorForm';
import ProjectTypeForm from '../ProjectTypeForm/ProjectTypeForm';

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    superInstancesPopupFormState?: IPopupFormState;
    vectorsPopupFormState?: IPopupFormState;
    projectTypesPopupFormState?: IPopupFormState;
}

interface IState {
    templates: any;
    superInstances: any;
    vectors: any;
    isLoading: boolean;
    saveSuccess: boolean;
    saveError: any[];
    successMessage?: string;
    copyLinkSuccess: boolean;
    copyLinkError: any[];
    activePivotIndex: number;
}

class AdvancedSettings extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            templates: {
                items: [],
                totalCount: 0,
                isLoading: true,
                includeDeleted: false
            },
            superInstances: {
                items: [],
                totalCount: 0,
                isLoading: true,
                includeDeleted: false
            },
            vectors: {
                items: [],
                totalCount: 0,
                isLoading: true,
                includeDeleted: false
            },
            isLoading: false,
            saveSuccess: false,
            saveError: [],
            copyLinkSuccess: false,
            copyLinkError: [],
            activePivotIndex: 0
        };
    }

    toggleDeleteVectorTemplate(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/vectors/templates/toggledelete/" + item.id).then(() => {
                        this.setState({
                            templates: {
                                ...this.state.templates,
                                items: this.state.templates.items.map((i: any) => {
                                    return {
                                        ...i,
                                        deleted: i.id === item.id ?
                                            !i.deleted :
                                            i.deleted
                                    }
                                })
                            }
                        });
                    });
                },
            })
        );
    }

    toggleDeleteSuperInstance(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/instances/super/toggledelete/" + item.id).then(() => {
                        this.setState({
                            superInstances: {
                                ...this.state.superInstances,
                                items: this.state.superInstances.items.map((i: any) => {
                                    return {
                                        ...i,
                                        deleted: i.id === item.id ?
                                            !i.deleted :
                                            i.deleted
                                    }
                                })
                            }
                        });
                    });
                },
            })
        );
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    postVectorStatus(vectorId, status) {
        api.post("/api/v1/vectors/status", {
            id: vectorId,
            status: status
        }).then(() => {
            //@ts-ignore
            this.listRef.current.reFetch();
        }).catch(() => { });
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
            </Page>
        )
    }

    renderHeader() {
        const menuItems = [
            [{
                key: "showDeleted",
                text: this.state.templates.includeDeleted ?
                    localize("DoljBorttagna") :
                    localize("VisaBorttagna"),
                onClick: () => {
                    this.setState({
                        templates: {
                            ...this.state.templates,
                            includeDeleted: !this.state.templates.includeDeleted
                        }
                    }, () => {
                        //@ts-ignore
                        this.listRef.current.reFetch();
                    });
                },
            }],
            [{
                key: "includeDeleted",
                text: this.state.vectors.includeDeleted ?
                    localize("DoljBorttagna") :
                    localize("VisaBorttagna"),
                onClick: () => {
                    this.setState({
                        vectors: {
                            ...this.state.vectors,
                            includeDeleted: !this.state.vectors.includeDeleted
                        }
                    }, () => {
                        //@ts-ignore
                        this.listRef.current.reFetch();
                    });
                },
            }],
            [{
                key: "showDeleted",
                text: this.state.superInstances.includeDeleted ?
                    localize("DoljBorttagna") :
                    localize("VisaBorttagna"),
                onClick: () => {
                    this.setState({
                        superInstances: {
                            ...this.state.superInstances,
                            includeDeleted: !this.state.superInstances.includeDeleted
                        }
                    }, () => {
                        //@ts-ignore
                        this.listRef.current.reFetch();
                    });
                },
            }],
        ]

        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey={"AdvancedSettings" + this.state.activePivotIndex} />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: menuItems[this.state.activePivotIndex]
                        }}
                    />
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: this.state.activePivotIndex === 0 ?
                                    "ProjectType" :
                                    this.state.activePivotIndex === 1 ?
                                        "Vector" :
                                        "SuperInstance",
                                id: 0,
                                title: localize(this.state.activePivotIndex === 0 ?
                                    "CREATE_PROJECT_TYPE" :
                                    this.state.activePivotIndex === 1 ?
                                        "CREATE_VECTOR" :
                                        "CREATE_SUPER_INSTANCE"),
                                isDisplayed: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize(this.state.activePivotIndex === 0 ?
                                    "CREATE_PROJECT_TYPE" :
                                    this.state.activePivotIndex === 1 ?
                                        "CREATE_VECTOR" :
                                        "CREATE_SUPER_INSTANCE")}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="AdvancedSettings">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={this.state.successMessage ? this.state.successMessage : localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PageHeader title={localize("AvanceradeInstallningar")} />
                <Pivot onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                    //@ts-ignore
                    let key = item.key;

                    this.setState({
                        activePivotIndex: Number(key.replace(".", ""))
                    });
                }}>
                    <PivotItem headerText={localize("PROJECT_TYPES")}>
                        <SpintrList
                            ref={this.listRef}
                            searchKey="AdvancedSettings0"
                            disableCommandBar
                            // history={this.props.history}
                            take={1000}
                            fetch={(skip, take, columnId, isAscending, searchQuery) => {
                                return new Promise((resolve, reject) => {
                                    api.get("/api/v1/projects/projecttypes", {
                                        params: {
                                            orderByColumn: columnId,
                                            includeDeleted: this.state.templates.includeDeleted,
                                            isAscending: isAscending,
                                            searchQuery: searchQuery,
                                            take: take,
                                            skip: skip,
                                        },
                                    }).then((response: AxiosResponse) => {
                                        let items = response.data.map((obj) => {
                                            return obj;
                                        });

                                        resolve({
                                            data: items,
                                            totalCount: items.length
                                        });

                                        this.setState({
                                            templates: {
                                                ...this.state.templates,
                                                items: items,
                                                totalCount: items.length
                                            }
                                        });
                                    }).catch(() => {});
                                });
                            }}
                            data={{
                                data: this.state.templates.items,
                                totalCount: this.state.templates.totalCount
                            }}
                            columns={[
                                {
                                    name: localize("Namn"),
                                    fieldName: "name",
                                    onRender: (item) => {
                                        return (
                                            <UnstyledButton
                                                className={"linkFGColor"}
                                                onClick={() => {
                                                    this.props.dispatch(setPopupForm({
                                                        popupFormKey: this.state.activePivotIndex === 0 ?
                                                            "ProjectType" :
                                                            this.state.activePivotIndex === 1 ?
                                                                "Vector" :
                                                                "SuperInstance",
                                                        id: item.id,
                                                        title: localize(this.state.activePivotIndex === 0 ?
                                                            "EDIT_PROJECT_TYPE" :
                                                            this.state.activePivotIndex === 1 ?
                                                                "EDIT_VECTOR" :
                                                                "EDIT_SUPER_INSTANCE"),
                                                        isDisplayed: true
                                                    }));
                                                }}>
                                                <span style={{
                                                    ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                                }}>{item.name}</span>
                                            </UnstyledButton>
                                        );
                                    },
                                },
                                {
                                    name: localize("VECTORS"),
                                    fieldName: "vectorNames",
                                    minWidth: 250,
                                    onRender: (item) => {
                                        const vectorNames = item.vectors.map(x => x.name).join(", ");
                                        return (
                                            <span title={vectorNames}>
                                                {vectorNames}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: localize("Skapad"),
                                    fieldName: "createdDate",
                                    minWidth: 200,
                                    onRender: (item) => {
                                        return (
                                            <span>
                                                {moment(item.createdDate).format("lll")}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: localize("Status"),
                                    fieldName: "deleted",
                                    minWidth: 150,
                                    onRender: (item) => {
                                        return (
                                            <span>
                                                {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: "",
                                    minWidth: listActionMenuWidth,
                                    maxWidth: listActionMenuWidth,
                                    onRender: (item: any) => {
                                        return (
                                            <ActionMenu
                                                categories={[
                                                    {
                                                        items: [{
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.props.dispatch(setPopupForm({
                                                                    popupFormKey: "ProjectType",
                                                                    id: item.id,
                                                                    title: localize("EDIT_PROJECT_TYPE"),
                                                                    isDisplayed: true
                                                                }));
                                                            }
                                                        }, {
                                                            text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                            onClick: () => this.toggleDeleteVectorTemplate(item),
                                                        }],
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ]}
                            isDescending={true}
                            orderByColumn="name"
                        ></SpintrList>
                        {this.props.projectTypesPopupFormState && (
                            <FormPopup
                                popupFormKey="ProjectType"
                                onClose={() => {
                                    //@ts-ignore
                                    this.listRef.current.reFetch();
                                }}>
                                <ProjectTypeForm item={this.props.projectTypesPopupFormState.id ?
                                    this.state.templates.items.find(x => x.id === this.props.projectTypesPopupFormState.id) :
                                    undefined} />
                            </FormPopup>
                        )}
                    </PivotItem>
                    <PivotItem headerText={localize("VECTORS")}>
                        <SpintrList
                            ref={this.listRef}
                            searchKey="AdvancedSettings1"
                            disableCommandBar
                            // history={this.props.history}
                            fetch={(skip, take, columnId, isAscending, searchQuery) => {
                                return new Promise((resolve, reject) => {
                                    api
                                        .get("/api/v1/vectors/list", {
                                            params: {
                                                orderByColumn: columnId,
                                                includeDeleted: this.state.vectors.includeDeleted,
                                                isAscending: isAscending,
                                                searchQuery: searchQuery,
                                                take: take,
                                                skip: skip,
                                            },
                                        })
                                        .then((response: AxiosResponse) => {
                                            let items = response.data.items.map((obj) => {
                                                return obj;
                                            });

                                            resolve({
                                                data: items,
                                                totalCount: response.data.totalCount
                                            });

                                            this.setState({
                                                vectors: {
                                                    ...this.state.vectors,
                                                    items: items,
                                                    totalCount: response.data.totalCount
                                                }
                                            });
                                        });
                                });
                            }}
                            data={{
                                data: this.state.vectors.items,
                                totalCount: this.state.vectors.totalCount
                            }}
                            columns={[
                                {
                                    name: localize("Namn"),
                                    fieldName: "name",
                                    onRender: (item) => {
                                        return (
                                            <UnstyledButton
                                                className={"linkFGColor"}
                                                onClick={() => {
                                                    this.props.dispatch(setPopupForm({
                                                        popupFormKey: this.state.activePivotIndex === 0 ?
                                                            "ProjectType" :
                                                            this.state.activePivotIndex === 1 ?
                                                                "Vector" :
                                                                "SuperInstance",
                                                        id: item.id,
                                                        title: localize(this.state.activePivotIndex === 0 ?
                                                            "EDIT_PROJECT_TYPE" :
                                                            this.state.activePivotIndex === 1 ?
                                                                "EDIT_VECTOR" :
                                                                "EDIT_SUPER_INSTANCE"),
                                                        isDisplayed: true
                                                    }));
                                                }}>
                                                <span>{!!item.name ? item.name : "(No name)"}</span>
                                            </UnstyledButton>
                                        );
                                    },
                                },
                                {
                                    name: localize("Status"),
                                    fieldName: "status",
                                    minWidth: 200,
                                    onRender: (item) => {
                                        const statuses = ["REMOVED", "Aktiv"];

                                        return (
                                            <span>
                                                {localize(statuses[item.status])}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: localize("Skapad"),
                                    fieldName: "createdDate",
                                    minWidth: 200,
                                    onRender: (item) => {
                                        return (
                                            <span>
                                                {moment(item.createdDate).format("lll")}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: "",
                                    minWidth: listActionMenuWidth,
                                    maxWidth: listActionMenuWidth,
                                    onRender: (item: any) => {
                                        let categories = [{
                                            items: []
                                        }];

                                        categories[0].items.push({
                                            text: localize("Redigera"),
                                            onClick: () => {
                                                this.props.dispatch(setPopupForm({
                                                    popupFormKey: this.state.activePivotIndex === 0 ?
                                                        "ProjectType" :
                                                        this.state.activePivotIndex === 1 ?
                                                            "Vector" :
                                                            "SuperInstance",
                                                    id: item.id,
                                                    title: localize(this.state.activePivotIndex === 0 ?
                                                        "EDIT_PROJECT_TYPE" :
                                                        this.state.activePivotIndex === 1 ?
                                                            "EDIT_VECTOR" :
                                                            "EDIT_SUPER_INSTANCE"),
                                                    isDisplayed: true
                                                }));
                                            }
                                        });

                                        categories[0].items.push({
                                            text: localize("Kopiera"),
                                            onClick: () => {
                                                api.post("api/v1/vectors/copy/" + item.id).then(() => {
                                                    //@ts-ignore
                                                    this.listRef.current.reFetch();
                                                }).catch(() => { });
                                            }
                                        });

                                        if (item.status === 0) {
                                            categories[0].items.push({
                                                text: localize("Aterstall"),
                                                onClick: () => {
                                                    this.postVectorStatus(item.id, 1);
                                                }
                                            });
                                        } else if (item.status === 1) {
                                            categories[0].items.push({
                                                text: localize("TaBort"),
                                                onClick: () => {
                                                    this.postVectorStatus(item.id, 0);
                                                }
                                            });
                                        }

                                        return (
                                            <ActionMenu
                                                categories={categories}
                                            />
                                        );
                                    },
                                },
                            ]}
                            isDescending={true}
                            orderByColumn="createdDate"
                        ></SpintrList>
                        {this.props.vectorsPopupFormState && (
                            <FormPopup
                                popupFormKey="Vector"
                                onClose={() => {
                                    //@ts-ignore
                                    this.listRef.current.reFetch();
                                }}>
                                <VectorForm id={this.props.vectorsPopupFormState.id} />
                            </FormPopup>
                        )}
                    </PivotItem>
                    <PivotItem headerText={"Cache"}>
                        <FormSection>
                            <FormControl>
                                <Label size="body-2" color="p-mid-grey-4">{localize("CLEAR_CACHE_DESCRIPTION")}</Label>
                            </FormControl>
                            {
                                this.state.isLoading && (
                                    <SpintrLoader />
                                )
                            }
                            {
                                !this.state.isLoading && (
                                    <FormControl>
                                        <PrimaryButton
                                            onClick={() => {
                                                this.setState({
                                                    isLoading: true
                                                }, () => {
                                                    api.get("/api/v1/instances/cache/clear").then(() => {
                                                        this.setState({
                                                            saveError: [],
                                                            saveSuccess: true,
                                                            isLoading: false,
                                                            successMessage: localize("CACHE_CLEAR_SUCCESS")
                                                        });
                                                    }).catch(this.handleCatch.bind(this));
                                                });
                                            }}>
                                            {localize("RensaCache")}
                                        </PrimaryButton>
                                    </FormControl>
                                )
                            }
                        </FormSection>
                    </PivotItem>
                    {this.props.currentUser.isSuperAdmin && (
                        <PivotItem headerText={localize("SUPER_INSTANCES")}>
                            {this.state.copyLinkSuccess && (
                                <SuccessMessagebar
                                    message={localize("COPY_REGISTER_LINK_SUCCESS")}
                                    onDismiss={() => {
                                        this.setState({
                                            copyLinkSuccess: false
                                        });
                                    }}
                                />
                            )}
                            {this.state.copyLinkError.length > 0 && (
                                <ErrorMessagebar
                                    errorList={this.state.copyLinkError}
                                    onDismiss={() => {
                                        this.setState({
                                            copyLinkError: [],
                                        });
                                    }}
                                />
                            )}
                            <SpintrList
                                ref={this.listRef}
                                searchKey="AdvancedSettings2"
                                disableCommandBar
                                // history={this.props.history}
                                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                                    return new Promise((resolve, reject) => {
                                        api
                                            .get("/api/v1/instances/super", {
                                                params: {
                                                    orderByColumn: columnId,
                                                    includeDeleted: this.state.superInstances.includeDeleted,
                                                    isAscending: isAscending,
                                                    searchQuery: searchQuery,
                                                    take: take,
                                                    skip: skip,
                                                },
                                            })
                                            .then((response: AxiosResponse) => {
                                                let items = response.data.items.map((obj) => {
                                                    return obj;
                                                });

                                                resolve({
                                                    data: items,
                                                    totalCount: response.data.totalCount
                                                });

                                                this.setState({
                                                    superInstances: {
                                                        ...this.state.superInstances,
                                                        items: items,
                                                        totalCount: response.data.totalCount
                                                    }
                                                });
                                            });
                                    });
                                }}
                                data={{
                                    data: this.state.superInstances.items,
                                    totalCount: this.state.superInstances.totalCount
                                }}
                                columns={[
                                    {
                                        name: localize("Namn"),
                                        fieldName: "name",
                                        onRender: (item) => {
                                            return (
                                                <UnstyledButton
                                                    className={"linkFGColor"}
                                                    style={{
                                                        ...(this.props.currentInstance.superInstanceId === item.id ? { fontWeight: 600 } : {})
                                                    }}
                                                    onClick={() => {
                                                        if (this.props.currentInstance.superInstanceId === item.id) {
                                                            return;
                                                        }

                                                        this.props.dispatch(setConfirmPopup({
                                                            isOpen: true,
                                                            title: localize("SWITCH_SUPER_INSTANCE"),
                                                            message: localize("SWITCH_SUPER_INSTANCE_MSG").replace("{0}", item.name),
                                                            onConfirm: () => {
                                                                this.props.history.push("/imp/" + item.firstInstanceId);
                                                            }
                                                        }));
                                                    }}>
                                                    <span style={{
                                                        ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                                    }}>{item.name}</span>
                                                    {this.props.currentInstance.superInstanceId === item.id && (
                                                        <Visage2Icon icon="user" type="bold" color="p-link-color" />
                                                    )}
                                                </UnstyledButton>
                                            )
                                        },
                                    },
                                    {
                                        name: localize("Skapad"),
                                        fieldName: "createdDate",
                                        minWidth: 200,
                                        onRender: (item) => {
                                            return (
                                                <span>
                                                    {moment(item.createdDate).format("lll")}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: localize("Status"),
                                        fieldName: "deleted",
                                        minWidth: 200,
                                        onRender: (item) => {
                                            return (
                                                <span>
                                                    {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "",
                                        minWidth: listActionMenuWidth,
                                        maxWidth: listActionMenuWidth,
                                        onRender: (item: any) => {
                                            return (
                                                <ActionMenu
                                                    categories={[
                                                        {
                                                            items: [{
                                                                text: localize("COPY_REGISTER_LINK"),
                                                                onClick: () => {
                                                                    const link = window.location.origin + "/register?partner=" + encodeURIComponent(item.externalId);
                                                                    let result = false;

                                                                    try {
                                                                        if (!!navigator &&
                                                                            !!navigator.clipboard &&
                                                                            !!navigator.clipboard.writeText) {
                                                                            navigator.clipboard.writeText(link);
                                                                            result = true;
                                                                        }

                                                                        if (!result &&
                                                                            !!navigator &&
                                                                            !!navigator.clipboard &&
                                                                            //@ts-ignore
                                                                            !!navigator.clipboard.setData) {
                                                                            //@ts-ignore
                                                                            navigator.clipboard.setData(link);
                                                                            result = true;
                                                                        }
                                                                    } catch (err) { }

                                                                    if (result) {
                                                                        this.setState({
                                                                            copyLinkSuccess: true,
                                                                            copyLinkError: []
                                                                        });
                                                                    } else {
                                                                        const errorMessage = localize("COPY_FAILED") + link;

                                                                        this.setState({
                                                                            copyLinkSuccess: false,
                                                                            copyLinkError: [errorMessage]
                                                                        });
                                                                    }
                                                                }
                                                            }, {
                                                                text: localize("Redigera"),
                                                                onClick: () => {
                                                                    this.props.dispatch(setPopupForm({
                                                                        popupFormKey: "SuperInstance",
                                                                        id: item.id,
                                                                        title: localize("EDIT_SUPER_INSTANCE"),
                                                                        isDisplayed: true
                                                                    }));
                                                                }
                                                            }, {
                                                                text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                                onClick: () => this.toggleDeleteSuperInstance(item),
                                                            }],
                                                        },
                                                    ]}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                isDescending={true}
                                orderByColumn="name"
                            ></SpintrList>
                            {this.props.superInstancesPopupFormState && (
                                <FormPopup
                                    popupFormKey="SuperInstance"
                                    onClose={() => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    }}>
                                    <SuperInstanceForm id={this.props.superInstancesPopupFormState.id} />
                                </FormPopup>
                            )}
                        </PivotItem>
                    )}
                </Pivot>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    superInstancesPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "SuperInstance"),
    vectorsPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Vector"),
    projectTypesPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "ProjectType")
});

export default withRouter(connect(mapStateToProps)(AdvancedSettings));