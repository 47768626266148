import api from "src/api/SpintrApi";
import * as actionTypes from "../action-types/user";

export const setCurrentUser = (item: any) => {
    return {
        meta: {
            item
        },
        type: actionTypes.SET_CURRENT_USER,
    }
}

export const fetchCurrentUser = () => {
    return {
        payload: api.get("/api/v1/users/me"),
        type: actionTypes.FETCH_CURRENT_USER,
    }
}
