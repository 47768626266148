import { DefaultButton, Pivot, PivotItem, PrimaryButton, SpinButton, Stack, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { uniqueId, validateRequiredTextField } from 'src/utils';
import { localize } from 'src/utils/l10n';
import FormControl from 'src/components/FormControl/FormControl';
import FormSection from 'src/components/FormSection/FormSection';
import Loader from 'src/components/Loader/Loader';
import ErrorMessagebar from 'src/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/components/Messagebars/SuccessMessagebar';

import './VectorForm.scss';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Label from '../Label/Label';
import { setPopupForm } from 'src/actions/ui';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';

interface IProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    id: number;
}

interface IState {
    isLoading: boolean;
    isNew: boolean;
    item: any;
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
}

class VectorForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const isNew = props.id === 0;

        this.state = {
            isLoading: !isNew,
            isNew,
            item: {
                id: 0,
                status: 0,
                name: "",
                questions: []
            },
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false,
        };
    }

    componentDidMount() {
        if (!this.state.isNew) {
            const id = this.props.id;

            api.get("api/v1/vectors/" + id).then((response: any) => {
                var item = response.data;

                for (let q of item.questions) {
                    q.tmpId = uniqueId();
                }

                this.setState({
                    item,
                    isLoading: false
                });
            }).catch(this.handleCatch.bind(this))
        }
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    onCancelClick = () => {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "Vector",
                isDisplayed: false
            }));
        });
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            let payload = { ...this.state.item };
            let saveError: string[] = [];

            for (let q of payload.questions) {
                if (!q.text) {
                    saveError.push("DuMasteFyllaIEttNamn");
                }
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/vectors", payload).then((response: any) => {
                this.props.dispatch(setPopupForm({
                    popupFormKey: "Vector",
                    isDisplayed: false
                }));

                api.post("/api/v1/language/translate/all").then(() => {}).catch(() => {});
            }).catch(this.handleCatch.bind(this));
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="VectorForm fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.onSaveClick.bind(this)} data={this.state.item}>
                    <Pivot>
                        <PivotItem headerText={localize("Allmant")}>
                            <FormSection>
                                {/* <FormControl>
                                    <TextField
                                        value={this.state.item.name}
                                        className="textField"
                                        label={localize("INTERNAL_NAME")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    name: newValue
                                                }
                                            });
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl> */}
                                <FormControl>
                                    <TextField
                                        value={this.state.item.name}
                                        className="textField"
                                        label={localize("Namn")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    name: newValue
                                                }
                                            });
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                            </FormSection>
                        </PivotItem>
                        <PivotItem headerText={localize("QUESTIONS")}>
                            {this.renderQuestions()}
                        </PivotItem>
                    </Pivot>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                        <PrimaryButton type={"submit"} text={localize("Spara")} />
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }

    renderQuestions() {
        return (
            <div>
                {this.state.item.questions.map((item: any, index: number) => {
                    return (
                        <div key={index} className="question-group">
                            <div className="question-group-header">
                                <Label weight="medium">{localize("QUESTION") + " " + (index + 1)}</Label>
                                <UnstyledButton onClick={() => {
                                    let newQuestions = [];

                                    for (let i = 0; i < this.state.item.questions.length; i++) {
                                        if (i !== index) {
                                            newQuestions.push(this.state.item.questions[i]);
                                        }
                                    }

                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            questions: newQuestions
                                        }
                                    });
                                }}>
                                    <Visage2Icon icon="trash" size="small" />
                                </UnstyledButton>
                            </div>
                            <div className="question-group-content">
                                <FormControl>
                                    <TextField
                                        value={item.text}
                                        className="textField"
                                        label={localize("Text")}
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    questions: this.state.item.questions.map((q: any) => {
                                                        if (q.tmpId === item.tmpId) {
                                                            return {
                                                                ...q,
                                                                text: newValue
                                                            }
                                                        } else {
                                                            return q;
                                                        }
                                                    })
                                                }
                                            });
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl label={localize("QUESTION_WEIGHT")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={"100"}
                                        value={item.importance}
                                        max={200}
                                        min={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    questions: this.state.item.questions.map((q: any) => {
                                                        if (q.tmpId === item.tmpId) {
                                                            return {
                                                                ...q,
                                                                importance: value
                                                            }
                                                        } else {
                                                            return q;
                                                        }
                                                    })
                                                }
                                            });
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    )
                })}
                <PrimaryButton text={localize("ADD_QUESTION")} onClick={() => {
                    this.setState({
                        item: {
                            ...this.state.item,
                            questions: [
                                ...this.state.item.questions,
                                {
                                    tmpId: uniqueId(),
                                    text: ""
                                }
                            ]
                        }
                    })
                }} />
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(VectorForm);
