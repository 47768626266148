import api from "src/api/SpintrApi";
import * as actionTypes from "../action-types/project";

export const fetchProjects = () => {
    return {
        payload: api.get("/api/v1/projects/sameinstancelist"),
        type: actionTypes.FETCH_PROJECTS,
    }
}

export const setActiveProjectId = (id: number) => {
    return {
        meta: {
            id
        },
        type: actionTypes.SET_ACTIVE_PROJECT_ID
    }
}

export const setActiveVectorId = (id: number) => {
    return {
        meta: {
            id
        },
        type: actionTypes.SET_ACTIVE_VECTOR_ID
    }
}
