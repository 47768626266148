import { DefaultButton, Dropdown, PrimaryButton, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import api from 'src/api/SpintrApi';
import localize, { fetchLangTags } from 'src/utils/l10n/localize';
import Label from 'src/components/Label/Label';
import SpintrLoader from 'src/components/Loader/Loader';
import logoUrl from './../../style/images/Logo_spintr_RGB_Blue-2.png';
import './SurveyView.scss';
import 'src/components/PhoenixApp/PhoenixApp.scss';
import UnstyledButton from 'src/components/UnstyledButton/UnstyledButton';
import Color from 'src/style/colors/Color';
import getLightOrDarkColorBasedOnColor from 'src/utils/getLightOrDarkColorBasedOnColor';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import getLanguages from 'src/utils/getLanguages';
import FormControl from '../FormControl/FormControl';
import FormSection from '../FormSection/FormSection';

interface IProps { }

interface IState {
    isLoadingInner: boolean;
    isLoading: boolean;
    data?: any;
    saveSuccess: boolean;
    saveError: any[];
    displayError: boolean;
    questionIndex: number;
    locked: boolean;
}

export class SurveyView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const id = window.location.href.split("/").pop();

        const questions = !!id ?
            JSON.parse(localStorage.getItem(id)) :
            [];

        const language = localStorage.getItem("language") ?? 0;

        this.state = {
            isLoadingInner: false,
            isLoading: true,
            saveSuccess: false,
            saveError: [],
            displayError: false,
            questionIndex: 0,
            data: {
                language,
                questions
            },
            locked: false
        }
    }

    componentDidMount(): void {
        this.setFavicon();
        this.setTitle();
        this.fetch();
    }

    fetch(): void {
        const id = window.location.href.split("/").pop();

        api.get("api/v1/surveys/" + id + "?language=" + this.state.data.language).then((response: any) => {
            let data = { ...response.data };

            if (!!data && !!data.instance) {
                data.instance = {
                    ...(data.instance.theme || {}),
                    ...data.instance,
                }

                if (!!data.instance.faviconUrl) {
                    this.setFavicon(data.instance.faviconUrl);
                }
            }

            if (data.hasEndQuestion) {
                data.questions.push({
                    text: data.endQuestionText,
                    isEndQuestion: true,
                    answerText: "",
                });
            }

            if (!!this.state.data &&
                !!this.state.data.questions) {
                for (let q of data.questions) {
                    const foundQuestion = this.state.data.questions.find(question => question.id === q.id);

                    if (!!foundQuestion) {
                        q.answer = foundQuestion.answer;
                        q.comment = foundQuestion.comment;
                    }
                }
            }

            fetchLangTags(() => {
                this.setState({
                    data,
                    isLoading: false,
                    questionIndex: !!data.answeredDate ? (data.questions.length - 1) : this.state.questionIndex
                });

                if (data.instance && data.instance.name) {
                    this.setTitle(localize("CultureIndex") + " - " + data.instance.name);
                }
            }, response.data.language);
        }).catch((error) => {
            this.setState({
                data: {
                    ...this.state.data,
                    language: 3
                }
            }, () => {
                fetchLangTags(() => {
                    this.handleCatch(error);
                }, this.state.data.language);
            });
        });
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
            displayError: true
        });
    };

    send() {
        if (this.state.isLoadingInner) {
            return;
        }

        for (let question of this.state.data.questions) {
            if (question.answer < 1 ||
                question.anser > 5) {
                this.setState({
                    saveError: [localize("SvaraPaAllaFragor")]
                });

                return;
            }
        }

        this.setState({
            isLoadingInner: true
        }, () => {
            api.post("/api/v1/surveys/answer", this.state.data).then((response: any) => {
                this.setState({
                    saveSuccess: true,
                    saveError: [],
                    isLoadingInner: false,
                    isLoading: false,
                    displayError: false
                });
            }).catch(this.handleCatch.bind(this));
        });
    }

    setAnswer(questionId: number, answer: number) {
        this.setState({
            data: {
                ...this.state.data,
                vectors: this.state.data.vectors.map((v: any) => {
                    return {
                        ...v,
                        questions: v.questions.map((q: any) => {
                            return {
                                ...q,
                                answer: q.id === questionId ?
                                    answer :
                                    q.answer
                            }
                        })
                    }
                }),
                questions: this.state.data.questions.map((q: any) => {
                    return {
                        ...q,
                        answer: q.id === questionId ?
                            answer :
                            q.answer
                    }
                })
            },
            questionIndex: this.state.data.questions.length === (this.state.questionIndex + 1) ?
                this.state.questionIndex :
                (this.state.questionIndex + 1)
        });
    }

    renderLanguageSelector() {
        return (
            <Dropdown
                className="language-selector"
                selectedKey={this.state.data.language.toString()}
                onChange={(_e, v) => {
                    localStorage.setItem("language", v.key.toString());

                    this.setState({
                        data: {
                            ...this.state.data,
                            language: Number(v.key),
                        },
                        isLoading: true
                    }, () => {
                        this.fetch();
                    });
                }}
                calloutProps={{
                    calloutMinWidth: 150
                }}
                options={getLanguages().map((language) => {
                    return {
                        ...language,
                        //text: localize("LANGUAGE") + ": " + language.text
                    }
                })}
            />
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="SurveyView-loader">
                    <SpintrLoader />
                </div>
            )
        }

        const currentQuestion = !!this.state.data && !!this.state.data.questions ?
            this.state.data.questions[this.state.questionIndex] :
            undefined;

        const error = this.state.displayError ||
            !this.state.data ||
            this.state.data.isClosed ||
            this.state.data.deleted;

        const percent = Math.round((this.state.questionIndex / (this.state.data.questions.length - 1)) * 100);

        return (
            <div className={"SurveyView" + (this.state.data.isAnonymous ? " anon" : "")}>
                {(this.state.saveSuccess) && (
                    <div className="confetti">
                        {"1,2,3,4,5,6,7,8,9,10,11,12,13".split(",").map((num: any) => {
                            return (
                                <div className="confetti-piece" key={num} />
                            )
                        })}
                    </div>
                )}
                <div className={"SurveyViewHeader"}>
                    <div className="SurveyViewHeader-inner">
                        {this.renderLogo()}
                        <div className="flex-spacer" />
                        {this.renderLanguageSelector()}
                        <Label className="top-right-info">
                            {localize("CultureIndexBySpintr")}
                        </Label>
                    </div>
                    {!!currentQuestion && !this.state.saveSuccess && !this.state.data.answeredDate && !error && this.state.data.isAnonymous && (
                        <div className="anon-banner">
                            <Label fontType="display" weight="medium" size="body-5" color="p-mid-grey-1">
                                {localize("ANSWER_QUESTIONS_ANONYMOUS")}
                            </Label>
                        </div>
                    )}
                </div>
                <div className="main">
                    <div className="SurveyViewMainContent">
                        {!error && !this.state.saveSuccess && !this.state.data.answeredDate && (
                            <div className="progress-meter">
                                <div className={"progress-meter-inner" + (percent === 100 ? " complete" : "")} style={{ width: percent + "%" }} />
                            </div>
                        )}
                        {this.renderContent(currentQuestion)}
                    </div>
                </div>
                {!!currentQuestion && !this.state.saveSuccess && !this.state.data.answeredDate && !error && this.renderFooter(currentQuestion)}
            </div>
        );
    }

    renderFooter(currentQuestion) {
        const canGoForward = currentQuestion.answer !== 0 && this.state.data.questions.length !== (this.state.questionIndex + 1);
        const canGoBack = this.state.questionIndex !== 0;
        const isDone = this.state.questionIndex === (this.state.data.questions.length - 1) && currentQuestion.answer !== 0;
        const instanceColor = this.state.data.instance.primaryColor || "#212647";
        const disabledPrimaryButton = !canGoForward && !isDone;

        return (
            <div className="footer">
                <div className="footer-inner">
                    <DefaultButton disabled={!canGoBack} onClick={() => {
                        if (!canGoBack) {
                            return;
                        }

                        this.setState({
                            questionIndex: this.state.questionIndex - 1
                        });
                    }}>
                        {localize("PREVIOUS")}
                    </DefaultButton>
                    <PrimaryButton style={{
                        backgroundColor: disabledPrimaryButton ? undefined : instanceColor,
                        borderColor: disabledPrimaryButton ? undefined : instanceColor
                    }} disabled={!canGoForward && !isDone} onClick={() => {
                        if (isDone) {
                            this.send();
                        } else {
                            if (!canGoForward) {
                                return;
                            }

                            this.setState({
                                questionIndex: this.state.questionIndex + 1
                            });

                            this.storeAnswers();
                        }
                    }}>
                        {isDone ? localize("SEND_YOUR_ANSWERS") : localize("NEXT")}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    storeAnswers() {
        const id = window.location.href.split("/").pop();

        if (localStorage) {
            localStorage.setItem(id, JSON.stringify(this.state.data.questions));
        }
    }

    renderLogo() {
        let url = logoUrl;
        let color = "#FFFFFF";

        if (this.state.data &&
            this.state.data.instance &&
            this.state.data.instance.useColorHeader) {
            color = this.state.data.instance.useSecondaryColorHeader ?
                this.state.data.instance.headerSecondaryColor :
                this.state.data.instance.headerColor;

            if (!color) {
                color = "#FFFFFF";
            }
        }

        let preferliblyUseLightLogo = getLightOrDarkColorBasedOnColor(color,
            "a",
            "b") === "a";

        if (preferliblyUseLightLogo) {
            url = !!this.state.data.instance.logoUrl ?
                this.state.data.instance.logoUrl :
                this.state.data.instance.colorLogoUrl;
        } else {
            url = !!this.state.data.instance.colorLogoUrl ?
                this.state.data.instance.colorLogoUrl :
                this.state.data.instance.logoUrl;
        }

        if (!url) {
            url = logoUrl;
        }

        return (
            <div className="logo-wrapper">
                <img src={url} alt="Spintr logotyp" />
            </div>
        )
    }

    setFavicon(url?: string) {
        let store = !!url;

        let faviconEl = document.getElementById("favicon");

        if (!url) {
            url = localStorage.getItem("favicon");

            if (!url) {
                return;
            }
        }

        // @ts-ignore
        faviconEl.href = url;

        if (store) {
            localStorage.setItem("favicon", url);
        }
    }

    setTitle(title?: string) {
        let store = !!title;

        if (!title) {
            title = localStorage.getItem("title");

            if (!title) {
                return;
            }
        }

        document.title = title;

        if (store) {
            localStorage.setItem("title", title);
        }
    }

    renderContent(currentQuestion: any) {
        if (this.state.saveSuccess || !!this.state.data.answeredDate) {
            return (
                <div>
                    <div className="survey-header">
                        <div className="survey-header-left">
                            <Label size="h3" weight="bold" fontType="display">{localize("THANK_YOU")}</Label>
                            <Label className="thank-you-description" fontType="display">{localize("THANK_YOU_DESCRIPTION")}</Label>
                        </div>
                    </div>
                </div>
            )
        }

        if (!currentQuestion ||
            this.state.displayError ||
            !this.state.data ||
            this.state.data.isClosed ||
            this.state.data.deleted) {
            const headline = this.state.displayError || !this.state.data ?
                "LOAD_TIMED_OUT_HEADLINE" :
                this.state.data.isClosed ?
                    "SURVEY_CLOSED" :
                    "SURVEY_DELETED";

            const text = this.state.displayError || !this.state.data ?
                "LOAD_TIMED_OUT_MESSAGE" :
                this.state.data.isClosed ?
                    "SURVEY_CLOSED_TEXT" :
                    "SURVEY_DELETED_TEXT";
            return (
                <div>
                    <div className="survey-header">
                        <div className="survey-header-left">
                            <Label size="h3" weight="bold" fontType="display">{localize(headline)}</Label>
                            <Label className="thank-you-description" fontType="display">{localize(text)}</Label>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="MainContentInner" key={this.state.questionIndex}>
                <div className="survey-header" key={this.state.questionIndex}>
                    <div className="survey-header-left">
                        <Label size="body-2" className="question-count" fontType="display" color="p-mid-grey-1" weight="medium">{localize("QUESTION") + " " + (this.state.questionIndex + 1) + "/" + this.state.data.questions.length}</Label>
                        <Label size="h3" weight="bold" fontType="display">{currentQuestion.text}</Label>
                    </div>
                </div>
                {currentQuestion.isEndQuestion ? <>
                    {this.renderEndQuestion(currentQuestion)}
                </> : <>
                    {this.renderOptions(currentQuestion)}
                    {this.renderComment(currentQuestion)}
                </>}
            </div>
        )
    }

    getOptions() {
        const options = [{
            id: 1,
            text: localize("STRONGLY_DISAGREE")
        }, {
            id: 2,
            text: localize("DISAGREE")
        }, {
            id: 3,
            text: localize("NEITHER_AGREE_DISAGREE")
        }, {
            id: 4,
            text: localize("AGREE")
        }, {
            id: 5,
            text: localize("STRONGLY_AGREE")
        }];

        return options;
    }

    renderOptions(currentQuestion: any) {
        const options = this.getOptions();

        const instanceColor = this.state.data.instance.primaryColor || "#212647";
        const instanceColorLight = Color.fromHex(instanceColor).customLighten(40).toString("hex");

        let useLightTextColor = getLightOrDarkColorBasedOnColor(instanceColor,
            "a",
            "b") === "a";

        return (
            <div className="SurveyView-options">
                {
                    options.map((o: any) => {
                        const isSelected = currentQuestion.answer === o.id;

                        return (
                            <UnstyledButton
                                disabled={this.state.isLoadingInner}
                                ariaLabel={localize("SELECT_OPTION")}
                                className={"SurveyView-option" + (isSelected ? " is-selected" : "")}
                                style={isSelected ? {
                                    background: "linear-gradient(90deg, " + instanceColorLight + " 0%, " + instanceColor + " 100%)"
                                } : undefined}
                                onClick={() => {
                                    if (this.state.locked) {
                                        return;
                                    }

                                    const newAnswer = isSelected ? 0 : o.id;

                                    this.setState({
                                        data: {
                                            ...this.state.data,
                                            questions: this.state.data.questions.map((question: any) => {
                                                if (question.id === currentQuestion.id) {
                                                    return {
                                                        ...question,
                                                        answer: newAnswer
                                                    }
                                                } else {
                                                    return question;
                                                }
                                            })
                                        }
                                    });
                                }}
                                key={o.id}>
                                <div>
                                    <div
                                        className="selector-outer">
                                        <div className="selector-inner" style={{
                                            backgroundColor: instanceColor
                                        }} />
                                    </div>
                                    <Label fontType="display" weight="medium" size="h4" color={isSelected && useLightTextColor ? "p-white" : "p-dark-grey-1"}>
                                        {o.text}
                                    </Label>
                                </div>
                            </UnstyledButton>
                        )
                    })
                }
            </div>
        )
    }

    renderComment(currentQuestion: any) {
        const hasAnswer = currentQuestion.answer !== 0;

        if (!hasAnswer) {
            return null;
        }

        return (
            <div className="survey-comment-wrapper">
                <Visage2Icon icon="message" size="small" />
                <TextField
                    value={currentQuestion.comment}
                    className="textField"
                    // label={localize("COMMENT") + " (" + localize("OPTIONAL") + ")"}
                    resizable={false}
                    multiline={currentQuestion.comment.length > 30}
                    autoAdjustHeight={currentQuestion.comment.length > 30}
                    borderless
                    placeholder={localize("WRITE_A_COMMENT") + " (" + localize("OPTIONAL") + ")"}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                questions: this.state.data.questions.map((question: any) => {
                                    if (question.id === currentQuestion.id) {
                                        return {
                                            ...question,
                                            comment: newValue
                                        }
                                    } else {
                                        return question;
                                    }
                                })
                            }
                        });
                    }}
                />
            </div>
        )
    }

    renderEndQuestion(currentQuestion: any) {
        return (
            <FormSection className="SurveyView-options">
                <FormControl>
                    <TextField
                        value={currentQuestion.answer}
                        resizable={false}
                        multiline={currentQuestion.answerText.length > 30}
                        autoAdjustHeight={currentQuestion.answerText.length > 30}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    questions: this.state.data.questions.map((question: any) => {
                                        if (question.id === currentQuestion.id) {
                                            return {
                                                ...question,
                                                answerText: newValue
                                            }
                                        } else {
                                            return question;
                                        }
                                    })
                                }
                            });
                        }}
                    />
                </FormControl>
            </FormSection>
        )
    }
}
