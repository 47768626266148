import React, { Component } from 'react';
import { Route } from 'react-router';
import './PhoenixApp.scss';
import { Redirect, Switch } from 'react-router-dom';
import Home from 'src/components/Home/Home';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { connect, MapStateToProps } from 'react-redux';
import { IApplicationState } from 'src/reducer';
import Loader from 'src/components/Loader/Loader';
import { fetchCurrentUser } from 'src/actions/user';
import { fetchCurrentInstance } from 'src/actions/instance';
import InstanceList from 'src/components/InstanceList/InstanceList';
import InstanceSettings from 'src/components/InstanceSettings/InstanceSettings';
import Plan from 'src/components/Plan/Plan';
import Coworkers from 'src/components/Coworkers/Coworkers';
import Surveys from 'src/components/Surveys/Surveys';
import { setConfirmPopup } from 'src/actions/popup';
import CustomDialog from 'src/components/CustomDialog/CustomDialog';
import { IConfirmPopupState } from 'src/reducers/popup';
import AdvancedSettings from 'src/components/AdvancedSettings/AdvancedSettings';
import TargetGroups from 'src/components/TargetGroups/TargetGroups';
import { fetchLangTags } from 'src/utils/l10n';
import localize, { getLanguageTagsFromStorage } from 'src/utils/l10n/localize';
import RadarView from 'src/components/RadarView/RadarView';
import DetailsView from 'src/components/DetailsView/DetailsView';
import api from 'src/api/SpintrApi';
import { fetchProjects } from 'src/actions/project';
import AdminImpView from '../AdminImpView/AdminImpView';
import AdminHomeView from '../AdminHomeView/AdminHomeView';
import CenteredContent from '../CenteredContent/CenteredContent';
import ProjectsList from '../ProjectsList/ProjectsList';
import GotoView from '../GotoView/GotoView';

interface IProps {
    currentUser?: any;
    hasFetchedCurrentUser: boolean;
    isLoadingCurrentUser: boolean;
    currentInstance?: any;
    hasFetchedCurrentInstance: boolean;
    isLoadingCurrentInstance: boolean;
    projects?: any[];
    hasFetchedProjects: boolean;
    isLoadingProjects: boolean;
    dispatch?: (Action) => void;
    confirmPopup: IConfirmPopupState;
}

interface IState {
    hasFetchedLanguageTags?: boolean;
}

class PhoenixApp extends Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            hasFetchedLanguageTags: getLanguageTagsFromStorage(),
        }
    }

    setFavicon(url?: string) {
        let store = !!url;

        let faviconEl = document.getElementById("favicon");

        if (!url) {
            url = localStorage.getItem("favicon");

            if (!url) {
                return;
            }
        }

        // @ts-ignore
        faviconEl.href = url;

        if (store) {
            localStorage.setItem("favicon", url);
        }
    }

    setTitle(title?: string) {
        let store = !!title;

        if (!title) {
            title = localStorage.getItem("title");

            if (!title) {
                return;
            }
        }

        document.title = title;

        if (store) {
            localStorage.setItem("title", title);
        }
    }

    componentDidUpdate() {
        if (!!this.props.currentInstance &&
            this.props.currentInstance.faviconUrl) {
            this.setFavicon(this.props.currentInstance.faviconUrl);
        }

        if (!!this.props.currentInstance) {
            this.setTitle(localize("CultureIndex") + " - " + this.props.currentInstance.name);
        }
    }

    componentDidMount() {
        this.setTitle();
        this.setFavicon();
        this.doInitialFetches();
    }

    doInitialFetches() {
        if (!this.props.hasFetchedCurrentUser) {
            this.props.dispatch(fetchCurrentUser());
        }

        if (!this.props.hasFetchedCurrentInstance) {
            this.props.dispatch(fetchCurrentInstance());
        }

        if (!this.props.hasFetchedProjects) {
            this.props.dispatch(fetchProjects());
        }

        fetchLangTags(() => {
            this.setState({
                hasFetchedLanguageTags: true,
            });
        });

        api.post("/api/v1/users/activity").then(() => { }).catch(() => { });
    }

    render() {
        const displayLoader = !this.props.currentUser ||
            !this.props.currentInstance ||
            !this.state.hasFetchedLanguageTags ||
            !this.props.hasFetchedProjects;

        if (displayLoader) {
            return (
                <CenteredContent>
                    <Loader />
                </CenteredContent>
            )
        }

        return (
            <div className="app">
                <Sidebar />
                <div className="app-content">
                    <div className="main">
                        <div className="main-content">
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/admin' component={AdminHomeView} />
                                <Route exact path='/admin/instances' component={InstanceList} />
                                <Route exact path='/admin/projects' component={ProjectsList} />
                                <Route exact path='/admin/surveys' component={Surveys} />
                                <Route exact path='/admin/users' render={() => <Coworkers adminMode />} />
                                <Route exact path='/admin/advanced-settings' component={AdvancedSettings} />
                                <Route exact path='/settings' component={InstanceSettings} />
                                <Route exact path='/plan' component={Plan} />
                                <Route exact path='/users' component={Coworkers} />
                                <Route exact path='/users/:id' component={Coworkers} />
                                <Route exact path='/surveys' component={Surveys} />
                                <Route exact path='/target-groups' component={TargetGroups} />
                                <Route exact path='/radar' component={RadarView} />
                                <Route exact path='/vector-details' component={DetailsView} />
                                <Route exact path='/imp/:id' component={AdminImpView} />
                                <Route exact path='/goto/:type/:id/:instanceId' component={GotoView} />
                                <Route exact path='/goto/:type/:id' component={GotoView} />
                                <Redirect to='/' />
                            </Switch>
                        </div>
                    </div>
                </div>
                {
                    this.props.confirmPopup.isOpen && (
                        <CustomDialog
                            show={this.props.confirmPopup.isOpen}
                            title={this.props.confirmPopup.title}
                            message={this.props.confirmPopup.message}
                            onDismiss={() => {
                                this.props.confirmPopup.onDismiss?.();
                                this.props.dispatch(setConfirmPopup({ isOpen: false }));
                            }}
                            onConfirm={() => {
                                this.props.confirmPopup.onConfirm();
                                this.props.dispatch(setConfirmPopup({ isOpen: false }));
                            }}
                        >
                            {this.props.confirmPopup.content}
                        </CustomDialog>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<IProps, IState, IApplicationState> = (state) => {
    return {
        currentUser: state.user.currentUser,
        hasFetchedCurrentUser: state.user.hasFetched,
        isLoadingCurrentUser: state.user.isLoading,
        currentInstance: state.instance.currentInstance,
        hasFetchedCurrentInstance: state.instance.hasFetched,
        isLoadingCurrentInstance: state.instance.isLoading,
        projects: state.project.projects,
        hasFetchedProjects: state.project.hasFetched,
        isLoadingProjects: state.project.isLoading,
        confirmPopup: state.popup.confirmPopup
    };
};

export default connect(mapStateToProps)(PhoenixApp);
