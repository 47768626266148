import React, { Component } from "react";

import "./HeaderContextSearch.scss";
import SpintrSearch from "../SpintrList/SpintrSearch";
import { IApplicationState } from "src/reducer";
import { connect } from "react-redux";
import { setContextSearch } from "src/actions/ui";

interface IProps {
    searchKey: string;
    dispatch?: any;
    small?: boolean;
    placeholder?: string;
}

interface IState {
    value: string;
}

class HeaderContextSearch extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            value: ""
        }
    }

    componentDidMount(): void {
        this.props.dispatch(setContextSearch(this.props.searchKey, ""));
    }

    render() {
        return (
            <SpintrSearch
                value={this.state.value}
                small={this.props.small}
                placeholder={this.props.placeholder}
                onChange={(event: React.ChangeEvent, value: string) => {
                    this.setState({
                        value
                    }, () => {
                        this.props.dispatch(setContextSearch(this.props.searchKey, value));
                    });
                }} />
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
});

export default connect(mapStateToProps)(HeaderContextSearch);
