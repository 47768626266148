const element: HTMLElement = document.createElement("div");

function decodeHtmlEntities(html: string,
    allowLinebreaks: boolean = true,
    replaceLineBreaksWithSpace: boolean = false,
    replaceDoubleSpace: boolean = false): string {
    if (!html || typeof html !== "string") {
        return html;
    }

    // Clean the string from intrusive code
    html = html.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');

    if (allowLinebreaks) {
        html = html.replace(/<br(\s)?(\/)?>/gmi, "&lt;br /&gt"); // allow linebreaks
    } else if (replaceLineBreaksWithSpace) {
        html = html.replace(/<br(\s)?(\/)?>/gmi, " ");
    } else {
        html = html.replace(/<br(\s)?(\/)?>/gmi, "");
    }

    html = html.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');

    element.innerHTML = html;
    html = element.textContent || "";
    element.textContent = "";

    if (replaceDoubleSpace) {
        html = html.replace(/\s\s+/g, ' ');
    }

    return html;
}

export default decodeHtmlEntities;