import React, { Component } from "react";
import "./AdminHomeView.scss";
import Page from "../Page/Page";
import { IApplicationState } from "src/reducer";
import { connect } from "react-redux";
import FormPopup from "../FormPopup/FormPopup";
import InstanceForm from "../InstanceForm/InstanceForm";
import { IPopupFormState } from "src/reducers/ui";
import { PrimaryButton } from "@fluentui/react";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import { localize } from "src/utils/l10n";
import { setAdminMode, setPopupForm } from "src/actions/ui";
import HeaderMainSearch from "../HeaderMainSearch/HeaderMainSearch";
import { ActionMenu } from "../ActionMenu";
import { listActionMenuWidth } from "src/utils/style";
import { setConfirmPopup } from "src/actions/popup";
import SpintrList from "../SpintrList/SpintrList";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import api from "src/api/SpintrApi";
import { SpintrTypes } from "src/typings";
import { AxiosResponse } from "axios";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import Label from "../Label/Label";
import HeaderContextSearch from "../HeaderContextSearch/HeaderContextSearch";
import SpintrLoader from "../Loader/Loader";
import { Line } from "react-chartjs-2";
import * as Style from 'src/utils/style';
import { capitalizeFirstLetter } from "src/utils";

interface IProps extends RouteComponentProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    history: any;
    popupFormState?: IPopupFormState;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
    data?: any;
}

class AdminHomeView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false
        };
    }

    componentDidMount(): void {
        api.get("/api/v1/surveys/statistics").then((response: AxiosResponse) => {
            this.setState({
                data: response.data,
                isLoading: false
            });
        }).catch(() => {});
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderMainSearch />
                </div>
                <div className="header-right">
                    {/* <DefaultButton onClick={() => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Project",
                            id: this.props.activeProjectId,
                            title: localize("EDIT_PROJECT"),
                            isDisplayed: true,
                            hideHeader: true
                        }));
                    }}>
                        {localize("EDIT_PROJECT")}
                    </DefaultButton> */}
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Instance",
                                id: 0,
                                title: localize("CREATE_CUSTOMER"),
                                isDisplayed: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("CREATE_CUSTOMER")}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    getSurveyChartData() {
        return {
            labels: [
                ...this.state.data.surveys.map((s) =>
                    capitalizeFirstLetter(moment(s.key).format("ddd"))
                ),
            ],
            datasets: [
                {
                    borderColor: Style.getHexFromSpintrColor("p-green-2"),
                    tension: 0.5,
                    data: this.state.data.surveys.map((s) => s.value),
                    pointBorderWidth: 0,
                    pointBorderColor: 'transparent',
                    pointRadius: 6,
                    pointHoverRadius: 7,
                    pointBackgroundColor: this.state.data.surveys.map((s) => "transparent"),
                    gradient: {
                      borderColor: {
                        axis: 'x',
                        colors: {
                          0: '#00A57815',
                          100: '#00A578',
                        },
                      },
                    },
                },
            ],
        }
    }

    getSurveyAnswersChartData() {
        return {
            labels: [
                ...this.state.data.surveyAnswers.map((s) =>
                    capitalizeFirstLetter(moment(s.key).format("ddd"))
                ),
            ],
            datasets: [
                {
                    borderColor: Style.getHexFromSpintrColor("p-green-2"),
                    tension: 0.5,
                    data: this.state.data.surveyAnswers.map((s) => s.value),
                    pointBorderWidth: 0,
                    pointBorderColor: 'transparent',
                    pointRadius: 6,
                    pointHoverRadius: 7,
                    pointBackgroundColor: this.state.data.surveyAnswers.map((s) => "transparent"),
                    gradient: {
                      borderColor: {
                        axis: 'x',
                        colors: {
                          0: '#00A57815',
                          100: '#00A578',
                        },
                      },
                    },
                },
            ],
        }
    }

    getChartOptions() {
        return {
            responsive: true,
            scales: {
                y: {
                    ticks: {
                        count: 5,
                        color: Style.getHexFromSpintrColor("p-mid-grey-1"),
                        font: {
                            size: 12,
                            family: 'EloquiaDisplay Regular',
                        },
                        callback: (scale: any) => {
                            return Math.round(scale)
                        },
                        padding: 8,
                        autoSkip: true,
                    },
                    beginAtZero: true,
                    grid: {
                        display: true,
                        color: Style.getHexFromSpintrColor("p-border-color"),
                        lineWidth: 0.5,
                        drawTicks: false,
                        borderColor: Style.getHexFromSpintrColor("p-border-color"),
                        borderWidth: 0.5
                    },
                },
                x: {
                    ticks: {
                        color: Style.getHexFromSpintrColor("p-mid-grey-1"),
                        font: {
                            size: 12,
                            family: 'EloquiaDisplay Regular',
                        },
                        padding: 8
                    },
                    grid: {
                        display: true,
                        color: Style.getHexFromSpintrColor("p-border-color"),
                        lineWidth: 0.5,
                        drawTicks: false,
                        tickLength: 0,
                        borderColor: Style.getHexFromSpintrColor("p-border-color"),
                        borderWidth: 0.5
                    },
                },
            },
            plugins: {
                tooltip: {
                    enabled: true,
                    titleFont: {
                        size: 12,
                        family: 'EloquiaDisplay Regular',
                    },
                    bodyFont: {
                        size: 12,
                        family: 'EloquiaDisplay Regular',
                    },
                    displayColors: false,
                    callbacks: {
                        label: (tooltipItem) => {
                            let title = tooltipItem.label;

                            if (!title) {
                                return null;
                            }
        
                            return title + ": " + tooltipItem.formattedValue;
                        },
                        title: () => "",
                    },
                },
                legend: {
                    display: false,
                }
            },
          };
    }

    renderSurveysChart() {
        if (this.state.isLoading) {
            return (
                <div className="chart-loader">
                    <SpintrLoader />
                </div>
            )
        }

        return (
            <div>
                <div className="content-header vertical">
                    <Label fontType="display" size="h4" weight="medium">
                        {localize("SURVEYS_PER_DAY")}
                    </Label>
                    <Label fontType="display" weight="medium" size="body-2" color="p-white">
                        Spacer
                    </Label>
                </div>
                <div className="chart-wrapper">
                    <Line
                        // @ts-ignore
                        //ref={this.chartRef}
                        // @ts-ignore
                        data={this.getSurveyChartData()}
                        // @ts-ignore
                        options={this.getChartOptions()}
                    />
                </div>
            </div>
        )
    }

    renderSurveyAnswersChart() {
        if (this.state.isLoading) {
            return (
                <div className="chart-loader">
                    <SpintrLoader />
                </div>
            )
        }

        return (
            <div>
                <div className="content-header vertical">
                    <Label fontType="display" size="h4" weight="medium">
                        {localize("SURVEY_RESPONSES_PER_DAY")}
                    </Label>
                    <Label fontType="display" weight="medium" size="body-2" color="p-mid-grey-1">
                        {localize("SURVEY_RESPONSES_PER_DAY_DESC")}
                    </Label>
                </div>
                <div className="chart-wrapper">
                    <Line
                        // @ts-ignore
                        //ref={this.chartRef}
                        // @ts-ignore
                        data={this.getSurveyAnswersChartData()}
                        // @ts-ignore
                        options={this.getChartOptions()}
                    />
                </div>
            </div>
        )
    }

    renderList() {
        return (
            <div>
                <div className="content-header">
                    <Label fontType="display" size="h4" weight="medium">
                        {localize("LAST_OPENED_CUSTOMERS")}
                    </Label>
                    <HeaderContextSearch searchKey="InstanceList" small placeholder={localize("SEARCH_PLACEHOLDER_CUSTOMERS")} />
                </div>
                <SpintrList
                    ref={this.listRef}
                    searchKey="InstanceList"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/instances", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        items: items,
                                        totalCount: response.data.totalCount
                                    });
                                }).catch(() => { });
                        });
                    }}
                    data={{
                        data: this.state.items,
                        totalCount: this.state.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                return (
                                    <UnstyledButton
                                        className={"linkFGColor list-icon-button"}
                                        style={{
                                            ...(this.props.currentInstance.id === item.id ? { fontWeight: 600 } : {})
                                        }}
                                        onClick={() => {
                                            if (this.props.currentInstance.id === item.id) {
                                                this.props.dispatch(setAdminMode(false));
                                                this.props.history.push("/");
                                                return;
                                            }

                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("SWITCH_INSTANCE"),
                                                message: localize("SWITCH_INSTANCE_MSG").replace("{0}", item.name),
                                                onConfirm: () => {
                                                    this.props.history.push("/imp/" + item.id);
                                                }
                                            }));
                                        }}>
                                        <span style={{
                                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                        }}>{item.name}</span>
                                        {this.props.currentInstance.id === item.id && (
                                            <Visage2Icon icon="user" type="bold" color="p-link-color" />
                                        )}
                                    </UnstyledButton>
                                );
                            },
                        },
                        {
                            name: localize("PROJECTS"),
                            fieldName: "projectCount",
                        },
                        {
                            name: localize("INDUSTRY"),
                            fieldName: "industry",
                            minWidth: 200
                        },
                        {
                            name: localize("USERS"),
                            fieldName: "userCount",
                        },
                        {
                            name: localize("Status"),
                            fieldName: "deleted",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [{
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.props.dispatch(setPopupForm({
                                                            popupFormKey: "Instance",
                                                            id: item.id,
                                                            title: localize("EDIT_CUSTOMER"),
                                                            isDisplayed: true
                                                        }));
                                                    },
                                                }, {
                                                    text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                                    onClick: () => this.toggleDelete(item),
                                                }],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    isDescending={true}
                    orderByColumn="lastVisited"
                ></SpintrList>
            </div>
        )
    }

    toggleDelete(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/instances/toggledelete/" + item.id).then(() => {
                        this.setState({
                            items: this.state.items.map((i: any) => {
                                return {
                                    ...i,
                                    deleted: i.id === item.id ?
                                        !i.deleted :
                                        i.deleted
                                }
                            })
                        });
                    });
                },
            })
        );
    }

    renderContent() {
        return (
            <div className="boxed-content admin-home">
                <div className="row">
                    <div className="column">
                        {this.renderSurveysChart()}
                    </div>
                    <div className="column">
                        {this.renderSurveyAnswersChart()}
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        {this.renderList()}
                    </div>
                </div>
            </div>
        )
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="Instance"
                onClose={() => {
                    //@ts-ignore
                    //this.listRef.current.reFetch();
                }}>
                <InstanceForm />
            </FormPopup>
        )
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)} noContentStyle pageClassName="AdminHomeView">
                {this.renderContent()}
                {this.renderFormPopup()}
            </Page>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Instance")
});

export default withRouter(connect(mapStateToProps)(AdminHomeView));
