import { Callout } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import "./HeaderMainSearch.scss";
import SpintrSearch from "../SpintrList/SpintrSearch";
import { IApplicationState } from "src/reducer";
import { connect } from "react-redux";
import api from "src/api/SpintrApi";
import { AxiosResponse } from "axios";
import SpintrUser from "../SpintrUser/SpintrUser";
import { Link } from "react-router-dom";

interface IProps {
    dispatch?: any;
}

interface IState {
    value: string;
    categories: any[];
    isLoading: boolean;
    showCallout: boolean;
}

class HeaderMainSearch extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            value: "",
            categories: [],
            isLoading: false,
            showCallout: false
        }
    }

    transformResponse(items: any) {
        const categories = [];

        for (let item of items) {
            const category = categories.find(x => x.id === item.type);

            if (category) {
                category.items.push(item);
            } else {
                categories.push({
                    id: item.type,
                    name: localize("ITEM_TYPE_" + item.type),
                    items: [item]
                });
            }
        }

        return categories;
    }

    fetch() {
        if (!this.state.value) {
            this.setState({
                categories: [],
                showCallout: false
            });

            return;
        }

        api.get("/api/v1/search", {
            params: {
                query: this.state.value
            },
        }).then((response: AxiosResponse) => {
            this.setState({
                categories: this.transformResponse(response.data),
                showCallout: true
            });
        }).catch(() => {});
    }

    render() {
        return (
            <div className="HeaderMainSearch">
                <SpintrSearch
                    id="HeaderMainSearch"
                    value={this.state.value}
                    onFocus={() => {
                        if (this.state.value) {
                            this.setState({
                                showCallout: true
                            });
                        }
                    }}
                    placeholder={localize("SEARCH_PLACEHOLDER_ADMIN")}
                    onClear={() => {
                        this.setState({
                            showCallout: false
                        });
                    }}
                    onChange={(event: React.ChangeEvent, value: string) => {
                        this.setState({
                            value
                        }, this.fetch.bind(this));
                    }} />
                {this.state.showCallout && this.renderPopout()}
            </div>
        )
    }

    renderPopout() {
        return (
            <Callout
                className="HeaderMainSearch-callout"
                isBeakVisible={false}
                role="dialog"
                gapSpace={0}
                target={"#HeaderMainSearch"}
                onDismiss={() => {
                    this.setState({
                        showCallout: false
                    });
                }}>
                {this.state.categories.length === 0 && (
                    <div className="no-results">
                        <Label color="p-mid-grey-1" fontType="display" size="body-2">{localize("NO_SEARCH_RESULTS")}</Label>
                    </div>
                )}
                {this.state.categories.length > 0 && (
                    <div>
                        {this.state.categories.map((c: any) => {
                            return (
                                <div key={c.id} className="HeaderMainSearch-category">
                                    <Label
                                        fontType="display"
                                        uppercase
                                        size="body-3"
                                        weight="medium">
                                        {c.name}
                                    </Label>
                                    <div className="category-items">
                                        {c.items.map((i: any) => {
                                            return (
                                                <Link
                                                    to={"/goto/" + i.type + "/" + i.id + "/" + i.instanceId}
                                                    key={i.id}
                                                    className="item">
                                                    <SpintrUser
                                                        big
                                                        imageUrl={i.imageUrl}
                                                        name={i.name}
                                                        subText={i.subtext}
                                                    />
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </Callout>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
});

export default connect(mapStateToProps)(HeaderMainSearch);
