import * as actionTypes from "../action-types/ui";

export interface ISearchContext {
    key: string;
    value: string;
}

export type PopupFormKey = "Instance" | "InstanceSidebar" | "User" | "SuperInstance" | "Survey" | "TargetGroup" | "Vector" | "ProjectType" | "ImportUsers" | "Goal" | "Action" | "SurveyReminder" | "Project" | "StringForm" | "Profile";

export interface IPopupFormState {
    popupFormKey: PopupFormKey;
    id?: number;
    title?: string;
    isDisplayed?: boolean;
    isLoading?: boolean;
    saveError?: any[];
    hideCloseButton?: boolean;
    hideHeader?: boolean;
    chooseInstance?: boolean;
    instanceId?: number;
    remainingUsers?: number;
}

export interface IUIState {
    contexts: ISearchContext[];
    popupForms: IPopupFormState[];
    adminMode: boolean;
}

const initialState: IUIState = {
    contexts: [],
    popupForms: [],
    adminMode: window.location.pathname === "/" || window.location.pathname.indexOf("/admin") === 0
};

const uiReducer = (state: IUIState = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONTEXT_SEARCH:
            if (state.contexts.find(c => c.key === action.meta.key)) {
                return {
                    ...state,
                    contexts: state.contexts.map((c: ISearchContext) => {
                        if (c.key === action.meta.key) {
                            return {
                                ...c,
                                value: action.meta.value
                            }
                        }

                        return c;
                    })
                }
            }

            return {
                ...state,
                contexts: [
                    ...state.contexts,
                    {
                        key: action.meta.key,
                        value: action.meta.value
                    }
                ]
            }
        case actionTypes.SET_POPUP_FORM:
            if (state.popupForms.find(c => c.popupFormKey === action.meta.item.popupFormKey)) {
                return {
                    ...state,
                    popupForms: state.popupForms.map((c: IPopupFormState) => {
                        if (c.popupFormKey === action.meta.item.popupFormKey) {
                            return {
                                ...c,
                                ...action.meta.item
                            }
                        }

                        return c;
                    })
                }
            }

            return {
                ...state,
                popupForms: [
                    ...state.popupForms,
                    action.meta.item
                ]
            }
            case actionTypes.SET_ADMIN_MODE:
                return {
                    ...state,
                    adminMode: action.meta.value
                }
        default:
            return state;
    }
};

export default uiReducer;
