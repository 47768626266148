import { setConfirmPopup } from "src/actions/popup";
import isAnythingDirty from "./isAnythingDirty";
import { localize } from "./l10n";

function onFormClose(dispatch, closeFn) {
    if (isAnythingDirty()) {
        dispatch(setConfirmPopup({
            isOpen: true,
            message: localize("UnsavedChangesWarning"),
            onConfirm: closeFn
        }));
    } else {
        closeFn();
    }
}

export default onFormClose;
