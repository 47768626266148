import React from "react";
import "./StatusBall.scss";

interface IProps {
    status: number;
}

const StatusBall = (props: IProps) => {
    const classNames = ["StatusBall"];

    classNames.push("status-" + props.status);

    return (
        <div className={classNames.join(" ")} />
    )
}

export default StatusBall;
