import { ITheme } from '@fluentui/react';
import React, { Component, ReactNode } from "react";
//import { defaults } from 'react-chartjs-2';
import { connect } from "react-redux";
import Color from 'src/style/colors/Color';
import ThemeContext, { produceTheme } from 'src/style/ThemeContext';
import * as Style from 'src/utils/style';
import { IApplicationState } from 'src/reducer';

interface ITenantStyle {
    css: string;
    theme: ITheme;
}

interface IProps {
    currentInstance: any;
}

interface IState {
    style: ITenantStyle;
}

class SpintrCustomStyleProvider extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            style: this.getStyleString(props),
        };
    }

    public getStyleString(props: IProps): ITenantStyle {
        const primaryColor = props.currentInstance.color;
        let accentColor = props.currentInstance.accentColor;
        let headerColor = props.currentInstance.headerColor;
        let lightPrimaryColor = Color.fromHex(primaryColor).customLighten().toString("hex");

        if (!accentColor || accentColor.length === 0) {
            accentColor = "#FFFFFF";
        }

        if (!headerColor || headerColor.length === 0) {
            headerColor = "#FFFFFF";
        }

        const css = `
            ${this.getColorStyle(primaryColor, "primary")}
            ${this.getColorStyle(accentColor, "accent")}
            ${this.getColorStyle(lightPrimaryColor, "lightPrimary")}
            ${this.getCustomSidebarCss()}
            ${this.getCustomFontHeadlineCss()}
            ${this.getCommandBarAddButtonStyle(primaryColor)}
        `;

        const theme = produceTheme(primaryColor);

        return { css, theme };
    }

    getCommandBarAddButtonStyle(primaryColor) {
        return `
            .ms-OverflowSet-item > .commandBarAddButton {
                background-color: ${primaryColor};
                color: #FFFFFF;
            }
            .ms-OverflowSet-item > button:not(.commandBarAddButton) {
                border: 1px solid ${Style.getHexFromSpintrColor("p-border-color")};
                color: ${Style.getHexFromSpintrColor("p-blue-1")};
            }
            .ms-OverflowSet-item > button:not(.commandBarAddButton) i {
                color: ${Style.getHexFromSpintrColor("p-blue-1")} !important;
            }
            .ms-OverflowSet-item > .commandBarAddButton i { color: #FFFFFF; }
            .ms-OverflowSet-item > .commandBarAddButton:hover {
                background-color: ${Color.fromHex(primaryColor).lighten(12.5).toString("hex")} !important;
                color: #FFFFFF !important;
            }
            .ms-OverflowSet-item > .commandBarAddButton:hover i { color: #FFFFFF !important; }
        `;
    }

    getColorStyle(color: string, name: string): string {
        const css = `
            .${name}BGColor { background-color: ${color} !important; }
            .${name}BGColorHover:hover { background-color: ${color} !important; }
            .${name}FGColor { color: ${color} !important; }
            .${name}FGColorHover:hover { color: ${color} !important; }
            .${name}IconColor svg path { fill: ${color} !important; }
            .${name}SubmenuIconColor ul li  i { color: ${color} !important; }
            .${name}IconColorHover:hover {color: ${color} !important }
            .${name}IconColorHover:hover div i {color: ${color} !important }
            .${name}IconColorHover:hover svg path { fill: ${color} !important }
            .${name}BorderColor { border-color: ${color} !important; }
            .${name}BorderColorHover:hover { border-color: ${color} !important; }
            .${name}FGColorHover:hover { color: ${color} !important; }
            .${name}BorderBottomColor { border-bottom-color: ${color} !important; }
            .${name}BorderBottomColorHover:hover { border-bottom-color: ${color} !important; }
            .${name}BorderLeft { border-left: 2px solid ${color} !important; }
            .${name}BorderLeftColor { border-left-color: ${color} !important; }
            .${name}BGColorInitials .ms-Persona-initials { background-color: ${color} !important; }
            .${name}VisageIconColorFill svg * { fill: ${color} !important; }
            .${name}VisageIconColorStroke svg * { fill: ${color} !important; }
        `;

        return css;
    }

    getCustomFontHeadlineCss() {
        const fontUrl = this.props.currentInstance.fontUrl;

        if (fontUrl) {
            return `
                @font-face {
                    font-family: SpintrCustomHeadlineFont;
                    src: url('${fontUrl}');
                }

                h1:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h2:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h3:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h4:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h5:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h6:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h1:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h2:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h3:not(.ignore-custom-font):not(.ignore-custom-font *),
                .fs-h4:not(.ignore-custom-font):not(.ignore-custom-font *),
                .fs-h5:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h6:not(.ignore-custom-font):not(.ignore-custom-font *) { font-family: SpintrCustomHeadlineFont, EloquiaDisplay Regular, Helvetica, Arial, sans-serif !important; }
            `;
        }

        return "";
    }

    getCustomSidebarCss() {
        if (!this.props.currentInstance.useColorHeader) {
            return '';
        }

        const accentColor = this.props.currentInstance.accentColor as string;

        return `
          .SpintrSidebarContainer.minimized .tabs ul .primaryFGColor { color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryBorderBottomColor { border-bottom-color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryFGColorHover:hover { color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryBorderBottomColorHover:hover { border-bottom-color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryIconColorHover:hover i { color: ${accentColor} !important; }
        `
    }

    public componentDidUpdate(lastProps: Readonly<IProps>) {
        let updateStyle: boolean = false;

        if (lastProps.currentInstance.instanceId !== this.props.currentInstance.instanceId ||
            lastProps.currentInstance.color !== this.props.currentInstance.color ||
            lastProps.currentInstance.logoUrl !== this.props.currentInstance.logoUrl ||
            lastProps.currentInstance.fontUrl !== this.props.currentInstance.fontUrl ||
            lastProps.currentInstance.faviconUrl !== this.props.currentInstance.faviconUrl ||
            lastProps.currentInstance.bodyFontUrl !== this.props.currentInstance.bodyFontUrl ||
            lastProps.currentInstance.menuFontUrl !== this.props.currentInstance.menuFontUrl ||
            lastProps.currentInstance.useUppercaseMainMenu !== this.props.currentInstance.useUppercaseMainMenu ||
            lastProps.currentInstance.useColorHeader !== this.props.currentInstance.useColorHeader ||
            lastProps.currentInstance.searchFieldBackgroundColor !== this.props.currentInstance.searchFieldBackgroundColor ||
            lastProps.currentInstance.headerColor !== this.props.currentInstance.headerColor ||
            lastProps.currentInstance.accentColor !== this.props.currentInstance.accentColor ||
            lastProps.currentInstance.backgroundColor !== this.props.currentInstance.backgroundColor ||
            lastProps.currentInstance.backgroundType !== this.props.currentInstance.backgroundType ||
            lastProps.currentInstance.useSecondaryColorHeader !== this.props.currentInstance.useSecondaryColorHeader ||
            lastProps.currentInstance.headerSecondaryColor !== this.props.currentInstance.headerSecondaryColor) {
            updateStyle = true;
        }

        if (updateStyle) {
            // if (this.props.currentInstance.bodyFontUrl")) {
            //     defaults.global.defaultFontFamily = "SpintrCustomBodyFont";
            // } else {
            //     defaults.global.defaultFontFamily = "EloquiaDisplay Regular";
            // }

            this.setState({
                style: this.getStyleString(this.props),
            });
        }
    }

    public render(): ReactNode {
        return (
            <ThemeContext.Provider value={this.state.style.theme}>
                <style>{this.state.style.css}</style>
                {
                    this.props.children
                }
            </ThemeContext.Provider>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(SpintrCustomStyleProvider);
