import { IPopupFormState } from "src/reducers/ui";
import * as actionTypes from "../action-types/ui";

export const setContextSearch = (key: string, value: string) => {
    return {
        meta: {
            key,
            value
        },
        type: actionTypes.SET_CONTEXT_SEARCH,
    }
}

export const setPopupForm = (item: IPopupFormState) => {
    return {
        meta: {
            item
        },
        type: actionTypes.SET_POPUP_FORM
    }
}

export const setAdminMode = (value: boolean) => {
    return {
        meta: {
            value
        },
        type: actionTypes.SET_ADMIN_MODE
    }
}