import { Modal } from "@fluentui/react";
import React, { useEffect } from "react";
import "./FormPopup.scss";
import PopupHeader from "../PopupHeader/PopupHeader";
import { connect, useDispatch } from "react-redux";
import { IApplicationState } from "src/reducer";
import Loader from "../Loader/Loader";
import ErrorMessagebar from "../Messagebars/ErrorMessagebar";
import { IPopupFormState, PopupFormKey } from "src/reducers/ui";
import { setPopupForm } from "src/actions/ui";
import onFormClose from "src/utils/onFormClose";

interface IProps {
    popupFormKey: PopupFormKey;
    popupFormState?: IPopupFormState;
    children: any;
    onClose?: any;
    isDisplayed?: boolean;
}

const FormPopupInner = (props: IProps) => {
    const dispatch = useDispatch();

    if (props.popupFormState.isLoading) {
        return <Loader />
    }

    return (
        <div>
            {!!props.popupFormState.saveError && props.popupFormState.saveError.length > 0 && (
                <ErrorMessagebar
                    errorList={props.popupFormState.saveError}
                    onDismiss={() => {
                        dispatch(setPopupForm({
                            popupFormKey: props.popupFormKey,
                            saveError: undefined
                        }));
                    }}
                />
            )}
            {props.children}
        </div>
    )
}

const FormPopup = (props: IProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!props.onClose &&
            !!props.popupFormState &&
            !props.popupFormState.isDisplayed) {
            props.onClose();
        }
    }, [props.isDisplayed])

    if (!props.popupFormState) {
        return null;
    }

    const close = () => {
        onFormClose(dispatch, () => {
            dispatch(setPopupForm({
                popupFormKey: props.popupFormKey,
                isDisplayed: false
            }));
        });
    }

    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            isOpen={!!props.popupFormState.isDisplayed}
            allowTouchBodyScroll
            isBlocking
            onDismiss={close}
        >
            {!props.popupFormState.hideHeader && (
                <PopupHeader
                    text={props.popupFormState.title}
                    hideCloseButton={props.popupFormState.hideCloseButton}
                    onClose={close} />
            )}
            <FormPopupInner {...props} />
        </Modal>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === props.popupFormKey),
    isDisplayed: state.ui.popupForms.find(x => x.popupFormKey === props.popupFormKey)?.isDisplayed
});

export default connect(mapStateToProps)(FormPopup);
