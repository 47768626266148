import { applyMiddleware, compose, createStore } from "redux";
import persistState, { mergePersistedState } from "redux-localstorage"
import filter from "redux-localstorage-filter";
import adapter from "redux-localstorage/lib/adapters/localStorage";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import createErrorHandlingMiddleware from "./error-handling-middleware";
import rootReducer, { IApplicationState } from "./reducer";
import { createLogger } from "redux-logger";

const logger = createLogger({
    level: "debug",
    predicate: () => false,
});

const middleware = applyMiddleware(
    createErrorHandlingMiddleware({
        printErrorsToConsole: false
    }),
    logger,
    promise,
    thunk,
);

const merger = (initialState: any, persistentState: any): any => {
    if (!!persistentState) {
        if (!!persistentState.user) {
            persistentState.user = {
                ...persistentState.user,
                hasFetched: false
            };
        }

        if (!!persistentState.instance) {
            persistentState.instance = {
                ...persistentState.instance,
                hasFetched: false
            };
        }
    }

    return {
        ...initialState,
        ...persistentState,
    }
};

const reducer = compose(
    mergePersistedState(merger)
)(rootReducer);

const storage = compose(
    filter([
        "user",
        "instance",
        "project.activeProjectId",
        "project.activeVectorId",
    ])
)(adapter(window.localStorage));

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    middleware,
    persistState(storage, "phoenix")
)

const configureStore = (preloadedState: IApplicationState) => createStore(
    reducer,
    preloadedState,
    enhancer,
);

export default configureStore;
