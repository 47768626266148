import React from "react";
import { connect } from "react-redux";
import "./SpintrPagination.scss";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import { localize } from "src/utils/l10n";
import Label from "../Label/Label";
import { IApplicationState } from "src/reducer";

interface IProps {
    activePage: number;
    totalCount: number;
    pageSize?: number;
    onPageChange: (index: number) => void;
    smallViewMode: boolean;
}

interface ISpintrPaginationPart {
    type: "number" | "dots";
    number?: number;
}

const SpintrPagination = React.memo((props: IProps) => {
    const defaultPageSize = 10;
    const pageSize = props.pageSize ? props.pageSize : defaultPageSize;
    const pageCount = Math.ceil(props.totalCount / pageSize);

    const getParts = () => {
        let result = [
            0,
            1,
            2,
            props.activePage -1,
            props.activePage,
            props.activePage + 1,
            pageCount - 3,
            pageCount - 2,
            pageCount - 1
        ];

        result = result.filter((value, index, array) => {
            return value > -1 &&
                value < pageCount &&
                array.indexOf(value) === index;
        }).sort((a, b) => a - b);

        const insertDotsAfterNumbers = [];

        for (let i = 0; i < result.length; i++) {
            if (i !== (result.length - 1) &&
                (result[i + 1] - result[i] !== 1)) {
                insertDotsAfterNumbers.push(result[i]);
            }
        }

        let items : ISpintrPaginationPart[] = result.map((number: any) => {
            return {
                type: "number",
                number
            }
        })

        for (let number of insertDotsAfterNumbers) {
            const index = items.findIndex(x => x.number === number) + 1;

            items.splice(index, 0, {
                type: "dots"
            });
        }

        if (items.length === 11) {
            items.splice(1, 1);
            items.splice(1, 1);
            items.splice(items.length - 2, 1);
            items.splice(items.length - 2, 1);
        }

        return items;
    }

    const parts = getParts();

    if (parts.length < 2) {
        return null;
    }

    return (
        <div className="SpintrPagination">
            <div className="SpintrPagination-inner">
                {
                    parts.map((p: ISpintrPaginationPart, index: number) => {
                        if (p.type === "dots") {
                            return <Visage2Icon icon={"more-circle"} color="p-mid-grey-2" key={index} />
                        }

                        return (
                            <UnstyledButton
                                ariaLabel={localize("Sida2") + " " + (p.number + 1)}
                                key={index}
                                className={p.number === props.activePage ? "active primaryBGColor number" : "number"}
                                onClick={() => {
                                    props.onPageChange(p.number);
                                }}>
                                <Label
                                    color={p.number === props.activePage ? "p-white" : "p-mid-grey-2"}
                                    size="body-2"
                                    weight={p.number === props.activePage ? "bold" : "normal"}>
                                    {
                                        p.number + 1
                                    }
                                </Label>
                            </UnstyledButton>
                        )
                    })
                }
            </div>
        </div>
    )
});

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props
});

export default connect(mapStateToProps)(SpintrPagination);
