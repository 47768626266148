import { PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import api from "src/api/SpintrApi";
import Label from "src/components/Label/Label";
import Loader from "src/components/Loader/Loader";
import forgotPasswordBackgroundUrl from "src/style/images/forgotpassword.jpg";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import SplitAuth from "../SplitAuth";
import PhoenixForm from "src/components/PhoenixForm/PhoenixForm";

interface IRouteParams {
    code: string;
    userId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> { }

interface IState {
    isLoading: boolean;
    password: string;
    repeatPassword: string;
    email: string;
    emailSent: boolean;
    error?: string;
}

export class ForgotPassword extends Component<IProps, IState> {
    private showPasswordForm = !!this.props.match.params.userId && !!this.props.match.params.code;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            emailSent: false,
            password: "",
            repeatPassword: "",
            email: "",
        };
    }

    private onChangeText = (ev, val) => {
        // @ts-ignore
        this.setState({ [ev.target.name]: val });
    };

    private onSubmit = () => {
        this.setState({
            isLoading: true,
        });

        if (!this.showPasswordForm) {
            api.post("/api/v1/authentication/forgotpassword", {
                email: this.state.email,
            })
                .then(() => {
                    this.setState({
                        isLoading: false,
                        emailSent: true,
                    });
                })
                .catch(() => {
                    this.setState({
                        error: "Unexpected error occured",
                        isLoading: false,
                    });
                });

            return;
        }

        api.post("/api/v1/authentication/resetpassword", {
            userId: this.props.match.params.userId,
            code: this.props.match.params.code,
            password: this.state.password,
        })
            .then(() => {
                this.props.history.push("/");
            })
            .catch(() => {
                this.setState({
                    error: "Unexpected error occured",
                    isLoading: false,
                });
            });
    };

    private onOk = () => {
        this.props.history.push("/login");
    };

    private onFormSubmit = (ev) => {
        ev.preventDefault();
    };

    public renderForm = () => {
        if (!this.showPasswordForm) {
            return (
                <PhoenixForm onSubmit={this.onFormSubmit}>
                    <TextField
                        label="Email Address"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeText}
                    />

                    <PrimaryButton text="Send!" onClick={this.onSubmit} className="next-button" type="submit" />
                    <Link to="/login" className="back-button">
                        Back to Login
                    </Link>
                </PhoenixForm>
            );
        }
        // password contains at least 8 characters, one uppercase character, one lowercase character, one digit and one special character
        const passwordIsValid =
            /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d)(?=.*["_')+[}&`?(=:<{/~|,.$>;^*%#\-@!¤´\]¨])["A-ZÅÄÖa-zåäö\d_')+[}&`?(=:<{/~|,.$>;^*%#\-@!¤´\]¨].{1,}$/.test(
                this.state.password
            );
        const passwordsMatch = this.state.password === this.state.repeatPassword;
        const shortPassword = this.state.password.length > 0 && this.state.password.length < 8;
        const weakPassword = this.state.password.length > 0 && !passwordIsValid;

        return (
            <PhoenixForm onSubmit={this.onFormSubmit}>
                <TextField
                    label="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangeText}
                    type="password"
                    canRevealPassword
                />
                {shortPassword ? (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        Use eight or more characters for the password
                    </Label>
                ) : weakPassword ? (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        Choose a more secure password. Test a mixture of at least one uppercase letter, one lowercase
                        letter, one number and one special character
                    </Label>
                ) : (
                    <Label size="body-1" color="p-mid-grey-3" className="password-requirements-label">
                        Choose a password with at least eight characters that contains at least one uppercase letter,
                        one lowercase letter, one number and one special character
                    </Label>
                )}
                <TextField
                    label="Confirm Password"
                    name="repeatPassword"
                    value={this.state.repeatPassword}
                    onChange={this.onChangeText}
                    type="password"
                    canRevealPassword
                />

                {this.state.password.length > 0 && this.state.repeatPassword.length > 0 && !passwordsMatch && (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        The passwords do not match. Try again
                    </Label>
                )}

                <PrimaryButton
                    text="Confirm"
                    onClick={this.onSubmit}
                    className="next-button"
                    disabled={shortPassword || weakPassword || !passwordIsValid || !passwordsMatch}
                    type="submit"
                />
                <Link to="/login" className="back-button">
                    Back to Login
                </Link>
            </PhoenixForm>
        );
    };

    public render() {
        const { error, emailSent } = this.state;

        return (
            <div className="forgot-password-view">
                <SplitAuth error={error} backgroundUrl={forgotPasswordBackgroundUrl}>
                    {emailSent ? (
                        <>
                            <Label as="h2" size="h2" weight="bold" className="forgot-password-label">
                                Check Your Email
                            </Label>

                            <Label size="body-1" color="p-mid-grey-3" className="forgot-password-subtext-label">
                                We have sent password recovery instructions to your email.
                            </Label>

                            <PrimaryButton text="Ok" onClick={this.onOk} className="next-button" />
                        </>
                    ) : (
                        <>
                            {this.showPasswordForm ? (
                                <>
                                    <Label as="h2" size="h2" weight="bold" className="forgot-password-label">
                                        Reset Password
                                    </Label>

                                    <Label size="body-1" className="forgot-password-subtext-label">
                                        Please choose your new password.
                                    </Label>
                                </>
                            ) : (
                                <>
                                    <Label as="h2" size="h2" weight="bold" className="forgot-password-label">
                                        Forgot Password
                                    </Label>

                                    <Label size="body-1" className="forgot-password-subtext-label">
                                        Enter your registered email address below to recieve password reset
                                        instructions.
                                    </Label>
                                </>
                            )}
                            {this.state.isLoading ? <Loader /> : this.renderForm()}
                        </>
                    )}
                </SplitAuth>
            </div>
        );
    }
}
