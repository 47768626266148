import { PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import api from "src/api/SpintrApi";
import Label from "src/components/Label/Label";
import Loader from "src/components/Loader/Loader";
import registerBackgroundUrl from "src/style/images/register.jpg";
import SplitAuth from "../SplitAuth";
import "./ConfirmEmail.scss";
import PhoenixForm from "src/components/PhoenixForm/PhoenixForm";

interface IRouteParams {
    code: string;
    userId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> { }

interface IState {
    isLoading: boolean;
    password: string;
    repeatPassword: string;
    error?: string;
}
export class ConfirmEmail extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            password: "",
            repeatPassword: "",
        };
    }

    private onChangeText = (ev, val) => {
        // @ts-ignore
        this.setState({ [ev.target.name]: val });
    };

    private onSubmit = async (ev) => {
        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/confirmemail", {
            userId: this.props.match.params.userId,
            code: this.props.match.params.code,
            password: this.state.password,
        })
            .then(() => {
                this.props.history.push("/");
            })
            .catch(() => {
                this.setState({
                    error: "The activation of your account failed. It may be because the code in the email has expired, in which case ask an administrator to send you a new activation email.",
                    isLoading: false,
                });
            });
    };

    private onFormSubmit = (ev) => {
        ev.preventDefault();
    };

    public renderForm = () => {
        // password contains at least 8 characters, one uppercase character, one lowercase character, one digit and one special character
        const passwordIsValid =
            /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d)(?=.*["_')+[}&`?(=:<{/~|,.$>;^*%#\-@!¤´\]¨])["A-ZÅÄÖa-zåäö\d_')+[}&`?(=:<{/~|,.$>;^*%#\-@!¤´\]¨].{1,}$/.test(
                this.state.password
            );
        const passwordsMatch = this.state.password === this.state.repeatPassword;
        const shortPassword = this.state.password.length > 0 && this.state.password.length < 8;
        const weakPassword = this.state.password.length > 0 && !passwordIsValid;

        return (
            <PhoenixForm onSubmit={this.onFormSubmit}>
                <TextField
                    label="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangeText}
                    type="password"
                    canRevealPassword
                />
                {shortPassword ? (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        Use eight or more characters for the password
                    </Label>
                ) : weakPassword ? (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        Choose a more secure password. Test a mixture of at least one uppercase letter, one lowercase
                        letter, one number and one special character
                    </Label>
                ) : (
                    <Label size="body-1" color="p-mid-grey-3" className="password-requirements-label">
                        Choose a password with at least eight characters that contains at least one uppercase letter,
                        one lowercase letter, one number and one special character
                    </Label>
                )}
                <TextField
                    label="Confirm Password"
                    name="repeatPassword"
                    value={this.state.repeatPassword}
                    onChange={this.onChangeText}
                    type="password"
                    canRevealPassword
                />

                {this.state.password.length > 0 && this.state.repeatPassword.length > 0 && !passwordsMatch && (
                    <Label size="body-1" color="p-red-4" className="password-error-label">
                        The passwords do not match. Try again
                    </Label>
                )}

                <PrimaryButton
                    text="Next"
                    onClick={this.onSubmit}
                    className="next-button"
                    disabled={shortPassword || weakPassword || !passwordIsValid || !passwordsMatch}
                    type="submit"
                />
            </PhoenixForm>
        );
    };

    public render() {
        const { error } = this.state;

        return (
            <div className="confirm-email-view">
                <SplitAuth error={error} backgroundUrl={registerBackgroundUrl}>
                    <Label as="h2" size="h2" weight="bold" className="confirm-email-label">
                        Thanks for registering!
                    </Label>

                    <Label size="body-1" color="p-mid-grey-3" className="confirm-email-subtext-label">
                        Now you just need to create a password to proceed
                    </Label>

                    {this.state.isLoading ? <Loader /> : this.renderForm()}
                </SplitAuth>
            </div>
        );
    }
}
