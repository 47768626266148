import api from "src/api/SpintrApi";
import * as actionTypes from "../action-types/instance";

export const fetchCurrentInstance = () => {
    return {
        payload: api.get("/api/v1/instances/this"),
        type: actionTypes.FETCH_CURRENT_INSTANCE,
    }
}

export const editInstance = (item: any) => {
    return {
        item,
        type: actionTypes.EDIT_INSTANCE
    }
}