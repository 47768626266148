import { DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { validateRequiredTextField } from 'src/utils';
import { localize } from 'src/utils/l10n';
import FormControl from 'src/components/FormControl/FormControl';
import FormSection from 'src/components/FormSection/FormSection';
import Loader from 'src/components/Loader/Loader';
import ErrorMessagebar from 'src/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/components/Messagebars/SuccessMessagebar';

import './SuperInstanceForm.scss';
import { setPopupForm } from 'src/actions/ui';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';

interface IProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    id: number;
}

interface IState {
    isLoading: boolean;
    isNew: boolean;
    item: any;
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
}

class SuperInstanceForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const isNew = props.id === 0;

        this.state = {
            isLoading: !isNew,
            isNew,
            item: {
                id: 0,
            },
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false,
        };
    }

    componentDidMount() {
        if (!this.state.isNew) {
            const id = this.props.id;

            api.get("api/v1/instances/super/" + id).then((response: any) => {
                this.setState({
                    item: response.data,
                    isLoading: false
                });
            }).catch(this.handleCatch.bind(this))
        }
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    onCancelClick = () => {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "SuperInstance",
                isDisplayed: false
            }));
        });
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            let payload = { ...this.state.item };
            let saveError: string[] = [];

            if (!payload.name) {
                saveError.push("DuMasteFyllaIEttNamn");
            }

            if (!payload.externalId) {
                saveError.push("DuMasteFyllaIEttExterntId");
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/instances/super", payload).then(() => {
                this.setState({
                    saveError: [],
                    saveSuccess: true,
                    isLoading: false
                });
            }).catch(this.handleCatch.bind(this));
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="TargetGroupForm fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.onSaveClick.bind(this)} data={this.state.item}>
                    <FormSection>
                        <FormControl>
                            <TextField
                                value={this.state.item.name}
                                className="textField"
                                label={localize("Namn")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            name: newValue
                                        }
                                    });
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.externalId}
                                className="textField"
                                label={localize("EXTERNAL_ID")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            externalId: newValue
                                        }
                                    });
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                    </FormSection>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                        <PrimaryButton type={"submit"} text={localize("Spara")} />
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(SuperInstanceForm);
