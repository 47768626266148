import React, { useEffect, useState } from "react";
import onFormControlChange from "src/utils/onFormControlChange";

interface IProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    data?: any;
}

const PhoenixForm = (props: IProps) => {
    const [changeCount, setChangeCount] = useState(0);

    const onChange = () => {
        if (changeCount === 0) {
            setChangeCount(1);
        } else if (changeCount === 1) {
            onFormControlChange();
        }
    }

    useEffect(onChange, [props.data]);

    return (
        <form
            {...props}
            onChange={onChange}>
            {props.children}
        </form>
    )
}

export default PhoenixForm;
