document.addEventListener('keyup', (e) => {
    if (e &&
        e.target &&
        //@ts-ignore
        e.target.tagName &&
        //@ts-ignore
        (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') &&
        //@ts-ignore
        e.target.form &&
        //@ts-ignore
        e.target.form.className.indexOf('form-dirty') === -1) {
        //@ts-ignore
        e.target.form.classList.add('form-dirty');
    }
});

document.addEventListener('click', (e) => {
    if (e &&
        e.target &&
        //@ts-ignore
        e.target.tagName &&
        //@ts-ignore
        (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') &&
        //@ts-ignore
        e.target.form &&
        //@ts-ignore
        e.target.form.className.indexOf('form-dirty') === -1) {
        //@ts-ignore
        e.target.form.classList.add('form-dirty');
    }
});

function isElementInsideElement(parentclassName: any, child: any) {
    var node = child.parentNode;

    while (node != null) {
        if (node.className && node.className.indexOf(parentclassName) > -1) {
            return true;
        }

        node = node.parentNode;
    }

    return false;
}

function isAnythingDirty() {
    const dirtyForms = document.getElementsByClassName('form-dirty');

    if (dirtyForms &&
        dirtyForms.length > 0) {
        return true;
    }

    const draftEditors = document.getElementsByClassName('DraftEditor-editorContainer');

    if (draftEditors &&
        draftEditors.length > 0) {
        for (var i = 0; i < draftEditors.length; i += 1) {
            if (isElementInsideElement("ChatTabComposer", draftEditors[i])) {
                continue;
            }

            let textContent = draftEditors[i].textContent;

            if (textContent) {
                textContent = textContent.replace(/(\r\n|\n|\r)/gm, "");
            } else {
                textContent = "";
            }

            if (textContent.length > 0) {
                return true;
            }
        }
    }

    const dirtyTinyEditors = document.getElementsByClassName('tinymce-dirty');

    if (dirtyTinyEditors &&
        dirtyTinyEditors.length > 0) {
        return true;
    }

    return false;
}

export default isAnythingDirty;