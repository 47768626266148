function getForm(el) {
    if (el.tagName === 'FORM') {
        return el;
    }

    if (!el.parentNode) {
        return null;
    }

    return getForm(el.parentNode);
}

function getAllForms() {
    return document.getElementsByTagName("form");
}

function markFormAsDirty(form) {
    if (form.className.indexOf('form-dirty') === -1) {
        //@ts-ignore
        form.classList.add('form-dirty');
    }
}

function onFormControlChange(e?: any) {
    if (!e || !e.target) {
        const forms = getAllForms();

        if (!forms) {
            return;
        }
        
        for (var i = 0; i < forms.length; i++) {
            markFormAsDirty(forms[i]);
        }
    } else {
        const form = getForm(e.target);

        if (!form) {
            return;
        }
    
        markFormAsDirty(form);
    }
}

export default onFormControlChange;
