import React, { useCallback, useState } from "react";
import "./ActionItemForm.scss";
import FormSection from "../FormSection/FormSection";
import FormControl from "../FormControl/FormControl";
import { DatePicker, DayOfWeek, DefaultButton, Dropdown, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { validateRequiredTextField } from "src/utils";
import { localize } from "src/utils/l10n";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import FormTokenizedObjectInput from "../FormTokenizedObjectInput/FormTokenizedObjectInput";
import { SpintrTypes } from "src/typings";
import api from "src/api/SpintrApi";
import ErrorMessagebar from "../Messagebars/ErrorMessagebar";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { setConfirmPopup } from "src/actions/popup";

interface IProps {
    item: any;
    onSave: any;
    onDelete: any;
    onCancelClick: any;
}

interface IState {
    item: any;
    isLoading: boolean;
    saveError: any[];
}

const ActionItemForm = (props: IProps) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        item: props.item,
        isLoading: false,
        saveError: []
    })

    const classNames = ["ActionItemForm"];

    const save = useCallback(() => {
        const envelope = {...state.item};

        setState(s => ({
            ...s,
            isLoading: true
        }));

        api.post("/api/v1/plans/action", envelope).then(() => {
            props.onSave(state.item);
        }).catch(() => {
            setState(s => ({
                ...s,
                isLoading: false,
                saveError: ["TeknisktFel"]
            }));
        });
    }, [state.item]);

    const remove = useCallback(() => {
        dispatch(setConfirmPopup({
            isOpen: true,
            title: localize("TaBort"),
            message: localize("ArDuSakerPaAttDuVillTaBortDennaPost"),
            onConfirm: async () => {
                api.delete("/api/v1/plans/action/" + state.item.id).then(() => {
                    props.onDelete(state.item);
                }).catch(() => {});
            },
        }));
    }, []);

    if (state.isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={classNames.join(" ")}>
            {state.saveError.length > 0 && (
                <ErrorMessagebar
                    errorList={state.saveError}
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            saveError: []
                        }));
                    }}
                />
            )}
            <FormSection>
                <FormControl>
                    <TextField
                        value={state.item.text}
                        className="textField"
                        label={localize("ACTION")}
                        resizable={false}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            setState(s => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    text: newValue
                                }
                            }));
                        }}
                        required
                        aria-required
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateRequiredTextField}
                    />
                </FormControl>
                <FormControl>
                    <Dropdown
                        label={localize("PRIORITY")}
                        selectedKey={state.item.priority.toString()}
                        required
                        onChange={(_e, v) => {
                            setState(s => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    priority: Number(v.key)
                                }
                            }));
                        }}
                        options={[{
                            key: "0",
                            text: localize("PRIORITY_0")
                        }, {
                            key: "1",
                            text: localize("PRIORITY_1")
                        }, {
                            key: "2",
                            text: localize("PRIORITY_2")
                        }, {
                            key: "3",
                            text: localize("PRIORITY_3")
                        }]}
                        styles={{ dropdown: { width: 250 } }}
                    />
                </FormControl>
                <FormControl>
                    <Dropdown
                        label={localize("STATUS")}
                        selectedKey={state.item.status.toString()}
                        required
                        onChange={(_e, v) => {
                            setState(s => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    status: Number(v.key)
                                }
                            }));
                        }}
                        options={[{
                            key: "0",
                            text: localize("ACTION_ITEM_STATUS_0")
                        }, {
                            key: "1",
                            text: localize("ACTION_ITEM_STATUS_1")
                        }, {
                            key: "2",
                            text: localize("ACTION_ITEM_STATUS_2")
                        }, {
                            key: "3",
                            text: localize("ACTION_ITEM_STATUS_3")
                        }, {
                            key: "4",
                            text: localize("ACTION_ITEM_STATUS_4")
                        }]}
                        styles={{ dropdown: { width: 250 } }}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label={localize("DUE_DATE")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        placeholder={localize("ValjDatum")}
                        ariaLabel={localize("ValjDatum")}
                        value={state.item.dueDate}
                        onSelectDate={(date: Date) => {
                            setState(s => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    dueDate: date
                                }
                            }));
                        }}
                    />
                </FormControl>
                <FormControl>
                    <FormTokenizedObjectInput
                        items={state.item.assignee ? [state.item.assignee] : []}
                        types={[SpintrTypes.UberType.User]}
                        label={localize("ASSIGNEE")}
                        url={"/api/v1/users"}
                        itemLimit={1}
                        onChange={(items) => {
                            if (!items || items.length === 0) {
                                setState(s => ({
                                    ...s,
                                    item: {
                                        ...s.item,
                                        assignee: undefined
                                    }
                                }));

                                return;
                            }

                            let assignee = items[0];

                            assignee = {
                                ...assignee,
                                key: !!assignee.key ? assignee.key : assignee.id,
                                id: !!assignee.id ? assignee.id : assignee.key
                            }

                            setState(s => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    assignee,
                                    assigneeId: assignee.id
                                }
                            }));
                        }}
                    />
                </FormControl>
            </FormSection>
            <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                <DefaultButton
                    onClick={props.onCancelClick}
                    text={localize("Avbryt")} />
                {state.item.id !== 0 && state.item.id !== undefined && (
                    <DefaultButton
                        onClick={remove}
                        text={localize("TaBort")} />
                )}
                <PrimaryButton
                    onClick={save}
                    text={localize("Spara")} />
            </Stack>
        </div>
    )
}

export default ActionItemForm;
