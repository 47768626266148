import { DefaultButton } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import Color from 'src/style/colors/Color';
import { localize } from 'src/utils/l10n';

import { Label } from '../Label/Label';
import SpintrLoader from '../Loader/Loader';
import './FileSelector.scss';
import Visage2Icon from '../Visage2Icon/Visage2Icon';

export interface IUploadModel {
    originalFileName?: string;
    fileName?: string;
    ticket?: string;
    url?: string;
    thumbnailUrl?: string;
    remove?: boolean;
}

interface IProps {
    currentUser?: any;
    currentInstance?: any;
    isImage?: boolean;
    isFont?: boolean;
    isCsv?: boolean;
    onChange: (IUploadModel) => void;
    fileUrl?: string;
    fileName?: string;
    uploadFolder?: number;
    instanceId?: number;
}

interface IState {
    fileTypesString: string;
    fileTypes: string[];
    isDraggingOver: boolean;
    isUploading: boolean;
}

class FileSelector extends Component<IProps, IState> {
    fileInput = React.createRef<HTMLInputElement>();

    constructor(props: IProps) {
        super(props);

        let fileTypesString = "*";
        let fileTypes = [];

        if (props.isImage) {
            fileTypesString = "image/png, image/jpeg";
            fileTypes = ["png", "jpeg", "jpg"];
        }

        if (props.isFont) {
            fileTypesString = ".ttf, .otf, .eot, .woff, .woff2, .svg";
            fileTypes = ["ttf", "otf", "eot", "woff", "woff2", "svg"];
        }

        if (props.isCsv) {
            fileTypesString = ".csv";
            fileTypes = ["csv"];
        }

        this.state = {
            fileTypesString,
            fileTypes,
            isDraggingOver: false,
            isUploading: false
        }
    }

    validateFiles(files) {
        let isOk = false;

        if (!this.state.fileTypes || this.state.fileTypes.length === 0) {
            isOk = true;

            return isOk;
        }

        for (let file of files) {
            for (let fileType of this.state.fileTypes) {
                if (file.name.toLowerCase().indexOf("." + fileType.toLowerCase()) > -1) {
                    isOk = true;
                }
            }
        }

        return isOk;
    }

    onFileSelected(files) {
        if (files.length < 1) {
            return;
        }

        this.setState({
            isUploading: true
        }, () => {

            const ticket = new Date().getTime().toString();
            const formData = new FormData();

            formData.append("ticket", ticket);
            formData.append("uploadFolder", (this.props.uploadFolder || 0).toString());
            formData.append("formFile", files[0]);

            const config = {
                headers: {
                    "content-type": "multipart/form-data; boundary=----WebKitFormBoundary5ieermsRmERasb8V",
                },
                params: {
                    instanceId: this.props.instanceId,
                }
            };

            api.post("/api/v1/uploads", formData, config).then((response) => {
                const result: IUploadModel = {
                    ...response.data,
                    ticket
                };

                this.props.onChange(result);

                this.setState({
                    isUploading: false
                });
            }).catch(() => { });
        });
    }

    useFileInput() {
        if (this.props.fileUrl) {
            return;
        }

        this.fileInput.current.click();
    }

    stopEvent(e) {
        let event = e as Event;

        event.stopPropagation();
        event.preventDefault();
    }

    removeDragEffect(data) {
        this.stopEvent(data.nativeEvent);

        this.setState({
            isDraggingOver: false,
        });
    }

    renderInnerContent() {
        return (
            <div>
                {!this.props.fileUrl && !this.state.isUploading && (
                    <div>
                        <div className="upload-button">
                            <Visage2Icon icon="document-upload" />
                        </div>
                        <div className="upload-text">
                            <Label size="body-2" weight="medium">{localize("UPLOAD_FILE_1")}</Label>
                            <Label size="body-2" color="p-dark-grey-1">{localize("UPLOAD_FILE_2")}</Label>
                        </div>
                    </div>
                )}
                {!this.props.fileUrl && this.state.isUploading && (
                    <div>
                        <SpintrLoader />
                    </div>
                )}
                {this.props.fileUrl && !this.props.isImage && (
                    <div>
                        <div className="upload-button" style={{
                            backgroundColor: Color.fromHex(this.props.currentInstance.color).customLighten().toString("hex")
                        }}>
                            <Visage2Icon icon="document" />
                        </div>
                        <Label>{this.props.fileName}</Label>
                    </div>
                )}
            </div>
        )
    }

    renderContent() {
        if (this.props.fileUrl && this.props.isImage) {
            return (
                <img src={this.props.fileUrl} alt={localize("PICTURE")} />
            )
        }

        return (
            <div className="FileSelector-inner">
                {this.renderInnerContent()}
            </div>
        )
    }

    render() {
        return (
            <div className={"FileSelector"}>
                <div className={"FileSelector-content" +
                    (this.state.isDraggingOver ?
                        " is-dragging-file-over" :
                        " is-not-dragging-file-over") +
                    (this.props.fileUrl ?
                        " has-file" :
                        " is-empty")}
                onClick={this.useFileInput.bind(this)}
                onDrop={(data) => {
                    if (this.props.fileUrl) {
                        this.setState({
                            isDraggingOver: false,
                        });

                        return;
                    }

                    this.stopEvent(data.nativeEvent);

                    let files = data.dataTransfer.files;

                    if (files.length > 0 && this.validateFiles(files)) {
                        this.onFileSelected(files);
                    } else {
                        this.fileInput.current.value = "";
                    }

                    this.setState({
                        isDraggingOver: false,
                    });
                }}
                onDragOver={(data) => {
                    if (this.props.fileUrl) {
                        return;
                    }

                    this.stopEvent(data.nativeEvent);

                    this.setState({
                        isDraggingOver: true,
                    });
                }}
                onDragEndCapture={this.removeDragEffect.bind(this)}
                onDragExit={this.removeDragEffect.bind(this)}
                onDragLeave={this.removeDragEffect.bind(this)}>
                    {this.renderContent()}
                </div>
                {this.props.fileUrl && (
                    <div className="FileSelector-buttons">
                        {/* <DefaultButton
                            text={localize("UPLOAD")}
                            disabled={!!this.props.fileUrl || this.state.isUploading}
                            onClick={this.useFileInput.bind(this)} /> */}
                        <DefaultButton
                            text={this.props.isImage ? localize("REMOVE_IMAGE") : localize("REMOVE_FILE")}
                            disabled={!this.props.fileUrl}
                            onClick={() => {
                                const result: IUploadModel = {
                                    remove: true
                                };

                                this.props.onChange(result);
                            }} />
                    </div>
                )}
                <input
                    aria-label={localize("UPLOAD")}
                    ref={this.fileInput}
                    style={{ height: 0, width: 0, display: "block" }}
                    onChange={(e) => {
                        let files = e.target.files;

                        if (files.length > 0 &&
                            this.validateFiles(files)) {
                            this.onFileSelected(files);
                        }
                    }}
                    type="file"
                    accept={this.state.fileTypesString}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(FileSelector);

