import React from 'react';
import './FormSteps.scss';

interface IProps {
    step: number;
    children: any;
}

const FormStep = (props: IProps) => {
    const classNames = ["FormStep"];

    classNames.push("FormStep-" + props.step);

    return (
        <div className={classNames.join(" ")}>
            {props.children}
        </div>
    )
}

export default FormStep;