import { Callout, ColorPicker, IColor } from '@fluentui/react';
import React, { Component } from 'react';
import generateUniqueId from 'src/utils/uniqueId';
import { Label } from '../Label/Label';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import './PhoenixColorPicker.scss';

interface IProps {
    color?: string;
    onChange: any;
}

interface IState {
    color: string;
    isCalloutVisible: boolean;
    id: string;
}

class PhoenixColorPicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            color: props.color ? props.color : "#FFFFFF",
            isCalloutVisible: false,
            id: "ColorPicker-" + generateUniqueId()
        }
    }

    render() {
        return (
            <div>
                <UnstyledButton
                    className="ColorPicker"
                    id={this.state.id}
                    onClick={() => {
                        this.setState({
                            isCalloutVisible: !this.state.isCalloutVisible
                        });
                    }}>
                    <Label color="p-mid-grey-4">{this.state.color}</Label>
                    <div
                        className="color-circle"
                        style={{
                            backgroundColor: this.state.color
                        }} />
                </UnstyledButton>
                {this.state.isCalloutVisible && (
                    <Callout
                        ariaLabelledBy={this.state.id}
                        ariaDescribedBy={this.state.id}
                        role="dialog"
                        gapSpace={0}
                        target={`#${this.state.id}`}
                        onDismiss={() => {
                            this.setState({
                                isCalloutVisible: false
                            });
                        }}
                        setInitialFocus
                    >
                        <ColorPicker color={this.state.color} onChange={(ev: React.SyntheticEvent<HTMLElement>, color: IColor) => {
                            this.setState({
                                color: color.str
                            }, () => {
                                this.props.onChange(this.state.color);
                            })
                        }} />
                    </Callout>
                )}
            </div>
        )
    }
}

export default PhoenixColorPicker;
